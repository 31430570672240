import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { SearchField } from '@app/components';
import DefaultProps from '@app/header.json';
import DefaultPropsUAT from '@app/header-uat.json';

const DefaultPropsContent = process.env.NEXT_PUBLIC_APP_ENVIRONMENT === 'Production' ? DefaultProps : DefaultPropsUAT;

export const SearchTap = props => {
  const [tap, setTap] = useState(1);
  const { onChange } = props;

  const handleClick = value => () => {
    tap !== value && setTap(value);
    value === 1 && onChange('');  // Clear value when tap change back
  }

  return <div className='tw-relative tw-flex tw-flex-row tw-flex-nowrap tw-items-end tw-bg-black tw-h-18 tw-gap-4 | sm:tw-h-20'>
    <button className={`tw-font-bold tw-text-base tw-text-left tw-h-10 tw-px-2 tw-text-gray-300 ${tap === 1 ? 'tw-border-primary-main tw-border-l-16' : 'tw-border-black tw-border-l-16'} | sm:tw-text-lg`} onClick={handleClick(1)}>Latest<span className='tw-hidden | md:tw-inline md:tw-whitespace-nowrap'> Articles</span></button>
    <button className={`tw-font-bold tw-text-base tw-text-left tw-w-full tw-h-10 tw-text-gray-300 tw-overflow-hidden ${tap === 2 ? 'tw-pl-2 tw-border-primary-main tw-border-l-16' : ' tw-pl-5.75 tw-border-border tw-border-l'} | sm:tw-text-lg`} onClick={handleClick(2)}>
      {tap === 2 ?
        <SearchField isTap {...props} />
        : <span><span className='tw-font-icon tw-text-2xl tw-leading-none tw-align-middle'>search</span> {DefaultPropsContent.search.label}</span>
      }
    </button>
    <div className='tw-absolute tw-right-2 tw-top-2 tw-text-left tw-italic tw-text-gray-300 tw-text-sm'>{DefaultPropsContent.search.sponser}</div>
  </div>
}

export default SearchTap;

SearchTap.propTypes = {
  /**
  * Value for the textfield
  */
  value: PropTypes.string.isRequired,
  /**
  * onChange callback function
  */
  onChange: PropTypes.func.isRequired,
};