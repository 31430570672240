import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';
import DefaultProps from '@app/header.json';
import DefaultPropsUAT from '@app/header-uat.json';

const DefaultPropsContent = process.env.NEXT_PUBLIC_APP_ENVIRONMENT === 'Production' ? DefaultProps : DefaultPropsUAT;

/**
* Logo UI component
*/
export const Logo = props =>
  <figure>
    <Link href='/'>
      <a className='tw-block tw-py-3 tw-px-2 | sm:tw-px-4'>
        <img alt={props.alt} src={props.logo} className='tw-hidden tw-w-auto tw-h-5 tw-transition-all | sm:tw-block | 2xl:tw-h-7' />
        <img alt={props.alt} src={props.icon} className='tw-w-auto tw-h-6 | sm:tw-hidden' />
      </a>
    </Link>
    {/* <a href={process.env.NEXTAUTH_URL} className='tw-block tw-py-3 tw-px-2 | sm:tw-px-4'>
      <img alt={props.alt} src={props.logo} className='tw-hidden tw-w-auto tw-h-5 tw-transition-all | sm:tw-block | 2xl:tw-h-7' />
      <img alt={props.alt} src={props.icon} className='tw-w-auto h-6 | sm:tw-hidden' />
    </a> */}
  </figure>


export default Logo;

Logo.propTypes = {
  /**
  * Website logo for big screen
  */
  logo: PropTypes.string.isRequired,
  /**
  * Website icon for small screen
  */
  icon: PropTypes.string.isRequired,
  /**
  * Alt text for logo
  */
  alt: PropTypes.string.isRequired
}

Logo.defaultProps = DefaultPropsContent.logo;