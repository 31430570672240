import ReactDOMServer from 'react-dom/server';
// components
import { Title } from '../components/Shared/Title';
import Space from '../components/Home/Space';
import MostReadArticle from '../components/Home/MostReadArticle';
import Border from '../components/Home/Border';
import { ArticleSummary } from '../components/Shared/ArticleSummary';
import { Button } from '../components/Shared/Button';
import { CenterTop } from '../components/Home/CenterTop';
import { Ad } from '../components/Ad/Ad';
import { Video } from '../components/Shared/Video';

import { getContentItemSummary, getMostPopular } from '@app/utils';

const shortCodePattern = /<!--{{\W+([^0-9]\w+):?(.*?)?\W+}}-->/gm

const RankingTable = ({ items }) => {
  // console.debug(items, items.length)
  return items && items.length > 0 ? items.map((x, idx) => <><MostReadArticle
    item={x}
    number={ idx + 1 }
  />{ (idx != items.length - 1) && <><Space /><Border /><Space /></> }</>) : <></>
}

export const renderHomeCard = (item, section) => {
  const {type, jsonContent} = item;
  const GeoSg = jsonContent?.tags && jsonContent?.tags?.some(tags => tags.toLowerCase() == process.env.NEXT_PUBLIC_GEO_TARGET_SG_TAG) && process.env.NEXT_PUBLIC_GEO_TARGET_SG_CONTENT;
  const GeoNoJp = jsonContent?.tags && jsonContent?.tags?.some(tags => tags.toLowerCase() == process.env.NEXT_PUBLIC_GEO_TARGET_NO_JP_TAG) && process.env.NEXT_PUBLIC_GEO_TARGET_NO_JP_CONTENT;
  
  switch(type) {
    case 'HomeTopStoryGroup':
      return <CenterTop items={jsonContent} />;
      // return <h1>{ shortcodeType } : { JSON.stringify(data) }</h1>;
    case 'AsideArticleSummary':
      switch(section) {
        case 'Center Bottom':
          return <div data-shortcode='AsideArticleSummary-center' className={GeoSg || GeoNoJp || undefined} style={(GeoSg || GeoNoJp) ? {display: 'none'} : undefined}><ArticleSummary home variant="homeQuickSearch" {...jsonContent} column={false} geoTarget={false} /></div>
        default:
          return <div data-shortcode='AsideArticleSummary' className={GeoSg || GeoNoJp || undefined} style={(GeoSg || GeoNoJp) ? {display: 'none'} : undefined}><ArticleSummary home variant="side" {...jsonContent} column={false} geoTarget={false} /></div>
      }
    case 'FigureWithAsideArticleSummary':
      switch(section) {
        case 'Center Bottom':
          return <div data-shortcode='FigureWithAsideArticleSummary-center' className={GeoSg || GeoNoJp || undefined} style={(GeoSg || GeoNoJp) ? {display: 'none'} : undefined}><ArticleSummary home variant="homeQuickSearch" {...jsonContent} column={false} geoTarget={false} /></div>
        default:
          return <div data-shortcode='FigureWithAsideArticleSummary' className={GeoSg || GeoNoJp || undefined} style={(GeoSg || GeoNoJp) ? {display: 'none'} : undefined}><ArticleSummary home variant="figureSide" {...jsonContent} column={false} geoTarget={false} /></div>
      }
    case 'InlineArticleSummary':
      return <ArticleSummary embed {...jsonContent} column={false} />
    case 'Title':
      switch(section) {
        case 'Center Bottom':
          return <Title data-shortcode='Title' title={jsonContent} home />
        default:
          return <Title data-shortcode='Title' variant="side" title={jsonContent} />
      }
    case 'Button':
      return <div data-shortcode='Button' className='tw-text-center'><Button id='cmsButton' btnType='box' color='secondary' outline long {...jsonContent} /></div>
    case 'AdSlot':
      return <Ad googleAd type="secondMpu" />
    case 'Spacer':
      return <Space data-shortcode='Spacer-double' double />
    case 'Border':
      switch(section) {
        case 'Center Bottom':
          return <div data-shortcode='Border'><Space middle /><Border /><Space middle /></div>
        default:
          return <div data-shortcode='Border'><Space /><Border /><Space /></div>
      }
    case 'RankingTable':
      return <div data-shortcode='RankingTable'><RankingTable {...jsonContent} /></div>
    case 'Brightcove':
      return <Video player='brightcove' id={jsonContent} />
    case 'Youtube':
      return <Video player='youtube' id={jsonContent} />
    default:
      return `${type}`;
  }
}

const render = async (shortcodeType, data, allStatuses) => {
    // console.debug(shortcodeType, data)
    switch(shortcodeType) {
      case 'HomeTopStoryGroup':
        return <CenterTop items={ await fetchTopStories(data, allStatuses) } />;
        // return <h1>{ shortcodeType } : { JSON.stringify(data) }</h1>;
      case 'AsideArticleSummary':
        return <div data-shortcode='AsideArticleSummary'><ArticleSummary variant="side" {...(await fetchContentItemSummary(data, allStatuses))} column={false}  /></div>;
      case 'FigureWithAsideArticleSummary':
        return <div data-shortcode='FigureWithAsideArticleSummary'><ArticleSummary variant="figureSide" {...(await fetchContentItemSummary(data, allStatuses))} column={false} /></div>;
      case 'InlineArticleSummary':
        return <ArticleSummary embed {...(await fetchContentItemSummary(data, allStatuses))} column={false} />;
      case 'Title':
        return <Title data-shortcode='Title' variant="side" title={data} />;
      case 'Button':
        return <div className='tw-text-center'><Button id='cmsButton' btnType='box' color='secondary' outline long {...jsonContent} /></div>;
      case 'AdSlot':
        return `{{ AdSlot }}`
      case 'Spacer':
        return <Space data-shortcode='Spacer' />
      case 'Border':
        return <><Space data-shortcode='Spacer' /><Border /><Space data-shortcode='Spacer' /></>
      case 'RankingTable':
        return <div data-shortcode='RankingTable'><RankingTable { ...(await listRankingArticles(10)) }/></div>
      case 'Brightcove':
        return <Video player='brightcove' id={data} />
      case 'Youtube':
        return <Video player='youtube' id={data} />
      default:
        return `${shortcodeType}`;
    }
}

// url field added to manage the new shortcode spa Button
export const getJsonResponse = async (shortcodeType, data, allStatuses, url) => {

  switch(shortcodeType) {
    case 'HomeTopStoryGroup':
      return await fetchTopStories(data, allStatuses)
    case 'InlineArticleSummary':
    case 'AsideArticleSummary':
    case 'FigureWithAsideArticleSummary':
      return await fetchContentItemSummary(data, allStatuses)
    case 'RankingTable':
      return await listRankingArticles(10)
    case 'Title':
      return data;
    case 'Button':
      return await mapButtonSPA(data, url);
    default:
      return null;
  }
}

export const toStaticMarkup = async (rawContent, allStatuses = false) => {  
  let resultByMarkup = _extractItems(rawContent, allStatuses);
  // console.debug(resultByMarkup)
  if(resultByMarkup && resultByMarkup?.length) {
    let hydratedContent = rawContent;
    await Promise.all(resultByMarkup.map(x => x.promise)).then(results => {
      resultByMarkup.forEach((item, i) => {
        var markup = ReactDOMServer.renderToString(results[i]);
        hydratedContent = hydratedContent.replace(new RegExp(item.from, "g"), markup);
      })
    })
    // resultByMarkup.forEach((item) => {
    //   hydratedContent = hydratedContent.replaceAll(item.from, item.to);
    // })
    return hydratedContent;
  }
  return rawContent;
}

export function remapCenterCards(centerCards) {
  const cardIdString = centerCards.reduce((prev, curr) => {
    if(curr.type === 'AsideArticleSummary' || curr.type === 'FigureWithAsideArticleSummary' || curr.type === 'InlineArticleSummary') {
      return !!prev ? prev + ", " + curr.content : curr.content
    } else {
      return prev
    }
  }, "")
  console.debug('cardIdString', cardIdString)
  return [
    {
      type: "HomeTopStoryGroup",
      content: cardIdString
    }
  ]
}

const _extractItems = (rawContent, allStatuses) => {
  let m;
  let resultByMarkup = [];
  while ((m = shortCodePattern.exec(rawContent)) !== null) {
    // This is necessary to avoid infinite loops with zero-width matches
    if (m.index === shortCodePattern.lastIndex) {
        shortCodePattern.lastIndex++;
    }
    
    if(m.length >= 2) {
      resultByMarkup.push({
        from: m[0],
        promise: render(m[1], m[2], allStatuses)
        // ReactDOMServer.renderToStaticMarkup(await )
      })
    }
  }
  return resultByMarkup;
}

const fetchContentItemSummary = async (id, allStatuses) => {
  // let res = await apiGet({ path:`${process.env.API_URL}`, func: 'getArticles',id:id });
  // var qs = new URLSearchParams({ id }).toString();
  // const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/api/article?${qs}`);
  console.debug('fetchContentItemSummary', id)
  const result = await getContentItemSummary(id, allStatuses);
  return result.status===200 ? result.data.summary : '';
}

const listRankingArticles = async (count) => {
  // const res = await fetch(`${process.env.NEXT_PUBLIC_API_URL}/api/most-popular`);
  // return res.status == 200 ? await res.json() : null;

  const result = await getMostPopular();
  console.debug('listRankingArticles', result.data.items)
  return result.status===200 ? result.data : null;
}

const mapButtonSPA = async (data, url) => {
  return {
    title: data,
    url: url
  }
}

const fetchTopStories = async (data, allStatuses) => {
  var ids = data.split(",").map(x => parseInt(x.trim()));
  var items = [];
  await Promise.all(ids.map(id => fetchContentItemSummary(id, allStatuses))).then(results => {
    results.forEach((item, i) => {
      items.push(item);
    })
  });
  return items;
}