
/**
 * Parse newsletter type to readable format
 * @param {string} type 
 * @returns {string}
 */
export const parseNewsletterType = (type) => {
  switch (type) {
    case "ai_alert":
    case "alert":
    case "asianinvestor_alert":
      return "(Alert)";
      
    case "ai_daily":
    case "asianinvestor":
    case "newsletter":
      return "(Daily)";

    case "ai_peoplenews":
    case "peoplenews":
      return "(People News)";

    case "ai_weekendread":
    case "weekendread":
      return "(Weekend Read)";

    case "ai_knowledgehub":
    case "knowledgehub":
      return "(Knowledge Hub)";
      
    case "ai_weekly":
      return "(Weekly)";
    default:
      return "";
  }
}