import { getArticleURL, getAuthorURL, getCategoryURL, getDateFromUnix, imgSrcHelper, parseUnicodeDecimal } from "@app/utils"
import { BreadcrumbJsonLd, NextSeo } from "next-seo"
import Head from "next/head"
import { CorporateContact, ApplicationLd } from "@app/components/Seo"

const Main = ({ summary, articleURL, imageURL, genre }) => {
  const {
    heading,
    publishedAt,
    lastModifiedAt,
    authors,
    introText
  } = summary

  const structuredData = {
    headline: heading,
    description: introText,
    mainEntityOfPage: {
      "@type": "WebPage",
      "@id": articleURL
    },
    image: [
      imageURL
    ],
    datePublished: getDateFromUnix(publishedAt).toDateString(),
    dateModified: getDateFromUnix(lastModifiedAt).toDateString(),
    author: authors?.map(({ display, id, slug }) => ({
      "@type": "Person",
      name: display,
      url: getAuthorURL(slug, id)
    })) || [],
    publisher: {
      "@type": "Organization",
      name: "AsianInvestor",
      logo: {
        "@type": "ImageObject",
        url:  imgSrcHelper("asian-investor/seo/AI-publisherLogo.png")
      }
    },
    genre: genre,
    thumbnailURL: imageURL
  }

  return (
    <ApplicationLd
      key='articlejsonld'
      type='NewsArticle'
      structuredData={structuredData}
    />
  )
}

const Breadcrumb = {
  Author: function Author({ authors, articleURL, articleName }) {
    // Create a breadcrumb for each author
    return (
      <>
        {authors?.map(({ slug, id, display }, index) => (
          <BreadcrumbJsonLd
            key={index}
            keyOverride="breadcrumb-author"
            itemListElements={[
              {
                position: 1,
                item: process.env.NEXT_PUBLIC_API_URL,
                name: "Homepage"
              },
              {
                position: 2,
                item: getAuthorURL(slug, id),
                name: display
              },
              {
                position: 3,
                item: articleURL,
                name: articleName
              }
            ]}
          />
        ))}
      </>
    )
  },
  Category: function Category({ categories, articleURL, articleName }) {
    return (
      // Same as authors, create a crumb for each category the article is in
      <>
        {categories?.map(({ slug, id, display }, index) => (
          <BreadcrumbJsonLd
            key={index}
            keyOverride="breadcrumb-categories"
            itemListElements={[
              {
                position: 1,
                item: process.env.NEXT_PUBLIC_API_URL,
                name: "Homepage"
              },
              {
                position: 2,
                item: getCategoryURL(slug, id),
                name: display
              },
              {
                position: 3,
                item: articleURL,
                name: articleName
              }
            ]}
          />
        ))}
      </>
    )
  }
}


export const Article = ({ summary }) => {
  const {
    heading,
    introText,
    tags,
    imagePath,
    alternateImagePath,
    publishedAt,
    lastModifiedAt,
    authors,
    categories,
    categoryNames,
    itemType,
    slug,
    id
  } = summary
  const categoryName = Array.isArray(categoryNames) && categoryNames[0] || ''
  const categorySlug = Array.isArray(categories) && categories[0].slug || ''
  const categoryId = Array.isArray(categories) && categories[0].id || 0
  const genre = getCategoryURL(categorySlug, categoryId)
  const title = parseUnicodeDecimal(`${heading} | ${categoryName} | AsianInvestor`)
  const keywords = tags?.join(", ") || ""
  const articleURL = getArticleURL(slug, id)
  const noImageURL = imgSrcHelper("asian-investor/content/no-image.png", {
    h: 675,
    w: 1200,
    q: 75,
    v: 20190520,
    c: 1
  })
  const imageURL = imagePath || alternateImagePath ? imgSrcHelper(imagePath || alternateImagePath) : noImageURL
  const publishedTime = getDateFromUnix(publishedAt).toISOString()
  const modifiedTime = getDateFromUnix(lastModifiedAt).toISOString()
  const authorsURL = Array.isArray(authors) ? authors.map((author) => getAuthorURL(author.slug, author.id)) : []
  return (
    <>
      <CorporateContact />
      <NextSeo
        robotsProps={{
          noarchive: true
        }}
        title={title}
        description={introText}
        canonical={articleURL}
        twitter={{
          cardType: "summary_large_image",
          handle: "@AsianInvestor",
          site: "@AsianInvestor",
        }}
        openGraph={{
          title: title, 
          description: introText,
          url: articleURL,
          type: itemType,
          article: {
            tags: tags,
            authors: authorsURL,
            publishedTime: publishedTime,
            modifiedTime: modifiedTime,
            section: categoryName,
          },
          images: [
            {
              url: imageURL
            }
          ],
          site_name: 'AsianInvestor',
          locale: "en_US", // FIXME handle other languages
        }}
        additionalMetaTags={[
          {
            name: 'title',
            content: title
          },
          {
            name: 'keywords',
            content: keywords
          },
          {
            name: 'url',
            content: articleURL
          },
        ]}
      />
      {/* May need to add the article tags manually */}
      {itemType !== 'article' && (
        <Head>
          {authorsURL.map((authorURL, index) => (
            <meta key={index} property='article:author' content={authorURL} />
          ))}
          {tags && <meta property='article:tag' content={tags} />}
          {categoryName && <meta property='article:section' content={categoryName} />}
          <meta property='article:published_time' content={publishedTime} />
          <meta property='article:modified_time' content={modifiedTime} />
        </Head>
      )} 
      {/* Linked Data */}
      <Main 
        summary={summary}
        articleURL={articleURL}
        imageURL={imageURL}
        genre={genre}
      />
      <Breadcrumb.Author 
        articleURL={articleURL}
        articleName={heading}
        authors={authors}
      />
      <Breadcrumb.Category 
        articleURL={articleURL}
        articleName={heading}
        categories={categories}
      />
    </>
  )
}