import { NextSeo } from 'next-seo'
import { useRouter } from 'next/router'
import { getBaseUrl } from '@app/utils'
import { CorporateContact, Breadcrumb } from "@app/components/Seo"


export const Static = ({ name }) => {
  const router = useRouter()
  return (
    <>
      <CorporateContact />
      <NextSeo
        title={`${name} | AsianInvestor`}
        canonical={getBaseUrl() + router.asPath}
        // description={introText}  
      />
      <Breadcrumb
        elements={[
          {
            position: 2,
            name: name,
            item: getBaseUrl() + router.asPath
          }
        ]}
      />
    </>
  )
}