import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { AppContext } from '@app/utils';

/**
* Backdrop loading UI component for page loading
*/
export const BackdropLoading = props => {
  const loading = useContext(AppContext)?.loading || props.loading;

  return loading ?
    <div className='tw-fixed tw-top-0 tw-left-0 tw-w-full tw-z-10 tw-h-screen tw-bg-black tw-bg-opacity-75 tw-flex tw-flex-col tw-flex-nowrap tw-items-center tw-justify-center'>
      <div className='tw-font-bold tw-uppercase tw-text-white'>
        <img src='/images/loading.svg' alt='Loading...' className='tw-block tw-w-16 tw-h-16 tw-mx-auto' />
        Loading...
      </div>
    </div>
    : null
}

export default BackdropLoading;

BackdropLoading.propTypes = {
  /**
  * Open loading screen
  */
  loading: PropTypes.bool,
};

BackdropLoading.defaultProps = {
  loading: false,
};