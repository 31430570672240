import { getMagazineIssueURL, linkHelper } from '@app/utils'
import { CarouselJsonLd } from 'next-seo'

/**
 * 
 * @param {Object} props
 * @param {Object[]} props.items
 * @param {string} props.items[].itemType The itemType that makes up the url. e.g. "category"
 * @param {string} props.items[].slug
 * @param {Number} props.items[].id
 * @returns 
 */
export const ItemList = ({ items }) => (
  <CarouselJsonLd
    ofType='default'
    data={(items || []).map(({ itemType, slug, id }) => ({
      url: process.env.NEXT_PUBLIC_API_URL + linkHelper(itemType, slug, id)
    }))}
  />
)

/**
 * The ItemList to render magazine issue urls
 * @param {Object} props
 * @param {Object[]} props.items
 * @param {Number} props.items[].id
 * @returns 
 */
export const MagazineItemList = ({ items }) => (
  <CarouselJsonLd
    ofType='default'
    data={items.map(({ id }) => ({
      url: getMagazineIssueURL(id)
    }))}
  />
)