import { Article } from './Article'
import { SiteLinksSearchBox } from './SitelinksSearchBox'
import * as Listings from './Listings'
import { Page } from './Page'
import { Static } from './Static'

export const Seo = {
  Article,
  SiteLinksSearchBox,
  ...Listings,
  Page,
  Static
}

export * from './CorporateContact';
export * from './Breadcrumb';
export * from './Listings/ListingSeo';
export * from './Listings/ItemList';
export * from './JsonLd';
