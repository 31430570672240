import React from 'react';
import PropTypes from 'prop-types';

export const Column = ({className, children, skipBorder, noBorder, variant, ...props}) => {  
  return variant == 'center' ? <div className={`${!noBorder ? 'tw-py-4 tw-border-t | md:tw-py-8' : 'tw-pt-4 | md:tw-pt-8'} tw-border-border | first:tw-pt-0 first:tw-border-t-0 | last:tw-pb-0 ${skipBorder ? (
    skipBorder === '9n' ? `| nth-child(9n):tw-border-t-0 ` : 
    skipBorder === '8n' ? `| nth-child(8n):tw-border-t-0 ` : 
    skipBorder === '5n' ? `| nth-child(5n):tw-border-t-0 ` : '') : ''}${className ? ` ${className}` : ''}`} {...props}>{children}</div>
    : <div className={`tw-py-4 tw-border-t tw-border-sideBorder first:tw-pt-0 first:tw-border-0 last:tw-pb-0${className ? ` ${className}` : ''}`} {...props}>{children}</div>
}

export default Column;

Column.propTypes = {
  /**
  * Children for the content render
  */
  children: PropTypes.node.isRequired,
  /**
  * Extra class name for the column render
  */
  className: PropTypes.string,
  /**
  * Extra number to skip boarder
  */
  skipBorder: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  /**
  * No border for content
  */
  noBorder: PropTypes.bool,
  /**
  * Article information
  */
  item: PropTypes.object,
  /**
  * Style objects
  */
  style: PropTypes.object,
  /**
  * Variant format of the article for layout, 'center' is layout for centre, 'side' is layout for left or right side
  */
  variant: PropTypes.oneOf(['center', 'side']),
};

Column.defaultProps = {
  className: '',
  skipBorder: 0,
  noBorder: false,
  variant: 'center',
}