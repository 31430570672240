import queryString from "query-string";
import { isEmpty } from '@app/utils'

export const linkHelper = (type, slug, id) => {
  return `${type && `/${type}` || ''}${slug && `/${encodeURIComponent(slug)}` || ''}${id && `/${id}` || ''}`.toLowerCase()
}

export const imgSrcHelper = (imgPath, params={}) => {
  return (process.env.NEXT_PUBLIC_IMG_URL || '') + `${encodeURIComponent(imgPath)}${!isEmpty(params) ? '&': ''}${queryString.stringify(params)}`
}

export const getArticleURL = (slug, id) => process.env.NEXT_PUBLIC_API_URL + linkHelper("article", slug, id)

export const getAuthorURL = (slug, id) => process.env.NEXT_PUBLIC_API_URL + linkHelper("author", slug, id)

export const getCategoryURL = (slug, id) => process.env.NEXT_PUBLIC_API_URL + linkHelper("category", slug, id)

export const getTagURL = (id) => process.env.NEXT_PUBLIC_API_URL + linkHelper("tag", id) 

export const getCountryURL = (slug, id) => process.env.NEXT_PUBLIC_API_URL + linkHelper("country", slug, id)

export const getVideoURL = (slug, id) => process.env.NEXT_PUBLIC_API_URL + linkHelper("video", slug, id)

export const getWebinarURL = (slug, id) => process.env.NEXT_PUBLIC_API_URL + linkHelper("webinar", slug, id)

export const getMagazineURL = () => `${process.env.NEXT_PUBLIC_API_URL}/magazine`

export const getMagazineIssueURL = (id) => process.env.NEXT_PUBLIC_API_URL + linkHelper("magazine", "issue", id)

export const getEventURL = (slug, id) => process.env.NEXT_PUBLIC_API_URL + linkHelper("event", slug, id)

export const getGalleryURL = (slug, id) => process.env.NEXT_PUBLIC_API_URL + linkHelper("gallery", slug, id)

export const getStaticPageURL = (slug, id) => process.env.NEXT_PUBLIC_API_URL + linkHelper("page", slug, id)

export const getTopicURL = (slug, id) => process.env.NEXT_PUBLIC_API_URL + linkHelper("topic", slug, id)

export const getBaseUrl = () => process.env.NEXT_PUBLIC_API_URL