import sanitizeHtml from 'sanitize-html';

export const trimContent = (introText, limit = process.env.NEXT_PUBLIC_WORD_LIMIT ) => {
  const clean = sanitizeHtml(introText, {
    allowedTags: [/*'b', 'i', 'em', 'strong'*/],
  });
  const truncate = (source, size) => source.length > size ? source.slice(0, size - 1) + "…" : source;
  return truncate(clean, limit);
}

export const trimBodyContent = (body) => {
  const paragraphs = body.split("</p>")
  const firstParagraph = (paragraphs) ? paragraphs[0]+'</p>' : "";
  return firstParagraph;
}

export default { trimContent, trimBodyContent }  ;
