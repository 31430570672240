import { getTagURL } from '@app/utils'
import { CorporateContact, Breadcrumb, ListingSeo, ItemList } from "@app/components/Seo"


export const Tags = ({ data, params }) => (
  <>
    <CorporateContact />
    <ListingSeo
      listing={`#${params.id}`}
      url={getTagURL(params.id)}
    />
    <ItemList items={data?.items} />
    <Breadcrumb 
      elements={[
        {
          position: 2,
          name: params.id,
          item: getTagURL(params.id)
        }
      ]}
    />
  </>
)