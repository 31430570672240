import React from 'react';
import PropTypes from 'prop-types';
import { MobileOnlyView } from 'react-device-detect';
import { Ad } from '@app/components';

export const MobileInsertAd = props =>
  <MobileOnlyView>
    {/* <div className={`tw-h-ad tw--mx-2 tw-bg-secondary-light ${props.type}`}>
      <div className='tw-sticky tw-top-0'>
        <Ad googleAd type={props.type} />
      </div>
    </div> */}
    <div className={`tw-h-ad tw--mx-2 tw-bg-secondary-light tw-relative tw-overflow-hidden ${props.type}`}>
      <div className='interscroller tw-absolute tw-w-full tw-h-full'>
        <div className='tw-fixed tw-top-1/2 tw-left-1/2 tw--translate-x-1/2 tw--translate-y-1/2'>
          <Ad googleAd type={props.type} />
        </div>
      </div>
    </div>
    {/* <div className='tw-h-ad tw--mx-2 tw-bg-secondary-light'>
      <div className='tw-sticky tw-top-0'>
        <Ad type='leftMpuMobile' googleAd />
      </div>
    </div> */}
    <div className='tw-h-ad tw--mx-2 tw-mt-2 tw-bg-secondary-light tw-relative tw-overflow-hidden'>
      <div className='interscroller tw-absolute tw-w-full tw-h-full'>
        <div className='tw-fixed tw-top-1/2 tw-left-1/2 tw--translate-x-1/2 tw--translate-y-1/2'>
          <Ad type='leftMpuMobile' googleAd />
        </div>
      </div>
    </div>
  </MobileOnlyView>

export default Ad;

MobileInsertAd.propTypes = {
  /**
  * Type of ad name
  */
  type: PropTypes.oneOf(['firstMpu', 'secondMpu', 'leftMpuMobile']),
}

MobileInsertAd.defaultProps = {
  /**
  * Type of ad name
  */
  type: 'firstMpu',
}