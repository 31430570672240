import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isMobileOnly, CustomView } from 'react-device-detect';
import { Ad } from '@app/components';
import { TYPEFORM_POLLING_ID } from '@app/utils';
import { Widget } from '@typeform/embed-react';


/**
* Container component for suitable view switch
*/
export const Container2 = (props) => {
  const [mounted, setMounted] = useState(false)
  const googleAd = props.googleAd || true
  const showAd = props.showAds || true
  const pollingId = TYPEFORM_POLLING_ID || false
  useEffect(() => {
    setMounted(true)
  }, []);
  //console.log(mounted, props)
  return (
    <section className={`tw-flex tw-flex-col tw-flex-wrap tw-gap-8 tw-w-full | sm:tw-gap-16 ${props.loadMoreNoAd ? 'tw--mt-4 | sm:tw--mt-8' : ''}`}>

      {!props.noAd && props.leaderboard &&
        <div className='tw-h-ad tw-bg-secondary-light | sm:tw-h-auto'>
          <div className='tw-sticky tw-top-2 | sm:tw-top-4'>{/* {(!props.noAd && props.leaderboard)?'true':'false'} {mounted?'mounted':'no mounted'} */}
            {mounted && showAd && <Ad googleAd={googleAd} type='middleLeaderboard' />}
          </div>
        </div>
      }

      <div className='tw-max-w-container tw-flex tw-flex-col tw-gap-8 tw-px-2 tw-m-auto tw-w-full | sm:tw-flex-row sm:tw-flex-nowrap sm:tw-gap-4 sm:tw-px-4 | md:tw-gap-12'>
        <aside className='tw-hidden | 2xl:tw-block 2xl:tw-w-75'>
          {!props.noAd && <div className='tw-sticky tw-top-4'>
            {props.left}
            <div className='tw-mt-16 first:tw-mt-0'>{mounted && <Ad type='leftMpu' googleAd={googleAd} />}</div>
          </div>}
        </aside>

        <div className={`tw-flex-grow tw-w-full ${props.partner ? 'tw-p-2 tw-pt-0 tw-bg-secondary-light ' : ''}| sm:tw-w-[calc(100%-19.75rem)] ${props.partner ? 'sm:tw-p-4 sm:tw-pt-0 | lg:tw-p-8 lg:tw-pt-0 ' : ''}| 2xl:tw-w-[calc(100%-(2*19.75rem))] | tw-flex tw-flex-col tw-flex-wrap tw-gap-6`}>
          {props.center}
          {props.centerBottom && <div className='tw-hidden | sm:tw-block'>{props.centerBottom}</div>}
        </div>

        <aside className='tw-flex-none tw-w-full | sm:tw-w-75'>
          {!props.noAd && <>
            <div className='sm:tw-h-1/2 sm:tw-min-h-[1033px] 2xl:tw-h-full'>
              <div className='tw-sticky tw-top-2 | sm:tw-top-4'>
                {/* First MPU in right column, beside mobile only device */}
                <div>
                  <CustomView renderWithFragment condition={isMobileOnly !== true}>
                    {props.mpu === 'firstMpu'
                      ? <Ad type='firstMpu' googleAd={googleAd} />
                      : <Ad type='secondMpu' googleAd={googleAd} />
                    }
                  </CustomView>

                </div>

                {/* HSBC typeform polling control by pollingId value */}
                {pollingId && props.mpu === 'firstMpu' && <div className='tw-mt-8'>
                  <div className={`tw-font-body tw-text-xs tw-leading-loose tw-uppercase tw-text-center ${props.white ? 'tw-text-white' : 'tw-text-third-main'}`}>Audience Poll</div>
                  <Widget id={pollingId} className='tw-w-full tw-h-[700px]' />
                </div>}

                {/* Right column */}
                {props.right && <div className='tw-pt-8 | first:tw-pt-0'>{props.right}</div>}

                {/* Left column in small screen */}
                {props.left && <div className='tw-hidden sm:tw-block sm:tw-pt-16 2xl:tw-hidden'>{props.left}</div>}
              </div>
            </div>
            <div className='tw-h-ad tw--mx-2 tw-bg-secondary-light sm:tw-h-1/2 sm:tw-min-h-[664px] sm:tw-mx-0 sm:tw-pt-16 sm:tw-bg-transparent 2xl:tw-hidden'>
              <div className='tw-sticky tw-top-0 sm:tw-top-4'>
                {mounted && <Ad googleAd={googleAd} type='leftMpuMobile' />}
              </div>
            </div>
            {/* Center column in smallest screen */}
            {props.centerBottom && <div className='tw-pt-8 sm:tw-hidden'>{props.centerBottom}</div>}

            {/* Left column in small screen */}
            {props.left && <div className='tw-pt-8 sm:tw-hidden'>{props.left}</div>}
          </>}
        </aside>
      </div>
    </section>
  )
}

export default Container2;

Container2.propTypes = {
  /**
  * Center column elements
  */
  center: PropTypes.node.isRequired,
  /**
  * Center column elements under center, and mobile view switch to the bottom
  */
  centerButton: PropTypes.node,
  /**
  * Left column elements
  */
  left: PropTypes.node,
  /**
  * Right column elements (beside first MPU & second MPU)
  */
  right: PropTypes.node,
  /**
  * Middle leaderboard at top
  */
  leaderboard: PropTypes.bool,
  /**
  * MPU option for first MPU & second MPU
  */
  mpu: PropTypes.oneOf(['firstMpu', 'secondMpu']),
  /**
  * Partner content background color for Single Article
  */
  partner: PropTypes.bool,
  /**
  * Ad content setting, if true, it will output center with grid, and no Ad
  */
  noAd: PropTypes.bool,
  /**
  * Load more no ad optional view
  */
  loadMoreNoAd: PropTypes.bool,
};

Container2.defaultProps = {
  leaderboard: false,
  mpu: 'firstMpu',
  partner: false,
  noAd: false,
  loadMoreNoAd: false,
}