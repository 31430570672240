import React from 'react';
import PropTypes from 'prop-types';
import { ArticleSummary, Title } from '@app/components';

/**
* Trending for Home
*/
export const Trending = ({ items, ...props }) => <>
  <Title title={`Trending`} home />

  <div className='tw-mt-8'>
    <div className='tw-flex tw-flex-col tw-flex-nowrap lg:tw-gap-8 lg:tw-flex-row'>
      {items.map((item, i) => {
        const GeoSg = item?.tags && item?.tags.some(tags => tags.toLowerCase() === process.env.NEXT_PUBLIC_GEO_TARGET_SG_TAG) && true;
        const GeoNoJp = item?.tags && item?.tags.some(tags => tags.toLowerCase() === process.env.NEXT_PUBLIC_GEO_TARGET_NO_JP_TAG) && true;

        return <div key={i} className={`${GeoSg ? process.env.NEXT_PUBLIC_GEO_TARGET_SG_CONTENT + ' ' : ''}${GeoNoJp ? process.env.NEXT_PUBLIC_GEO_TARGET_NO_JP_CONTENT + ' ' : ''}tw-py-4 tw-border-t tw-border-border | first:tw-pt-0 first:tw-border-t-0 | last:tw-pb-0 | md:tw-py-8 | lg:tw-w-1/3 lg:tw-border-t-0 lg:tw-pt-0 lg:tw-pb-0`} style={(GeoSg || GeoNoJp) ? { display: 'none' } : undefined}>
          <ArticleSummary variant='trending' {...items[i]} column={false} geoTarget={false} />
        </div>
      })}
    </div>
  </div>
</>


export default Trending;

Trending.propTypes = {
  /**
  * Trending items for content
  */
  items: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};