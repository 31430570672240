import { getTopicURL } from '@app/utils'
import { CorporateContact, Breadcrumb, ListingSeo, ItemList } from "@app/components/Seo"

export const Topics = ({ data, summary }) => (
  <>
    <CorporateContact />
    <ListingSeo 
      listing={summary?.heading || "Topic"} 
      url={getTopicURL(summary.slug, summary.id)} 
      description={summary?.introText || ''}
    />
    <ItemList items={data?.items} />
    <Breadcrumb 
      elements={[
        {
          position: 2,
          name: summary?.heading || "Topic",
          item: getTopicURL(summary.slug, summary.id)
        }
      ]}
    />
  </>
)