import { useState, useEffect } from 'react';

export const useSelectedFilters  = (filters)=>{
  //let itemTypes = [], categoryIds = [], authorIds = [], tags = [], countryIds = [], year=[], sort = [];
  const [selectedFilters, setSelectedFilter ] = useState();
  useEffect(() => {
    let selected = {
      itemTypes:[],
      categoryIds:[],
      authorIds:[],
      countryIds:[],
      year:[],
      sort:[],
      tags:[],
    }
    if(filters){
      selected.itemTypes = filters?.itemTypes?.filter((obj) => (obj.selected === true)).map((obj) => obj.key) || [];
      selected.authorIds = filters?.authorIds?.filter((obj) => (obj.selected === true)).map((obj) => obj.key) || [];
      selected.categoryIds = filters?.categoryIds?.filter((obj) => (obj.selected === true)).map((obj) => obj.key) || [];
      selected.countryIds =filters?.countryIds?.filter((obj) => (obj.selected === true)).map((obj) => obj.key) || [];
      selected.year = filters?.year?.filter((obj) => (obj.selected === true)).map((obj) => obj.key) || [];
      selected.sort = filters?.sortTypes?.filter((obj) => (obj.selected === true))[0]?.key || '';
      selected.tags = filters?.tags?.filter((obj) => (obj.selected === true))[0]?.key || '';
      //console.log(selected)
      setSelectedFilter(selected)
    }
  },[filters])
  return selectedFilters;
}