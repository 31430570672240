import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { linkHelper } from '@app/utils';
import DefaultProps from '@app/header.json';
import DefaultPropsUAT from '@app/header-uat.json';
import metadata from '@app/metadata.json';

const DefaultPropsContent = process.env.NEXT_PUBLIC_APP_ENVIRONMENT === 'Production' ? DefaultProps : DefaultPropsUAT;


/**
* Footer navigation UI component for footer
*/
export const FooterNav = props => {
  const { categories, countries } = metadata;
  const Section = props => <div className='tw-flex tw-flex-col tw-flex-wrap tw-gap-2'>{props.children}</div>
  const Title = props => <div className='tw-font-extrabold tw-text-sm tw-text-white'>{props.children}</div>
  const Column1 = props => <div className='tw-flex tw-flex-col tw-flex-wrap'>{props.children}</div>
  const Column2 = props => <div className='tw-flex tw-flex-col tw-flex-wrap tw-pl-4'>{props.children}</div>
  
  const Button = props =>
    <Link href={props.url || linkHelper(props.type, props.slug, props.id)}>
      <a className='tw-block tw-text-sm tw-leading-tight tw-text-white tw-py-1 | hover:tw-underline' target={props.target || undefined}>
        {props.display}
      </a>
    </Link>
    // <a href={props.url || linkHelper(props.type, props.slug, props.id)} className='tw-text-sm tw-leading-tight tw-text-white | hover:tw-underline' target={props.target || undefined}>
    //   {props.display}
    // </a>

  const findObject = (objs, slug) => objs[objs.map(o => !o.hideInFooter && o.slug?.toLowerCase()).indexOf(slug)] || null;

  const jsonInfo = findObject(props.nav, 'info');
  const jsonCategories = findObject(props.nav, 'categories');
  const jsonCountries = findObject(props.nav, 'countries');
  const jsonOthers = findObject(props.nav, 'others');
  const jsonOurSites = findObject(props.nav, 'our-sites');
  const jsonOtherSites = findObject(props.nav, 'other-sites');

  return <nav className='tw-grid tw-grid-cols-1 tw-gap-8 | sm:tw-grid-cols-3 | md:tw-grid-cols-4 | 2xl:tw-gap-16 2xl:tw-grid-cols-5 | 3xl:tw-grid-cols-6'>
    {jsonInfo &&
      <Section>
        <Title>{jsonInfo.display}</Title>
        <Column1>
          {jsonInfo.subNav.map((button1, i) =>
            <div key={i}>
              <Button {...button1} />
              {button1.subNav &&
                <Column2>
                  {buttonLv1.subNav.map((button2, j) =>
                    <div key={j}>
                      <Button {...button2} />
                    </div>
                  )}
                </Column2>
              }
            </div>
          )}
        </Column1>
      </Section>
    }

    {jsonCategories &&
      <Section>
        <Title>{jsonCategories.display}</Title>
        <Column1>
          {jsonCategories.subNav.map((button1, i) => {
            const button1Data = findObject(categories, button1.slug);
            return button1Data ?
              <div key={i}>
                <Button type='category' {...button1Data} />
                {button1.subNav &&
                  <Column2>
                    {button1.subNav.map((button2, j) => {
                      const button2Data = findObject(categories, button2);
                      return button2Data ?
                        <div key={j}>
                          <Button type='category' {...button2Data} />
                        </div>
                        : ''
                    })}
                  </Column2>
                }
              </div>
              : ''
          })}
        </Column1>
      </Section>
    }

    {jsonCountries &&
      <Section>
        <Title>{jsonCountries.display}</Title>
        <Column1>
          {jsonCountries.subNav.map((button, i) => {
            const buttonData = findObject(countries, button);
            return buttonData ?
              <div key={i}>
                <Button type='country' {...buttonData} />
              </div>
              : ''
          })}
        </Column1>
      </Section>
    }

    {jsonOthers &&
      <Section>
        <Title>{jsonOthers.display}</Title>
        <Column1>
          {jsonOthers.subNav.map((button, i) => {
            const buttonData = findObject(categories, button.slug);
            return buttonData ?
              <div key={i}>
                <Button type='category' {...buttonData} display={button.display || buttonData.display} />
              </div>
              : <div key={i}>
                <Button {...button} />
              </div>
          })}
        </Column1>
      </Section>
    }

    {jsonOurSites &&
      <Section>
        <Title>{jsonOurSites.display}</Title>
        <Column1>
          {jsonOurSites.subNav.map((button, i) =>
            <div key={i}>
              <Button {...button} />
            </div>
          )}
        </Column1>
      </Section>
    }

    {jsonOtherSites &&
      <Section>
        <Title>{jsonOtherSites.display}</Title>
        <Column1>
          {jsonOtherSites.subNav.map((button, i) =>
            <div key={i}>
              <Button {...button} />
            </div>
          )}
        </Column1>
      </Section>
    }
  </nav>
}

export default FooterNav;

FooterNav.prototype = {
  nav: PropTypes.arrayOf(PropTypes.shape({
    /**
    * Slug of the section
    */
    slug: PropTypes.string.isRequired,
    /**
    * Title or section
    */
    display: PropTypes.string.isRequired,
    /**
    * Sub navigation button array p.s difference patten for all section
    */
    subNav: PropTypes.array.isRequired,
  })).isRequired
}

FooterNav.defaultProps = {
  nav: DefaultPropsContent.navigation,
}