import { getStaticPageURL } from "@app/utils"
import { NextSeo } from 'next-seo'
import { CorporateContact, Breadcrumb } from "@app/components/Seo"


export const Page = ({ params, summary }) => {
  const title = (summary?.heading || params?.slug || 'Page') + ' | AsianInvestor';
  const description = 'AsianInvestor is the premier publication and online source focused on asset management and Asian capital news for institutional investors';
  const url = getStaticPageURL(params?.slug, params?.id);
  return (
    <>
      <CorporateContact />
      <NextSeo
        title={title}
        description={description}
        canonical={url}
        twitter={{
          cardType: "summary_large_image",
          handle: "@AsianInvestor",
          site: "@AsianInvestor",
        }}
        openGraph={{
          title: title,
          description: description,
          url: url,
          type: 'website',
          site_name: 'AsianInvestor',
          locale: "en_US", // FIXME handle other languages
        }}
      />
      <Breadcrumb
        elements={[
          {
            position: 2,
            name: summary?.heading || params?.slug || 'Page',
            item: getStaticPageURL(params?.slug, params?.id)
          }
        ]}
      />
    </>
  )
}