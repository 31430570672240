import React, { useState, useEffect, useCallback } from 'react';
import { Section, ArticleSummary, SearchTap, Button } from '@app/components';
import queryString from "query-string";
import { SEARCH_START, SEARCH_ROWS } from '@app/utils';

/**
* Home Quick Search component
*/
export const HomeQuickSearch = (props) => {
  const [keyword, setSearch] = useState('');
  const [articles, setArticles] = useState()
  const delay = 500;

  const handleChange = (value) => {
    setSearch(value);
  }
  const startSearch = useCallback(async () => {
    try {
      const res = await fetch(`/api/search?${queryString.stringify({ site: process.env.NEXT_PUBLIC_SITE_ID, keyword: keyword, start: SEARCH_START, rows: SEARCH_ROWS, sort: 'latest' })}`)
      if (res.status === 200) {
        let data = await res.json()
        if (data.items?.length > 0) {
          setArticles(data.items)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }, [keyword]);

  useEffect(() => {
    const timer = setTimeout(startSearch, delay);
    return () => clearTimeout(timer);
  }, [startSearch]);

  return <>
    <Section title={<SearchTap value={keyword} onChange={handleChange} />} className='tw-mt-8 2xl:tw-mt-16'>
      {articles ?
        <>
          {articles.map((data, i) =>
            // <ResultArticle key={`result-article-${i}`} {...data} onClick={handleClick} />
            <ArticleSummary key={`result-article-${i}`} variant='homeQuickSearch' {...data} />
          )}
          <div className='tw-text-center'><Button title='More' id='homeMore' btnType='box' color='secondary' outline long url={keyword ? `/search?keyword=${keyword}&sort=latest` : '/search?sort=latest'} /></div>
        </> :
        <div className='tw-font-bold tw-uppercase tw-text-center'>
          <img src='/images/loading.svg' alt='Loading...' className='tw-block tw-w-16 tw-h-16 tw-mx-auto' />
          Loading...
        </div>
      }
    </Section>
  </>
}

export default HomeQuickSearch;