import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@app/components';
import JsonData from '@app/header.json';
import JsonDataUAT from '@app/header-uat.json';

const JsonDataContent = process.env.NEXT_PUBLIC_APP_ENVIRONMENT === 'Production' ? JsonData : JsonDataUAT;

/**
* Pay Wall UI component
*/
export const MagazinePayWall = props => {
  const { paywall, subscribe, account } = JsonDataContent;
  let message;

  switch (props.lockReason) {
    case 'NoSubscription':
      message = {
        ...paywall[props.lockReason],
        footer: <Button btnType='box' title={subscribe.display} url={subscribe.url} />
      };
      break;
    case 'DomainBlacklisted':
      message = {
        ...paywall[props.lockReason],
        footer: <Button btnType='box' title={account.addWorkEmailDisplay} url={`https://${process.env.AUTHSERVER_DOMAIN}${account.addWorkEmailUrl}`} />
      };
      break;
    default:
      return null;
  }

  return <div className='tw-fixed tw-w-full tw-h-screen tw-flex tw-flex-col tw-flex-nowrap tw-items-center tw-justify-center'>
    <div className='tw-relative tw-grid tw-grid-cols-1 tw-gap-4 tw-max-w-middle tw-bg-primary-deepLight tw-shadow-md tw-m-4 tw-p-4 | sm:tw-gap-6 | md:tw-gap-8 | 2xl:tw-p-8'>
      <header>
        <h5 className='tw-font-headline tw-text-xl tw-font-bold tw-tracking-tighter tw-text-primary-main | sm:tw-text-2xl | lg:tw-text-3xl'>{message.header}</h5>
        <div className='tw-font-body tw-text-base tw-font-bold tw-tracking-tight tw-text-black tw-mt-0.25em | sm:tw-text-lg sm:tw-mt-0.5em | lg:tw-text-xl'>{message.magazineBody}</div>
      </header>
      <footer>{message.footer}</footer>
    </div>
  </div>
};

export default MagazinePayWall;

MagazinePayWall.propTypes = {
  /**
  * Lock reason from 
  */
  lockReason: PropTypes.oneOf(['NoSubscription', 'DomainBlacklisted']),
};

MagazinePayWall.defaultProps = {
  lockReason: 'NoSubscription'
}