import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import DefaultProps from '@app/header.json';
import DefaultPropsUAT from '@app/header-uat.json';

const DefaultPropsContent = process.env.NEXT_PUBLIC_APP_ENVIRONMENT === 'Production' ? DefaultProps : DefaultPropsUAT;

/**
* Error template for error page
*/
export const ErrorTemplate = props =>
  <div className='tw-flex tw-flex-col tw-flex-nowrap tw-text-center tw-items-center tw-justify-center tw-min-h-96 tw-h-screen tw-gap-4 tw-max-w-screen-sm tw-mx-auto tw-p-4 | sm:tw-gap-8 sm:tw-p-8 '>
    <img
      alt='AsianInvestor'
      className='tw-w-96 tw-mx-auto'
      src='data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5
      lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI2LjAuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuM
      DAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IuWcluWxpF8xIiB4bWxucz0iaHR0cDovL3d3dy53My5
      vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBwe
      CIKCSB2aWV3Qm94PSIwIDAgMzc3LjYgNDAiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDM3Ny42IDQwOyI
      geG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw6I0VDMUMyNDt9Cjwvc
      3R5bGU+CjxnIGlkPSJBSS1Mb2dvIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMzIgLTMwOSkiPgoJPHBhdGggaWQ9IkludmV
      zdG9yIiBjbGFzcz0ic3QwIiBkPSJNMzg3LjksMzQ4LjNoMTIuNHYtNy41YzAtNi41LDIuOC05LjQsOC05LjRoMS4zdi0xM
      2MtNC45LTAuMi03LjYsMi40LTkuMyw2LjR2LTUuOWgtMTIuNFYzNDguMwoJCXogTTM2OS41LDMzOC44Yy0yLjgtMC4xLTU
      tMi40LTQuOS01LjJjMCwwLDAsMCwwLDB2LTAuMWMtMC4xLTIuNywyLTUsNC43LTUuMWMwLDAsMC4xLDAsMC4xLDBjMi44L
      DAuMSw1LDIuNCw0LjksNS4yYzAsMCwwLDAsMCwwCgkJdjAuMWMwLjEsMi43LTIsNS00LjcsNS4xQzM2OS42LDMzOC44LDM
      2OS42LDMzOC44LDM2OS41LDMzOC44IE0zNjkuNCwzNDljOS44LDAsMTYuOC03LjEsMTYuOC0xNS40di0wLjFjMC04LjMtN
      i45LTE1LjMtMTYuNy0xNS4zCgkJYy05LjgsMC0xNi44LDcuMS0xNi44LDE1LjR2MC4xQzM1Mi43LDM0MiwzNTkuNiwzNDk
      sMzY5LjQsMzQ5IE0zNDMuNCwzNDguOWMyLjcsMCw1LjQtMC41LDcuOS0xLjd2LTkuNGMtMS4yLDAuNi0yLjUsMC45LTMuO
      CwwLjkKCQljLTIsMC0zLTAuOC0zLTN2LTcuNGg2Ljl2LTkuNGgtNi45di03LjRoLTEyLjR2Ny40aC0zLjV2OS40aDMuNXY
      xMC4xQzMzMi4yLDM0NS42LDMzNi4xLDM0OSwzNDMuNCwzNDguOSBNMzE1LDM0OQoJCWM4LjEsMCwxMi45LTMuNywxMi45L
      TEwLjF2LTAuMWMwLTYtNC41LTguMS0xMi05LjljLTMtMC44LTMuNy0xLjEtMy43LTEuN1YzMjdjMC0wLjYsMC41LTAuOSw
      xLjctMC45YzMuMywwLjIsNi42LDEuMSw5LjUsMi43CgkJbDMuOS03LjNjLTQuMS0yLjMtOC43LTMuNS0xMy40LTMuNGMtN
      y43LDAtMTMsMy42LTEzLDEwLjF2MC4xYzAsNi4xLDQuNiw4LjEsMTIuMiw5LjljMywwLjgsMy41LDEuMSwzLjUsMS43djA
      uMQoJCWMwLDAuNy0wLjYsMS0xLjgsMWMtMy45LTAuMS03LjctMS4yLTExLTMuM2wtNC4yLDYuOUMzMDQuMywzNDcuNiwzM
      DkuNiwzNDkuMSwzMTUsMzQ5IE0yNzkuNSwzMzEuMWMwLjQtMi43LDEuOS00LjQsMy45LTQuNAoJCWMyLjIsMCwzLjksMS4
      2LDQuMSw0LjRIMjc5LjV6IE0yODQuNCwzNDljNi40LDAsMTAuNi0yLjYsMTMuNi02LjRsLTYuNi01LjJjLTIsMi0zLjgsM
      y02LDNjLTIuNiwwLjEtNC45LTEuNS01LjYtMy45aDE5LjEKCQljMC4xLTAuNiwwLjEtMS40LDAuMS0yYzAtOC45LTQuOS0
      xNi4yLTE1LjUtMTYuMmMtOC45LDAtMTUuMyw2LjctMTUuMywxNS41djAuMUMyNjgsMzQyLjksMjc0LjYsMzQ5LDI4NC40L
      DM0OSBNMjQ2LjUsMzQ4LjVoMTEuMwoJCWwxMi4xLTI5LjZIMjU3bC00LjksMTQuOGwtNC44LTE0LjhoLTEzTDI0Ni41LDM
      0OC41eiBNMjAyLjcsMzQ4LjNoMTIuNHYtMTUuNWMwLTIuNSwxLjctNCwzLjUtNHMzLjQsMS41LDMuNCw0djE1LjVoMTIuN
      HYtMTkuNAoJCWMwLTYuNi0zLjktMTAuNy0xMC4yLTEwLjdjLTMuNiwwLjEtNywxLjktOS4xLDQuOHYtNC4xaC0xMi40TDI
      wMi43LDM0OC4zeiBNMTg3LjIsMzQ4LjNIMjAwdi0zNy43aC0xMi44TDE4Ny4yLDM0OC4zeiIvPgoJPHBhdGggaWQ9IkFza
      WFuIiBkPSJNMTUyLjcsMzQ4LjNoMTIuNHYtMTUuNWMwLTIuNSwxLjctNCwzLjUtNHMzLjQsMS41LDMuNCw0djE1LjVoMTI
      uNHYtMTkuNGMwLTYuNi0zLjktMTAuNy0xMC4yLTEwLjcKCQljLTMuNiwwLjEtNywxLjktOS4xLDQuOHYtNC4xaC0xMi40V
      jM0OC4zeiBNMTM0LjgsMzQxLjljLTEuNSwwLjEtMi44LTEuMS0yLjgtMi43YzAtMC4xLDAtMC4xLDAtMC4yVjMzOWMwLTE
      uOCwxLjQtMy4zLDMuOC0zLjMKCQljMSwwLDEuOSwwLjIsMi44LDAuNXYxLjJDMTM4LjcsMzQwLjIsMTM3LDM0MS45LDEzN
      C44LDM0MS45IE0xMjkuOSwzNDljMy4yLDAuMSw2LjQtMS4zLDguNi0zLjd2M2gxMi40di0xNi43CgkJYzAtNC4zLTAuOS0
      3LjEtMy4xLTkuM2MtMi42LTIuNi02LjUtMy44LTEyLjYtMy44Yy00LjMtMC4xLTguNSwwLjctMTIuNSwyLjJsMi4yLDguM
      WMyLjYtMS4xLDUuNC0xLjcsOC4yLTEuNwoJCWMzLjgsMCw1LjUsMS42LDUuNSw0LjN2MC41Yy0yLjMtMC43LTQuNi0xLjE
      tNy0xLjFjLTYuOSwwLTExLjMsMy4zLTExLjMsOS4ydjAuMUMxMjAuMiwzNDUuOCwxMjQuMiwzNDksMTI5LjksMzQ5IE0xM
      DYuMiwzNDguMwoJCWgxMi40di0yOS40aC0xMi40TDEwNi4yLDM0OC4zeiBNMTA2LDMxNi44aDEyLjhWMzA5SDEwNkwxMDY
      sMzE2Ljh6IE05MS41LDM0OWM4LjEsMCwxMi45LTMuNywxMi45LTEwLjF2LTAuMWMwLTYtNC41LTguMS0xMi05LjkKCQljL
      TMtMC44LTMuNy0xLjEtMy43LTEuN1YzMjdjMC0wLjYsMC41LTAuOSwxLjctMC45YzMuMywwLjIsNi42LDEuMSw5LjUsMi4
      3bDMuOS03LjNjLTQuMS0yLjMtOC43LTMuNS0xMy40LTMuNAoJCWMtNy43LDAtMTMsMy42LTEzLDEwLjF2MC4xYzAsNi4xL
      DQuNiw4LjEsMTIuMiw5LjljMywwLjgsMy41LDEuMSwzLjUsMS43djAuMWMwLDAuNy0wLjYsMS0xLjgsMWMtMy45LTAuMS0
      3LjctMS4yLTExLTMuM2wtNC4yLDYuOQoJCUM4MC44LDM0Ny42LDg2LjEsMzQ5LjEsOTEuNSwzNDkgTTUwLjgsMzM0LjNsM
      y41LTkuMmwzLjUsOS4ySDUwLjh6IE0zMiwzNDguM2gxMy42bDItNS4xaDEzLjRsMiw1LjFoMTMuOGwtMTYuMS0zOEg0OC4
      xTDMyLDM0OC4zegoJCSIvPgo8L2c+Cjwvc3ZnPgo=' />
    <h1 className='tw-font-bold tw-text-7xl | sm:tw-text-8xl | lg:tw-text-9xl'>{props.errorCode}</h1>
    {props.heading && <div className='tw-font-bold tw-text-xl | sm:tw-text-2xl | lg:tw-text-3xl'>{props.heading}</div>}
    {props.introText && <div>{props.introText}</div>}
    <div className='tw-flex tw-flex-col tw-flex-wrap tw-gap-2 tw-items-center | sm:tw-flex-row sm:tw-flex-nowrap sm:tw-gap-6'>
      <Link href={props.copyright.url}>
        <a className='tw-block' target='_blank'>
      {/* <a href={props.copyright.url} className='tw-block' target='_blank' rel='noreferrer'> */}
          <img alt={props.copyright.alt}
          className='tw-w-auto tw-h-8 tw-transition-all tw-mx-auto'
          src='data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBH
          ZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDI2LjAuMSwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb24
          6IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IuWcluWxpF8xIiB4bWxucz0iaHR0cDovL3
          d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwc
          HgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgMTYzLjUgMzQiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAw
          IDE2My41IDM0OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxzdHlsZSB0eXBlPSJ0ZXh0L2NzcyI+Cgkuc3Qwe2ZpbGw
          6IzExMTA0ODt9Cjwvc3R5bGU+CjxnIGlkPSJoYXltYXJrZXQiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDQwNiAtNDQ2Lj
          AxNCkiPgoJPHBhdGggaWQ9InIiIGNsYXNzPSJzdDAiIGQ9Ik0tMjQ0LjIsNDQ5LjVjMC45LDAsMS43LTAuOCwxLjctM
          S43YzAtMC45LTAuOC0xLjctMS43LTEuN3MtMS43LDAuOC0xLjcsMS43Yy0wLjEsMC44LDAuNCwxLjYsMS4zLDEuNwoJ
          CUMtMjQ0LjUsNDQ5LjUtMjQ0LjQsNDQ5LjUtMjQ0LjIsNDQ5LjUgTS0yNDQuMiw0NDkuMWMtMSwwLTEuMy0wLjYtMS4
          zLTEuNGMwLTAuOCwwLjMtMS40LDEuMy0xLjRzMS4zLDAuNiwxLjMsMS40CgkJQy0yNDIuOSw0NDguNS0yNDMuMiw0ND
          kuMS0yNDQuMiw0NDkuMSBNLTI0NC41LDQ0OC42VjQ0OGwwLjYsMC42aDAuNWwtMC44LTAuN2gwLjJjMC41LDAsMC42L
          TAuMywwLjYtMC41YzAtMC4yLTAuMS0wLjYtMC44LTAuNgoJCWgtMC43djEuOEwtMjQ0LjUsNDQ4LjZ6IE0tMjQ0LjUs
          NDQ3LjJoMC4zYzAuNCwwLDAuNCwwLjIsMC40LDAuM3MwLDAuMy0wLjQsMC4zaC0wLjNMLTI0NC41LDQ0Ny4yeiIvPgo
          JPHBhdGggaWQ9InQiIGNsYXNzPSJzdDAiIGQ9Ik0tMjQ2LjQsNDU4LjF2LTMuM2gtNC4zdi01LjRoLTQuM3YxNy4xYy
          0wLjEsMy4yLDIuMyw1LjgsNS41LDZjMC4yLDAsMC4zLDAsMC41LDAKCQljMS43LDAsMy4zLTAuNiw0LjUtMS43bC0xL
          jctMi44Yy0wLjYsMC43LTEuNCwxLjEtMi4zLDEuMWMtMC43LDAuMS0xLjMtMC4yLTEuOC0wLjdjLTAuNC0wLjUtMC42
          LTEuMS0wLjYtMS44di04LjRILTI0Ni40eiIvPgoJPHBhdGggaWQ9ImUiIGNsYXNzPSJzdDAiIGQ9Ik0tMjU3LjcsNDY
          0LjloLTEzLjJjMCwxLjgsMS4zLDQuMiw0LjksNC4xYzEuOCwwLjEsMy42LTAuNiw0LjktMmwyLjIsMi40Yy0yLDItNC
          43LDMuMS03LjYsMwoJCWMtMi4zLDAtNC42LTAuOC02LjItMi41Yy0xLjgtMS43LTIuNy00LjItMi42LTYuN2MtMC4yL
          TQuOCwzLjYtOC45LDguNC05LjFjMC4xLDAsMC4zLDAsMC40LDBjMi4zLTAuMSw0LjYsMC44LDYuMiwyLjQKCQljMS43
          LDEuOCwyLjcsNC4yLDIuNiw2LjdMLTI1Ny43LDQ2NC45eiBNLTI3MC45LDQ2MS44aDguOWMtMC4xLTIuNS0yLjEtNC4
          0LTQuNi00LjNDLTI2OSw0NTcuNi0yNzAuOCw0NTkuNS0yNzAuOSw0NjEuOCIvPgoJPHBhdGggaWQ9ImsiIGNsYXNzPS
          JzdDAiIGQ9Ik0tMjkwLjMsNDQ2LjF2MjZoNC40di03LjVsNS44LDcuNWg1LjJsLTcuMy05LjNsNi45LTguMWgtNC45b
          C01LjYsNi40di0xNS4xSC0yOTAuM3oiLz4KCTxwYXRoIGlkPSJyLTIiIGNsYXNzPSJzdDAiIGQ9Ik0tMjk4LjYsNDYw
          LjdjMC0yLjQsMC43LTMsMy40LTNjMC44LDAsMS41LDAuMSwyLjMsMC4ybDAuNS0zLjRjLTEuMS0wLjMtMi4yLTAuNS0
          zLjQtMC41CgkJYy0xLjgtMC4xLTMuNywwLjUtNS4xLDEuNmMtMS40LDEuMy0yLjIsMy4xLTIsNXYxMS41aDQuM0wtMj
          k4LjYsNDYwLjd6Ii8+Cgk8cGF0aCBpZD0iYSIgY2xhc3M9InN0MCIgZD0iTS0zMTEuMSw0NjguMWMtMC44LDAuNi0xL
          jgsMS0yLjgsMWMtMC45LDAuMS0xLjgtMC4xLTIuNi0wLjZjLTAuNi0wLjUtMS0xLjMtMC45LTIuMQoJCWMwLTEuNyww
          LjktMi4zLDMuNi0yLjNoMy43djEuNUMtMzEwLjIsNDY2LjgtMzEwLjIsNDY3LjQtMzExLjEsNDY4LjEgTS0zMTguNyw
          0NTkuNGMxLjQtMS4xLDMuMi0xLjcsNS0xLjhjMi42LDAsMy41LDAuNywzLjUsMy4xCgkJdjAuNWgtNC40Yy0xLjgtMC
          4xLTMuNywwLjQtNS4zLDEuM2MtMS4zLDAuOS0yLDIuNS0xLjksNC4xYy0wLjEsMS42LDAuNiwzLjIsMS44LDQuMmMxL
          jMsMS4xLDIuOSwxLjYsNC42LDEuNmMyLjUsMCwzLjgtMC40LDUuMy0yCgkJbDAuMywxLjdoMy43di0xMS41YzAuMi0x
          LjktMC42LTMuNy0yLTVjLTEuNS0xLjEtMy4zLTEuNy01LjEtMS42Yy0yLjcsMC01LjMsMC45LTcuNCwyLjZMLTMxOC4
          3LDQ1OS40eiIvPgoJPHBhdGggaWQ9Im0iIGNsYXNzPSJzdDAiIGQ9Ik0tMzQ5LjcsNDYxLjNjMC01LjMsMy4xLTcuMy
          w3LjQtNy4zYzIsMCwzLjksMC45LDUuMiwyLjVjMS4zLTEuNiwzLjItMi41LDUuMi0yLjVjNC4zLDAsNy40LDIsNy40L
          DcuMwoJCXYxMC44aC00LjN2LTEwLjhjMC0yLTEuMi0zLjUtMy4xLTMuNWMtMS44LDAtMy4xLDEuNS0zLjEsMy41djEw
          LjhoLTQuM3YtMTAuOGMwLTItMS4yLTMuNS0zLjEtMy41Yy0xLjgsMC0zLjEsMS41LTMuMSwzLjV2MTAuOAoJCWgtNC4
          zVjQ2MS4zeiIvPgoJPHBhdGggaWQ9InkiIGNsYXNzPSJzdDAiIGQ9Ik0tMzU3LjIsNDU0LjlsMCw5LjdjMCwyLjYtMS
          4xLDQuMi0zLjQsNC4yYy0yLjcsMC0zLjctMS42LTMuNy00LjJ2LTkuN2gtNC4zdjkuNwoJCWMwLDIuMiwwLjMsNC4zL
          DEuNiw1LjljMS41LDEuNiwzLjcsMi40LDUuOSwyLjFjMS42LTAuMSwzLjEtMC44LDQuMS0ybDAsMi43YzAsMi40LTEu
          MiwzLjEtMy44LDMuMWMtMS44LTAuMS0zLjYtMC43LTUuMS0xLjgKCQlsLTEuOCwyLjhjMi4xLDEuNyw0LjcsMi42LDc
          uNSwyLjZjMS45LDAuMSwzLjctMC41LDUuMi0xLjZjMS4zLTEuMSwyLTIuOCwyLTUuMXYtMTguNEgtMzU3LjJ6Ii8+Cg
          k8cGF0aCBpZD0iYS0yIiBjbGFzcz0ic3QwIiBkPSJNLTM3Ni45LDQ2OC4xYy0wLjgsMC42LTEuOCwxLTIuOCwxYy0wL
          jksMC4xLTEuOC0wLjEtMi42LTAuNmMtMC42LTAuNS0xLTEuMy0wLjktMi4xCgkJYzAtMS43LDAuOS0yLjMsMy42LTIu
          M2gzLjd2MS41Qy0zNzYsNDY2LjgtMzc2LDQ2Ny40LTM3Ni45LDQ2OC4xIE0tMzg0LjUsNDU5LjRjMS40LTEuMSwzLjI
          tMS43LDUtMS44YzIuNiwwLDMuNSwwLjcsMy41LDMuMXYwLjUKCQloLTQuNGMtMS44LTAuMS0zLjcsMC40LTUuMywxLj
          NjLTEuMywwLjktMiwyLjUtMS45LDQuMWMtMC4xLDEuNiwwLjYsMy4yLDEuOCw0LjJjMS4zLDEuMSwyLjksMS42LDQuN
          iwxLjZjMi41LDAsMy44LTAuNCw1LjMtMgoJCWwwLjMsMS43aDMuN3YtMTEuNWMwLjItMS45LTAuNi0zLjctMi01Yy0x
          LjUtMS4xLTMuMy0xLjctNS4xLTEuNmMtMi43LDAtNS4zLDAuOS03LjQsMi42TC0zODQuNSw0NTkuNHoiLz4KCTxwYXR
          oIGlkPSJoIiBjbGFzcz0ic3QwIiBkPSJNLTQwNiw0NDYuOGg0LjN2OC45YzEuMi0xLjEsMi44LTEuNyw0LjQtMS43Yz
          QsMCw2LjksMiw2LjksOC4xdjEwaC00LjN2LTEwLjRjMC0yLjMtMS40LTMuOS0zLjUtMy45CgkJYy0yLDAtMy42LDEuM
          S0zLjYsMy45djEwLjRoLTQuM1Y0NDYuOHoiLz4KPC9nPgo8L3N2Zz4K' />
      {/* </a> */}
        </a>
      </Link>
      <div className='tw-font-bold'>{props.copyright.display}</div>
    </div>
  </div>

export default ErrorTemplate;

ErrorTemplate.propTypes = {
  /**
  * Error code for template output
  */
  errorCode: PropTypes.number.isRequired,
  /**
  * Heading message for template
  */
  heading: PropTypes.node,
  /**
  * Intro text for template
  */
  introText: PropTypes.node,
  /**
  * Information of Copyright
  */
  copyright: PropTypes.object,
};

ErrorTemplate.defaultProps = {
  heading: '',
  introText: '',
  copyright: DefaultPropsContent.copyright
}