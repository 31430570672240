import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, SearchField, ArticleSummary, Ad } from '@app/components';
import Link from 'next/link';
import queryString from "query-string";
import { SEARCH_START, SEARCH_POPUP_ROWS } from '@app/utils';
import DefaultProps from '@app/header.json';
import DefaultPropsUAT from '@app/header-uat.json';

const DefaultPropsContent = process.env.NEXT_PUBLIC_APP_ENVIRONMENT === 'Production' ? DefaultProps : DefaultPropsUAT;

const SearchResult = () => {
  const [keyword, setSearch] = useState('');
  const [articles, setArticles] = useState([])
  const delay = 500;

  const handleChange = (value) => {
    setSearch(value);
  }
  const startSearch = useCallback(async () => {
    try {
      const res = await fetch(`/api/search?${queryString.stringify({ keyword: keyword, start: SEARCH_START, rows: SEARCH_POPUP_ROWS, sort: 'latest' })}`)
      if (res.status === 200) {
        let data = await res.json()
        if (data.items?.length > 0) {
          setArticles(data.items)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }, [keyword]);

  useEffect(() => {
    if (keyword) {
      const timer = setTimeout(startSearch, delay);
      return () => clearTimeout(timer);
    }
  }, [keyword, startSearch]);

  return <div>
    <SearchField value={keyword} onChange={handleChange} submitable />
    <div className='tw-mt-8'>
      {articles.map((data, i) =>
        <ArticleSummary key={`result-article-${i}`} {...data} simple columnProps={{ noBorder: true }} />
      )}
    </div>
  </div>
}

/**
* Search Popup component
*/
export const SearchPopup = props => {
  const { search } = DefaultPropsContent;

  useEffect(() => {
    document.querySelector('.searchPopupClose')?.addEventListener('click', () => {
      document.querySelector('.searchPopup')?.classList.add('!tw-hidden');
    })
  })
  return <div className={`!tw-hidden searchPopup tw-transition-all tw-fixed tw-z-50 tw-top-0 tw-left-0 tw-w-screen tw-px-4 tw-pt-2 tw-pb-8 tw-overflow-y-auto tw-bg-white tw-h-screen | sm:tw-pt-4 sm:tw-pb-8 | 2xl:tw-px-8 2xl:tw-pb-16`}>
    <div className='tw-flex'>
      <div className='tw-flex-grow' />
      <Button title='close' btnType='boxIcon' color='transparent' classes='searchPopupClose' />
    </div>
    <div className='tw-grid tw-grid-cols-1 tw-gap-4 tw-max-w-middle tw-m-auto | sm:tw-gap-8 | xl:tw-gap-16'>
      <div className={`${search.sponser ? 'tw-h-28 tw-w-full | sm:tw-h-40' : ''}`}>
        <Ad type='search' googleAd={true} />
      </div>
      <SearchResult />
    </div>
    {search.sponser ? <style>{"\
        #searchAd {\
          position: fixed;\
          top: 3rem;\
          left: 50%;\
          z-index: 50;\
          height: auto;\
          transform: translateX(-50%);\
          margin-right: -50%;\
          transition-delay: 0.125s;\
        }\
        @media (min-width: 640px) {\
          #searchAd {\
            top: 4rem;\
          }\
        }\
      "}</style>
      : <style>{"\
      #searchAd {\
        position: fixed;\
        top: -1000rem;\
        left: 50%;\
        transform: translateX(-50%);\
        margin-right: -50%;\
        transition-delay: 0s;\
      }\
    "}</style>}
  </div>
}

export default SearchPopup;

// SearchPopup.propTypes = {
//   /**
//   * Value for open feature panel
//   */
//   open: PropTypes.bool,
//   /**
//   * onClose call back function
//   */
//   onClose: PropTypes.func
// }

// SearchPopup.defaultProps = {
//   open: false,
//   onClose: undefined
// }