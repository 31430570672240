import { getJsonResponse } from '@app/utils/shortcodeUtils';
import { remapCenterCards } from '.';

export function deserializeResponseBodyColumns(responseBody) {
    var mapped = responseBody.result.map(_ => {
        return {
            section: _.section,
            html: JSON.parse(_.html)
        }
    })
    responseBody.result = mapped;
    return responseBody;
}

export async function getResponseBody(requestBody, isPreview) {
    let responseBody = {
        site: process.env.NEXT_PUBLIC_SITE_ID,
        format: "json"
    }
    // responseBody[isPreview === "true" ? "preview" : "result"] = [
    responseBody['result'] = [
        {
            section: "Left Top",
            html: JSON.stringify(await getJsons(getColumnPayload(requestBody, "Left Top")))
        },
        {
            section: "Left Bottom",
            html: JSON.stringify(await getJsons(getColumnPayload(requestBody, "Left Bottom")))
        },
        {
            section: "Center Top",
            html: JSON.stringify(await getJsons(getColumnPayload(requestBody, "Center Top")))
        },
        {
            section: "Center Bottom",
            html: JSON.stringify(await getJsons(getColumnPayload(requestBody, "Center Bottom")))
        },
        {
            section: "Right Top",
            html: JSON.stringify(await getJsons(getColumnPayload(requestBody, "Right Top")))
        },
        {
            section: "Right Bottom",
            html: JSON.stringify(await getJsons(getColumnPayload(requestBody, "Right Bottom")))
        },
    ]

    return responseBody
}

function getColumnPayload(requestBody, section) {
    let cards = requestBody.columns.find(x => x.section === section).cards
    if (section === "Center Top") {
        cards = remapCenterCards(cards);
    }
    return cards.map((card, idx) => {
        return {
            type: card.type,
            content: card.content,
            promise: getJsonResponse(card.type, card.content, true, card.url)
        }
    })
}

async function getJsons(payloads) {
    const jsons = [];
    await Promise.all(payloads.map(x => x.promise)).then(results => {
        payloads.forEach((item, i) => {
            jsons.push({
                type: item.type,
                jsonContent: results[i]
            });
        })
    })
    return jsons;
}