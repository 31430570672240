import { getAuthorURL } from '@app/utils'
import { CorporateContact, Breadcrumb, ListingSeo, ItemList } from "@app/components/Seo"


export const Authors = ({ data, author }) => (
  <>
    <CorporateContact />
    <ListingSeo
      listing={`by ${author?.text || "Author"}`}
      url={getAuthorURL(author?.slug, author?.key)}
    />
    <ItemList items={data?.items} />
    <Breadcrumb 
      elements={[
        {
          position: 2,
          name: author?.text || "Author",
          itemURL: getAuthorURL(author?.slug, author?.key)
        }
      ]}
    />
  </>
)