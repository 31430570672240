import { SiteLinksSearchBoxJsonLd } from 'next-seo'
import { CorporateContact } from '@app/components/Seo'
import Head from 'next/head'

export const SiteLinksSearchBox = ({}) => {
  const url = process.env.NEXT_PUBLIC_API_URL
  return (
    <>
      <Head>
        <link rel="canonical" href={url} />
      </Head>
      <CorporateContact />
      <SiteLinksSearchBoxJsonLd
        url={url}
        potentialActions={[
          {
            target: `${url}/api/v1/search?keyword`,
            queryInput: 'search_term_string',
          },
          // {
          //   target: `android-app:${url}/api/v1/search?`,
          //   queryInput: 'search_term_string',
          // },
        ]}
      />
    </>
  )
}