import React from 'react';
import PropTypes from 'prop-types';

/**
* Single Article Footer UI component
*/
export const ArticleSource = props =>
  <div className='tw-font-body tw-text-base tw-italic'>{props.source}</div> || ''

export default ArticleSource;

ArticleSource.propTypes = {
  /**
  * Text of the article source
  */
  source: PropTypes.string,
};