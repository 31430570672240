import React from 'react';
import PropTypes from 'prop-types';
import { MobileInsertAd, Ad, TopStory, Top4, Top5, Trending, EditorsPicks } from '@app/components';
import { CustomView, MobileOnlyView, isMobileOnly } from 'react-device-detect';

/**
* Center Top component for Home
*/
export const CenterTop = ({ items, ...props }) => <div>
  {items[0] && <div className='tw-flex tw-flex-col tw-gap-4 tw-border-border | lg:tw-flex-row lg:tw-flex-nowrap lg:tw-gap-8 lg:tw-border-0'>
    <TopStory item={items[0]} />
    
    {items[1] && <div className='lg:tw-w-5/12'><Top4 items={items.slice(1, 4)} /></div>}
  </div>}

  {items[4] && <Top5 item={items[4]} />}

  <div><MobileOnlyView><div className='tw-mt-16'><MobileInsertAd /></div></MobileOnlyView></div>

  {items[5] && <div className='tw-mt-16'><Trending items={items.slice(5, 8)} /></div>}

  <div><CustomView renderWithFragment condition={isMobileOnly !== true}><div className='tw-mt-16'><Ad googleAd type='homeMiddleLeaderboard' /></div></CustomView></div>
  <div><MobileOnlyView>
    <div className='tw-mt-16'>
      <div className={`tw-h-ad tw--mx-2 tw-bg-secondary-light tw-relative tw-overflow-hidden ${props.type}`}>
        <div className='interscroller tw-absolute tw-w-full tw-h-full'>
          <div className='tw-fixed tw-top-1/2 tw-left-1/2 tw--translate-x-1/2 tw--translate-y-1/2'>
            <Ad googleAd type='homeMiddleLeaderboard' />
          </div>
        </div>
      </div>
    </div>
  </MobileOnlyView></div>

  {items[8] && <div className='tw-mt-16'><EditorsPicks items={items.slice(8)} /></div>}
</div>

export default CenterTop;

CenterTop.propTypes = {
  /**
  * 4 items for content
  */
  items: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};