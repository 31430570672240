import axios from 'axios';
//import queryString from "query-string";
import https from 'https'
import { SEARCH_ROWS, SEARCH_START, NEWSLETTERS_PER_PAGE } from './constants';
import { deserializeResponseBodyColumns } from '@app/utils/homepageUtils';
import * as Sentry from '@sentry/nextjs'

const site = process.env.NEXT_PUBLIC_SITE_ID;
const start = SEARCH_START;
const rows = SEARCH_ROWS;

const getHeader = () =>{
  const agent = new https.Agent({
    rejectUnauthorized: false,
  });
  return { httpsAgent: agent };
}
const apiService = axios.create({
  baseURL:`${process.env.NEXT_PUBLIC_API_URL}`
});

/* Serverside functions below */

export const get = async (path, params = {}) => {
  //console.log('get',path);
  try{
    let qs = new URLSearchParams(params).toString();
    const res = await apiService.get(`${path}${qs}`,{headers:getHeader()})
    if(res.status===200)
      return res;
  }catch(error){
    if (!error.response || error.response?.status !== 404) {
      // Only capture if not 404
      Sentry.captureException(error)
    }

    console.debug(error)
    if (error.response){
      console.debug(error.response.data);
      return error.response;
    }
  }
}
export const post = async (path, params = {}) => {
  //console.log('post',path, params, typeof(params));
  try{
    const res = await apiService.post(`${path}`,params, { headers:getHeader()})
    if(res.status===200)
      return res;
  }catch(error){
    if (!error.response || error.response?.status !== 404) {
      // Only capture if not 404
      Sentry.captureException(error)
    }
    
    console.debug('post',error)
    if (error.response){
      console.debug(error.response.data);
      return error.response;
    }
      
  }
}

export const search = async (params={}) =>{
  //console.log(process.env);
  return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/search?`,{
    site,
    start,
    rows,
    ...params
  });
}

export const listMagazines = async (params={}) =>{
  return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/magazines?`,{
    site,
    start,
    rows,
    sort: 'Latest',
    ...params
  });
}

export const listAuthors = async (ids) =>{
  let queryIds = ids.map(id=>`authorIds=${id}`).join('&');
  return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/search?${queryIds}&`,{
    site,
    start,
    rows,
    sort: 'Latest',
  });
}

export const listEvents = async () =>{
  return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/search?`,{
    site,
    start,
    rows,
    itemTypes: 'event',
    sort: 'Latest',
  });
}
export const listPreviousEvents = async () =>{
  return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/search?`,{
    site,
    start,
    rows,
    itemTypes: 'event',
    sort: 'Latest',
    itemTypeListing: 'previous',
  });
}
export const listUpcomingEvents = async () =>{
  return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/search?`,{
    site,
    start,
    rows,
    itemTypes: 'event',
    sort: 'Latest',
    itemTypeListing: 'upcoming',
  });
}
export const listVideos = async () =>{
  return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/search?`,{
    site,
    start,
    rows,
    itemTypes: 'video',
    sort: 'Latest',
  });
}
export const listWebinars = async () =>{
  return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/search?`,{
    site,
    start,
    rows,
    itemTypes: 'webinar',
    sort: 'Latest',
  });
}
export const listPreviousWebinars = async () =>{
  return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/search?`,{
    site,
    start,
    rows,
    itemTypes: 'webinar',
    sort: 'Latest',
    itemTypeListing: 'previous',
  });
}
export const listUpcomingWebinars = async () =>{
  return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/search?`,{
    site,
    start,
    rows,
    itemTypes: 'webinar',
    sort: 'Latest',
    itemTypeListing: 'upcoming',
  });
}
export const getTopic = async (id) =>{
  return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/topic?`,{
    id:id,
    site,
    start,
    rows,
    sort: 'Latest'
  });
}
export const listGallery = async () =>{
  return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/search?`,{
    site,
    start,
    rows,
    itemTypes: 'gallery',
    sort: 'Latest',
  });
}

export const listCountries = async (ids) =>{
  let queryIds = ids.map(id=>`countryIds=${id}`).join('&');
  return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/search?${queryIds}&`,{
    site,
    start,
    rows,
    sort: 'Latest',
  });
}

export const listTags = async (ids) =>{
  let queryIds = ids.map(id=>`tags=${id}`).join('&');
  return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/search?${queryIds}&`,{
    site,
    start,
    rows,
    sort: 'Latest',
  });
}

export const listCategories = async (ids) =>{
  let queryIds = ids.map(id=>`categoryIds=${id}`).join('&');
  return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/search?${queryIds}&`,{
    site,
    start,
    rows,
    sort: 'Latest',
  });
}
export const getViewLogs = async (sub) =>{
  return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/viewlogs?`,{
    siteId:process.env.NEXT_PUBLIC_SITE_ID,
    sub:sub,
    ttl: parseInt(process.env.NEXT_PUBLIC_ARTICLE_EXPIRED_DUR)
  });
}
export const postViewLogs = async (id,sub,viewAt) =>{
  return post(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/viewlogs`,{
    siteId: process.env.NEXT_PUBLIC_SITE_ID,
    sub:sub,
    id:id,
    viewAt:viewAt
  });
}

const getPreviewToken = async(id, token) => {
  return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/cms/preview-token?`,{id,token});
}

export const getEvent = async (id) =>{
  return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/event?`,{site,id});
}

export const getWebinar = async (id) =>{
  return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/webinar?`,{site,id});
}

export const getArticle = async (id, token) =>{
  if (typeof token === 'string' && token.length > 0) {
    const { data: previewToken } = await getPreviewToken(id, token);
    if (String(previewToken?.id) === id) {
      return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/article?`,{ site, id, allStatuses: true, preview: previewToken.token });
    }
  }
  const allStatuses = false;
  return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/article?`,{site,id,allStatuses});
}

export const getMagazine = async (id) =>{
  return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/magazine?`,{site,id});
}

export const getGallery = async (id, token) =>{
  if (typeof token === 'string' && token.length > 0) {
    const { data: previewToken } = await getPreviewToken(id, token);
    if (String(previewToken?.id) === id) {
      return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/gallery?`,{ site, id, allStatuses: true, preview: previewToken.token });
    }
  }
  const allStatuses = false;
  return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/gallery?`,{site,id,allStatuses});
}

export const getVideo = async (id, token) =>{
  if (typeof token === 'string' && token.length > 0) {
    const { data: previewToken } = await getPreviewToken(id, token);
    if (String(previewToken?.id) === id) {
      return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/video?`,{ site, id, allStatuses: true, preview: previewToken.token });
    }
  }
  const allStatuses = false;
  return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/video?`,{site,id,allStatuses});
}

export const getPage = async (slug, id, token) =>{
  if (typeof token === 'string' && token.length > 0) {
    const { data: previewToken } = await getPreviewToken(id, token);
    if (String(previewToken?.id) === id) {
      return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/info?`,{ site, id, slug, allStatuses: true, preview: previewToken.token });
    }
  }
  return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/info?`,{site,slug});
}

// To render shortcode article summary only
export const getContentItemSummary = async (id, allStatuses) =>{
  return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/contentItem?`,{site,id,allStatuses});
}

export const getAllRss = async () => {
  return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/search?`, { 
    site, 
    start, 
    rows, 
    sort: "Latest", // SORTINGS., 
    allStatuses: false 
  });
}

export const getHomepage = async () => {
  return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/home?`, {site})
}

export const getHomepageJson = async () => {
  var {data} = await get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/home?`, {site, format:"json"})
  data = deserializeResponseBodyColumns(data);
  console.debug(data)
  return data
}

export const getMostPopular = async () => {
  return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/ranking/most-popular?`, {
    site: process.env.NEXT_PUBLIC_SITE_ID,
    allStatuses: false
  })
}

export const getRssByCategory = async (categoryIds) => {
  let queryIds = categoryIds.map(id=>`categoryIds=${id}`).join('&');
  return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/search?${queryIds}&`,{
    site,
    start,
    rows,
    allStatuses: false
  });
}

export const getNewsletters = async (start = 0) => {
  return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/newsletters?`, {
    site,
    start: start,
    rows: NEWSLETTERS_PER_PAGE
  })
}

export const getAspxRedirect = async (url) => {
  return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/aspxRedirect?`, {
    site,
    url:url
  });
};

export const getSourceUrlRedirect = async (url) => {
    return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/source-url-redirect?`, {
        site,
        url: url
    });
};

export const getAccount = async (userId) => {
  
  try{
    const res = await axios.get(`https://${process.env.AUTHSERVER_DOMAIN}/api/v1/users/getuserinfo?userId=` + userId + `&siteId=` + 14);
    
    if(res.status===200)
      return res;
  }catch(error){
    if (!error.response || error.response?.status !== 404) {
      Sentry.captureException(error)
    }

    console.debug(error)
    if (error.response){
      console.debug(error.response.data);
      return error.response;
    }
  }
  //return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/account`, {});
};

/*

export const post = async (path, params = {}) => {
  return apiService.post(path,params, {headers:getHeader()})
}

export const put = async (path, params = {}) => {
  return apiService.put(path,params, {headers:getHeader()})
}

export const getArticles = async (params={}) =>{
  return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/article`,{site:process.env.SITE_ID,...params});
}
export const getMagazines = async (params={}) =>{
  return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/magazines`,{site:Number(process.env.SITE_ID),id:1545,rows:SEARCH_ROWS,itemTypes: 'magazine',...params});
}

export const getEvent = async (params={}) =>{
  return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/event`,{site:process.env.SITE_ID,...params});
}
export const getRelatedArticles = async (params={}) =>{
  return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/extras`,{site:process.env.SITE_ID,...params});
}
export const getMetadata = async (params={}) =>{
  return get(`${process.env.APP_CONTENT_API_ENDPOINT}/api/v1/metadata`,{site:process.env.SITE_ID,...params});
}

export const apiGet = async (params = {}) =>{
    
  return get(`/api/get`,{
    site:process.env.SITE_ID,
    start:SEARCH_START,
    rows:SEARCH_ROWS,
    ...params
  });
}
*/
/* apiService.interceptorsresponse.use(
  function()
) */