import React from 'react';
import PropTypes from 'prop-types';
import ReactPlayerLoader from '@brightcove/react-player-loader';

export const Video = props => {
  switch (props.player.toLowerCase()) {
    case 'brightcove':
      switch (props.type) {
        case 'body':
          return <div className='tw-block tw-relative tw-max-w-xl tw-mx-auto'>
            <div className='tw-pt-56.25%'>
              <iframe src={`https://players.brightcove.net/${process.env.NEXT_PUBLIC_BRIGHTCOVE_ID}/0a88MFiFk_default/index.html?videoId=${props.id.trim()}`} allowFullScreen allow="encrypted-media" className='tw-block tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-w-full tw-h-full'></iframe>
            </div>
          </div>

        case 'preview':
          const previewSettings = {
            accountId: `${process.env.NEXT_PUBLIC_BRIGHTCOVE_ID}`,
            playerId: 'xxmnbBz13',
            videoId: props.id,
            embedOptions: {
              responsive: { aspectRatio: "16:9" },
            },
            embedType: "in-page",
            options: {
              autoplay: true,
              controls: false,
              loop: false,
              muted: true,
              playsinline: true,
              preload: "preload"
            }
          };
          return <ReactPlayerLoader {...previewSettings} />

        case 'head':
          // const headSettings = {
          //   accountId: `${process.env.NEXT_PUBLIC_BRIGHTCOVE_ID}`,
          //   playerId: 'xxmnbBz13',
          //   videoId: props.id,
          //   manualCleanup: true,
          //   embedOptions: {
          //     responsive: { aspectRatio: "16:9" },
          //   },
          //   embedType: "in-page",
          //   options: {
          //     autoplay: false,
          //     controls: true,
          //     loop: true,
          //     muted: false,
          //     playsinline: true,
          //     preload: "preload"
          //   },
          //   attrs: {
          //     className: 'tw-hidden'
          //   }
          // };
          return <div className='tw-block tw-relative tw-max-w-full'>
            <div className='tw-pt-56.25%'>
              <iframe src={`https://players.brightcove.net/${process.env.NEXT_PUBLIC_BRIGHTCOVE_ID}/0a88MFiFk_default/index.html?videoId=${props.id.trim()}`} allowFullScreen allow="encrypted-media" className='tw-block tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-w-full tw-h-full'></iframe>
            </div>
            {/* <ReactPlayerLoader {...headSettings} /> */}
          </div>
        default:
          return null;
      }
    case 'vimeo':
      switch (props.type) {
        case 'body':
          return <div className='tw-block tw-relative tw-max-w-xl tw-mx-auto'>
            <div className='tw-pt-56.25%'>
              <iframe src={`https://player.vimeo.com/video/${props.id.trim()}`} allow='fullscreen' className='tw-block tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full' />
            </div>
          </div>
          break;
        case 'preview':
          return <div className='tw-relative'>
              <div className='tw-pt-56.25%'>
                <iframe src={`https://player.vimeo.com/video/${props.id.trim()}?autopause=0&autoplay=1&muted=1&loop=1`} allow='autoplay; muted; loop;' className='tw-block tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full' />
              </div>
            </div>

        case 'head':
          return <div className='tw-block tw-relative tw-max-w-full'>
            <div className='tw-pt-56.25%'>
              <iframe src={`https://player.vimeo.com/video/${props.id.trim()}`} allow='fullscreen' className='tw-block tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full' />
            </div>
          </div>
          break;
        default:
          return null;
      }
    case 'youtube':
      switch (props.type) {
        case 'body':
          return <div className='tw-block tw-relative tw-max-w-xl tw-mx-auto'>
            <div className='tw-pt-56.25%'>
              <iframe src={`https://www.youtube.com/embed/${props.id.trim()}`} allowFullScreen allow="encrypted-media" className='tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-w-full tw-h-full'></iframe>
            </div>
          </div>

        case 'preview':
          return null;
          // <div className='tw-block tw-relative tw-max-w-full'>
          //   <div className='tw-pt-56.25%'>
          //     <iframe src={`https://www.youtube.com/embed/${props.id}?autoplay=1&modestbranding=1&showinfo=0&controls=0&disablekb=1&fs=0`} allow="encrypted-media, autoplay, muted, loop" className='tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-w-full tw-h-full'></iframe>
          //   </div>
          // </div>

        case 'head':
          return <div className='tw-block tw-relative tw-max-w-full'>
            <div className='tw-pt-56.25%'>
              <iframe src={`https://www.youtube.com/embed/${props.id.trim()}`} allowFullScreen allow="encrypted-media" className='tw-absolute tw-top-0 tw-right-0 tw-bottom-0 tw-left-0 tw-w-full tw-h-full'></iframe>
            </div>
          </div>
        default:
          return null;
      }
    default:
      return null;
  }
}

export default Video;

Video.propTypes = {
  /**
  * ID of video
  */
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  /**
  * Type of video player
  */
  player: PropTypes.oneOf(['brightcove', 'Brightcove', 'vimeo', 'Vimeo', 'youtube', 'Youtube']),
  /**
  * Display type (preview for ArticleSummary, head for ArticleImage, body for shortcode)
  */
  type: PropTypes.oneOf(['body', 'preview', 'head']),
};

Video.defaultProps = {
  player: 'brightcove',
  type: 'body'
}
