import { OrganizationJsonLd } from "next-seo"
import Head from "next/head"

export const CorporateContact = () => {
  return (
    <>
      <Head >
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=Edge,chrome=1" />
        <meta name="copyright" content="© Haymarket Media Ltd." />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, user-scalable=no" />
      </Head>
      <OrganizationJsonLd
        name="AsianInvestor"
        url={"https://www.asianinvestor.net"} // {process.env.NEXT_PUBLIC_API_URL}
        sameAs={[
          "https://www.facebook.com/AsianInvestor/",
          "https://twitter.com/AsianInvestor/",
          "https://www.linkedin.com/company/asian-investor/",
        ]}
        contactPoint={[
          {
            contactType: "customer service",
            telephone: "+852 2122 5222",
            email: "subscriptions@asianinvestor.net",
          }
        ]}
        logo="https://cdn.i.haymarketmedia.asia/?n=%2fasian-investor%2fseo%2fAI-publisherLogo.png"
        parentOrganization={{
          "@type": "Organization",
          "name": "Haymarket",
          "url": "https://www.haymarket.com",
          "logo": "https://cdn.i.haymarketmedia.asia/?n=%2fshared%2fseo%2fHaymarket-112x112.png&h=112&w=112&q=100&v=20190520&c=0",
          "sameAs": [
            null,
            "https://twitter.com/haymarket_media/",
            "https://www.linkedin.com/company/haymarket-media-group/",
            "https://www.instagram.com/haymarket.media/"
          ]
        }}
      />
    </>
  )
}