import React from 'react';
import PropTypes from 'prop-types';
import { ArticleSummary } from '@app/components';

/**
* Most read UI component for Home
*/
export const MostReadArticle = props =>
  // <div className='tw-flex tw-flex-row tw-flex-nowrap tw-gap-2'>
  //   <div className='tw-text-xl tw-font-extrabold tw-text-center tw-tracking-tighter tw-flex-none tw-w-6'>{props.number}</div>
  //   <ArticleSummary variant='side' {...props.item} column={false} mostRead />
  // </div>
  <ArticleSummary variant='side' {...props.item} column={false} mostRead />

export default MostReadArticle;

MostReadArticle.propTypes = {
  /**
  * Number for display
  */
  number: PropTypes.node.isRequired,
  /**
  * Item for display
  */
  item: PropTypes.object.isRequired,
};