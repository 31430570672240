import React, {  Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { format } from 'date-fns';
import { dateTime, getDiffYearMonthDate, getHourMinute, imgSrcHelper, linkHelper, trimContent } from '@app/utils';
import { Video, Button, Column, SocialShare } from '@app/components';
import metadata from '@app/metadata.json';

// Author helper function
const Author = ({ authors, center, partner, className, ...props }) =>
  <div className={className.container}>
    {partner && center && <span className={className.partner}>In partnership with </span>}
    {authors.map((author, i) =>
      <Fragment key={i}>
        {i > 0 && ', '}
        <Link href={linkHelper('author', author.slug, author.id)}>
          <a className={className.link}>{author.display}</a>
        </Link>
      </Fragment>
    )}
  </div>

/**
* Article UI component for user interaction
*/
export const ArticleSummary = props => {
  const [showPreview, setPreview] = useState(false);
  // const [browserShare, setBrowserShare] = useState(false);
  const { id, variant, categoryNames, itemType, publishedAt, authors, heading: headingRawData, eventStartAt, eventEndAt, eventUrl,
    webinarStartAt, webinarDuration, webinarUrl, introText: introTextRawData, slug, imagePath, alternateImagePath, embed, linkUrl,
    video: videoRawData, simple, home, tags, column, columnProps, geoTarget, mostRead } = props;
  const heading = trimContent(headingRawData, 150);
  const introText = trimContent(introTextRawData);
  let link;
  switch (itemType) {
    case 'link':
      link = linkUrl;
      break;
    case 'event':
      link = eventUrl ? linkHelper(itemType, slug, id) : false;
      break;
    case 'webinar':
      link = webinarUrl ? linkHelper(itemType, slug, id) : false;
      break;
    case 'magazine':
      link = linkHelper(itemType, 'issue', id);
      break;
    default:
      link = linkHelper(itemType, slug, id);
      break;
  }
  const center = variant == 'center';
  const side = variant == 'side';
  const figureSide = variant == 'figureSide';
  const top = variant == 'top';
  const top4 = variant == 'top4';
  const top5 = variant == 'top5';
  const editorsPicks = variant == 'editorsPicks';
  const trending = variant == 'trending';
  const homeQuickSearch = variant == 'homeQuickSearch';
  const award = categoryNames && categoryNames.some(categoryName => categoryName.toLowerCase() === 'awards') && 'awards';
  const partner = categoryNames && categoryNames.some(categoryName => categoryName.toLowerCase() === 'partner content') && 'partner content';
  const type = (itemType == 'gallery' && 'gallery') || (itemType === 'video' && 'video'); // Replay material icon font content, reference: https://fonts.google.com/icons
  const article = itemType == 'article';
  const gallery = itemType == 'gallery';
  const video = itemType == 'video';
  const event = itemType == 'event' && 'events';
  const webinar = itemType == 'webinar' && 'webinars';
  const topic = itemType == 'topic';
  const customLink = itemType == 'link';
  const magazine = itemType == 'magazine';
  const placeholder = '/images/placeholder.svg';
  const placeholderMagazine = '/images/placeholderMagazine.svg';
  const GeoSg = geoTarget && tags && tags.some(tags => tags.toLowerCase() == process.env.NEXT_PUBLIC_GEO_TARGET_SG_TAG) && true;
  const GeoNoJp = geoTarget && tags && tags.some(tags => tags.toLowerCase() == process.env.NEXT_PUBLIC_GEO_TARGET_NO_JP_TAG) && true;
  const render = {
    slug: (partner && (event || webinar || award) && center) || (partner && !(center || top || top4 || top5 || editorsPicks || trending || homeQuickSearch || figureSide)) || (!partner && !figureSide && (event || webinar || award)),
    header: !figureSide,
    link: (link && !magazine) || (magazine && linkUrl),
    asset: !customLink && !(award && side) && !figureSide,
    author: authors && (article || gallery || video || topic),
    viewMagazine: linkUrl && magazine,
    publishedAt: publishedAt && !((partner || event || webinar || topic || customLink)),
    eventTime: eventStartAt && event,
    webinarTime: webinarStartAt && webinar,
    introText: introText && (center || top || top5 ),
    audio: top5 && partner && !customLink,
    figure: !mostRead && (center || figureSide || trending || top || top5 || editorsPicks || partner || magazine /*|| award || type || event || webinar*/),
  }

  const findObject = (objs, slug) => objs[objs.map(o => o.slug?.toLowerCase()).indexOf(slug)] || null;

  const getLink = props => linkHelper('category', props.slug, props.id)

  const partnerLinkData = findObject(metadata.categories, 'partner-content');
  const awardLinkData = findObject(metadata.categories, 'awards');


  const bigger = partner && center;
  const today = new Date();

  const handleMouseOver = () => {
    setPreview(true);
  }

  const handleMouseOut = () => {
    setPreview(false);
  }

  // const handleMobileShare = async () => {
  //   try {
  //     await navigator.share({url: link });
  //     // console.log("Data was shared successfully");
  //   } catch (err) {
  //     // console.error("Share failed:", err.message);
  //   }
  // }

  // const handleBrowserShare = () => {
  //   setBrowserShare(true);
  // }

  // const handleBrowserShareClose = () => {
  //   setBrowserShare(false);
  // }

  const joinObjectValues = (obj, delimiter = ' ') => {
    return Object.values(Object.entries(obj).reduce((a, [k, v]) => (!v ? a : (a[k] = v, a)), {}))
      .map(val => {
        if (typeof val == 'object') {
          return joinObjectValues(val, delimiter);
        }
        return val;
      })
      .join(delimiter)
  }

  const fontStyle = {
    header: {
      base: 'tw-font-headline',
      side: (side || figureSide) && 'tw-text-lg tw-leading-1.2em tw-tracking-tight tw-font-semibold',
      center: (center || top5 || editorsPicks || homeQuickSearch) && 'tw-text-lg tw-leading-1.2em tw-tracking-tight tw-font-bold sm:tw-text-2xl sm:tw-leading-1.2em lg:tw-text-3xl lg:tw-leading-1.2em',
      trending: trending && 'tw-text-lg tw-leading-1.2em tw-tracking-tight tw-font-bold sm:tw-text-2xl sm:tw-leading-1.2em lg:tw-text-xl lg:tw-leading-1.2em xl:tw-text-2xl xl:tw-leading-1.2em 3xl:tw-text-3xl 3xl:tw-leading-1.2em',
      top: top && 'tw-text-3xl tw-leading-1.2em tw-tracking-tight tw-font-bold lg:tw-text-4xl lg:tw-leading-1.2em',
      top4: top4 && 'tw-text-lg tw-leading-1.2em tw-tracking-tight tw-font-bold sm:tw-text-2xl sm:tw-leading-1.2em lg:tw-text-3xl lg:tw-leading-1.2em',
    },
    asset: {
      base: 'tw-block tw-float-left tw-clear-both',
      side: (side || figureSide) && 'tw-text-xs tw-leading-tight tw-tracking-tight',
      center: (center || top4 || top5 || editorsPicks || trending || homeQuickSearch) && 'tw-text-xs tw-leading-tight tw-tracking-tight sm:tw-text-sm sm:tw-leading-tight lg:tw-text-base lg:tw-leading-tight',
      top: top && 'tw-text-sm tw-leading-tight tw-tracking-tight sm:tw-text-base sm:tw-leading-tight',
    },
    introText: {
      base: 'tw-text-gray-500',
      center: (center || top5 || editorsPicks || trending) && 'lg:tw-text-lg lg:tw-leading-normal',
      top: top && 'lg:tw-text-lg lg:tw-leading-normal',
    },
  }

  const classes = {
    side: {
      container: joinObjectValues({
        base: 'tw-grid tw-auto-cols-fr tw-gap-x-4',
        nonColumn: !column && {
          geoTargetSG: GeoSg && process.env.NEXT_PUBLIC_GEO_TARGET_SG_CONTENT,
          geoTargetNoJP: GeoNoJp && process.env.NEXT_PUBLIC_GEO_TARGET_NO_JP_CONTENT,
        },
      }),
      slug: {
        container: 'tw-row-end-2 tw-col-span-6',
        link: 'tw-block tw-float-left tw-clear-both tw-font-headline tw-uppercase tw-font-bold tw-text-xs tw-text-primary-main hover:tw-underline',
      },
      header: {
        container: 'tw-row-end-3 tw-col-span-6',
        link: joinObjectValues({
          ...fontStyle.header,
          addition: 'tw-block tw-transition-all hover:tw-underline',
        }),
        div: joinObjectValues({
          ...fontStyle.header,
        }),
      },
      asset: 'tw-mt-2 tw-row-end-4 tw-col-span-6',
      author: {
        container: joinObjectValues({
          ...fontStyle.asset,
          addition: 'tw-font-bold',
        }),
        partner: 'tw-font-normal',
        link: 'hover:tw-underline',
      },
      magazine: joinObjectValues({
        ...fontStyle.asset,
        addition: 'tw-font-bold tw-text-primary-main tw-transition-all hover:tw-underline',
      }),
      time: joinObjectValues({
        ...fontStyle.asset,
        addition: 'tw-text-gray-500',
      }),
      introText: undefined,
      figure: {
        container: joinObjectValues({
          base: 'tw-row-end-5 tw-row-span-4',
          general: !magazine && 'tw-col-span-4',
          magazine: magazine && 'tw-col-span-2',
        }),
        link: 'tw-block tw-relative',
        div: 'tw-relative',
        icon: 'tw-font-icon tw-pointer-events-none tw-text-2xl tw-text-white tw-absolute tw-bottom-0 tw-right-0 tw-z-10 tw-px-2 tw-py-0 tw-bg-primary-main',
        img: 'tw-block tw-w-full tw-h-auto',
        video: {
          container: 'tw-relative tw-w-full',
          layer: 'tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-10',
          link: 'tw-absolute tw-block tw-w-full tw-h-full tw-z-10',
        },
      },
    },
    figureSide: {
      container: joinObjectValues({
        base: 'figureSide tw-grid tw-auto-cols-fr tw-gap-x-4',
        nonColumn: !column && {
          geoTargetSG: GeoSg && process.env.NEXT_PUBLIC_GEO_TARGET_SG_CONTENT,
          geoTargetNoJP: GeoNoJp && process.env.NEXT_PUBLIC_GEO_TARGET_NO_JP_CONTENT,
        },
      }),
      // slug: {
      //   container: 'tw-start-1 tw-row-end-2 tw-col-span-6',
      //   link: 'tw-block tw-float-left tw-clear-both tw-font-headline tw-uppercase tw-font-bold tw-text-xs tw-text-primary-main hover:tw-underline',
      // },
      // header: {
      //   container: 'tw-start-2 tw-row-end-3 tw-col-span-6',
      //   link: joinObjectValues({
      //     ...fontStyle.header,
      //     addition: 'tw-block tw-transition-all hover:tw-underline',
      //   }),
      //   div: joinObjectValues({
      //     ...fontStyle.header,
      //   }),
      // },
      // asset: 'tw-mt-2 tw-start-3 tw-row-end-4 tw-col-span-6',
      // author: {
      //   container: joinObjectValues({
      //     ...fontStyle.asset,
      //     addition: 'tw-font-bold',
      //   }),
      //   partner: 'tw-font-normal',
      //   link: 'hover:tw-underline',
      // },
      // magazine: joinObjectValues({
      //   ...fontStyle.asset,
      //   addition: 'tw-font-bold tw-text-primary-main tw-transition-all hover:tw-underline',
      // }),
      // time: joinObjectValues({
      //   ...fontStyle.asset,
      //   addition: 'tw-text-gray-500',
      // }),
      introText: undefined,
      figure: {
        container: joinObjectValues({
          general: !magazine && 'tw-row-start-4 tw-row-end-5 tw-col-span-6',
          magazine: magazine && 'tw-row-start-1 tw-row-end-5 tw-col-span-2',
        }),
        link: 'tw-block tw-relative',
        div: 'tw-relative',
        icon: 'tw-font-icon tw-pointer-events-none tw-text-2xl tw-text-white tw-absolute tw-bottom-0 tw-right-0 tw-z-10 tw-px-2 tw-py-0 tw-bg-primary-main',
        img: 'tw-block tw-w-full tw-h-auto',
        video: {
          container: 'tw-relative tw-w-full',
          layer: 'tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-10',
          link: 'tw-absolute tw-block tw-w-full tw-h-full tw-z-10',
        },
      },
    },
    center: {
      container: joinObjectValues({
        base: 'tw-grid tw-auto-cols-fr tw-gap-x-4 3xl:tw-gap-x-8',
        nonColumn: !column && {
          geoTargetSG: GeoSg && process.env.NEXT_PUBLIC_GEO_TARGET_SG_CONTENT,
          geoTargetNoJP: GeoNoJp && process.env.NEXT_PUBLIC_GEO_TARGET_NO_JP_CONTENT,
        },
        partner: !embed && partner && 'tw-p-2 tw-bg-secondary-light md:tw-p-4',
        embed: embed && {
          nonPartner: partner && 'tw-p-4 tw-border-4 tw-border-solid tw-bg-white sm:tw-p-8',
          partner: 'tw-p-4 tw-bg-secondary-light sm:tw-p-8',
        }
      }),
      slug: {
        container: 'tw-row-start-1 tw-row-end-2 tw-col-span-6 3xl:tw-col-span-full',
        link: 'tw-block tw-float-left tw-clear-both tw-font-headline tw-uppercase tw-font-bold tw-text-xs tw-text-primary-main hover:tw-underline',
      },
      header: {
        container: joinObjectValues({
          general: !magazine && 'tw-row-start-2 tw-row-end-3 tw-col-span-6 3xl:tw-col-span-4',
          magazine: magazine && 'tw-row-start-1 tw-row-end-2 tw-col-span-6',
        }),
        link: joinObjectValues({
          ...fontStyle.header,
          addition: 'tw-block tw-transition-all hover:tw-underline',
        }),
        div: joinObjectValues({
          ...fontStyle.header,
        }),
      },
      asset: joinObjectValues({
        general: !magazine && 'tw-mt-2 tw-row-start-3 tw-row-end-4 tw-col-span-6 3xl:tw-col-span-4',
        magazine: magazine && 'tw-mt-2 tw-row-start-2 tw-row-end-3 tw-col-span-6',
      }),
      author: {
        container: joinObjectValues({
          ...fontStyle.asset,
          addition: 'tw-font-bold',
        }),
        partner: 'tw-font-normal',
        link: 'hover:tw-underline',
      },
      magazine: joinObjectValues({
        ...fontStyle.asset,
        addition: 'tw-font-bold tw-text-primary-main tw-transition-all hover:tw-underline',
      }),
      time: joinObjectValues({
        ...fontStyle.asset,
        addition: 'tw-text-gray-500',
      }),
      introText: joinObjectValues({
        ...fontStyle.introText,
        addition: 'tw-hidden 3xl:tw-block',
        general: !(partner || magazine) && '3xl:tw-row-start-2 3xl:tw-row-end-5 3xl:tw-col-span-4',
        partner: partner && !customLink && '3xl:tw-mt-4 3xl:tw-row-start-4 3xl:tw-row-end-5 3xl:tw-col-span-4',
        partner2: partner && customLink && '3xl:tw-mt-4 3xl:tw-row-start-3 3xl:tw-row-end-4 3xl:tw-col-span-4',
        magazine: magazine && 'sm:tw-block sm:tw-mt-4 sm:tw-row-start-3 sm:tw-row-end-4 sm:tw-col-span-6',
      }),
      figure: {
        container: joinObjectValues({
          general: !(partner || magazine || event || webinar || award) && 'tw-row-start-2 tw-row-end-5 tw-col-span-4 sm:tw-mt-4 sm:tw-row-start-4 sm:tw-col-span-6 md:tw-mt-0 md:tw-row-start-2 md:tw-col-span-3 3xl:tw-row-start-2 3xl:tw-col-span-4',
          slug: !(partner || magazine) && (event || webinar || award) && 'tw-row-start-1 tw-row-end-5 tw-col-span-4 sm:tw-mt-4 sm:tw-row-start-4 sm:tw-col-span-6 md:tw-mt-0 md:tw-row-start-1 md:tw-col-span-3 3xl:tw-row-start-2 3xl:tw-col-span-4',
          partner: partner && 'tw-row-start-2 tw-row-end-5 tw-col-span-5 sm:tw-mt-4 sm:tw-row-start-4 sm:tw-col-span-6 lg:tw-mt-0 lg:tw-row-start-2 lg:tw-col-span-5 3xl:tw-row-end-6 3xl:tw-col-span-3',
          magazine: magazine && 'tw-row-start-1 tw-row-end-5 tw-col-span-2 sm:tw-row-end-5',
        }),
        link: 'tw-block tw-relative',
        div: 'tw-relative',
        icon: 'tw-font-icon tw-pointer-events-none tw-text-2xl tw-text-white tw-absolute tw-bottom-0 tw-right-0 tw-z-10 tw-px-2 tw-py-0 tw-bg-primary-main',
        img: 'tw-block tw-w-full tw-h-auto',
        video: {
          container: 'tw-relative tw-w-full',
          layer: 'tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-10',
          link: 'tw-absolute tw-block tw-w-full tw-h-full tw-z-10',
        },
      },
    },
    top: {
      container: joinObjectValues({
        base: 'tw-grid',
        nonColumn: !column && {
          geoTargetSG: GeoSg && process.env.NEXT_PUBLIC_GEO_TARGET_SG_CONTENT,
          geoTargetNoJP: GeoNoJp && process.env.NEXT_PUBLIC_GEO_TARGET_NO_JP_CONTENT,
        },
        partner: partner && 'tw-p-2 tw-bg-secondary-light md:tw-p-4',
      }),
      slug: {
        container: undefined,
        link: 'tw-block tw-float-left tw-clear-both tw-font-headline tw-uppercase tw-font-bold tw-text-xs tw-text-primary-main hover:tw-underline lg:tw-text-base',
      },
      header: {
        container: undefined,
        link: joinObjectValues({
          ...fontStyle.header,
          addition: 'tw-block tw-transition-all hover:tw-underline',
        }),
        div: joinObjectValues({
          ...fontStyle.header,
        }),
      },
      asset: 'tw-mt-2',
      author: {
        container: joinObjectValues({
          ...fontStyle.asset,
          addition: 'tw-font-bold',
        }),
        partner: 'tw-font-normal',
        link: 'hover:tw-underline',
      },
      time: joinObjectValues({
        ...fontStyle.asset,
        addition: 'tw-text-gray-500',
      }),
      introText: joinObjectValues({
        ...fontStyle.introText,
        addition: 'tw-mt-4',
      }),
      figure: {
        container: 'tw-mt-4',
        link: 'tw-block tw-relative',
        div: 'tw-relative',
        icon: 'tw-font-icon tw-pointer-events-none tw-text-2xl tw-text-white tw-absolute tw-bottom-0 tw-right-0 tw-z-10 tw-px-2 tw-py-0 tw-bg-primary-main',
        img: 'tw-block tw-w-full tw-h-auto',
        video: {
          container: 'tw-relative tw-w-full',
          layer: 'tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-10',
          link: 'tw-absolute tw-block tw-w-full tw-h-full tw-z-10',
        },
      },
    },
    top4: {
      container: joinObjectValues({
        base: 'tw-grid tw-auto-cols-fr tw-gap-x-4 3xl:tw-gap-x-8',
        nonColumn: !column && {
          geoTargetSG: GeoSg && process.env.NEXT_PUBLIC_GEO_TARGET_SG_CONTENT,
          geoTargetNoJP: GeoNoJp && process.env.NEXT_PUBLIC_GEO_TARGET_NO_JP_CONTENT,
        },
        partner: partner && 'tw-p-2 tw-bg-secondary-light md:tw-p-4',
      }),
      slug: {
        container: 'tw-row-start-1 tw-row-end-2 tw-col-span-6 3xl:tw-col-span-full',
        link: 'tw-block tw-float-left tw-clear-both tw-font-headline tw-uppercase tw-font-bold tw-text-xs tw-text-primary-main hover:tw-underline',
      },
      header: {
        container: 'tw-row-start-2 tw-row-end-3 tw-col-span-6 3xl:tw-col-span-4',
        link: joinObjectValues({
          ...fontStyle.header,
          addition: 'tw-block tw-transition-all hover:tw-underline',
        }),
        div: joinObjectValues({
          ...fontStyle.header,
        }),
      },
      asset: 'tw-mt-2 tw-row-start-3 tw-row-end-4 tw-col-span-6 3xl:tw-col-span-4',
      author: {
        container: joinObjectValues({
          ...fontStyle.asset,
          addition: 'tw-font-bold',
        }),
        partner: 'tw-font-normal',
        link: 'hover:tw-underline',
      },
      time: joinObjectValues({
        ...fontStyle.asset,
        addition: 'tw-text-gray-500',
      }),
      introText: joinObjectValues({
        ...fontStyle.introText,
        addition: 'tw-hidden 3xl:tw-block',
        general: !(partner || magazine) && '3xl:tw-row-start-2 3xl:tw-row-end-5 3xl:tw-col-span-4',
        partner: partner && !customLink && '3xl:tw-mt-4 3xl:tw-row-start-4 3xl:tw-row-end-5 3xl:tw-col-span-4',
        partner2: partner && customLink && '3xl:tw-mt-4 3xl:tw-row-start-3 3xl:tw-row-end-4 3xl:tw-col-span-4',
        magazine: magazine && 'sm:tw-block sm:tw-mt-4 sm:tw-row-start-3 sm:tw-row-end-4 sm:tw-col-span-6',
      }),
      figure: {
        container: joinObjectValues({
          general: !(partner || magazine || event || webinar || award) && 'tw-row-start-2 tw-row-end-5 tw-col-span-4 sm:tw-mt-4 sm:tw-row-start-4 sm:tw-col-span-6 md:tw-mt-0 md:tw-row-start-2 md:tw-col-span-3 3xl:tw-row-start-2 3xl:tw-col-span-4',
          slug: !(partner || magazine) && (event || webinar || award) && 'tw-row-start-1 tw-row-end-5 tw-col-span-4 sm:tw-mt-4 sm:tw-row-start-4 sm:tw-col-span-6 md:tw-mt-0 md:tw-row-start-1 md:tw-col-span-3 3xl:tw-row-start-2 3xl:tw-col-span-4',
          partner: partner && 'tw-mt-4 tw-row-start-4 tw-row-end-5 tw-col-span-6 lg:tw-mt-0 lg:tw-row-start-2 lg:tw-col-span-6 xl:tw-col-span-5 3xl:tw-row-end-6 3xl:tw-col-span-3',
          magazine: magazine && 'tw-row-start-1 tw-row-end-5 tw-col-span-2 sm:tw-row-end-5',
        }),
        link: 'tw-block tw-relative',
        div: 'tw-relative',
        icon: 'tw-font-icon tw-pointer-events-none tw-text-2xl tw-text-white tw-absolute tw-bottom-0 tw-right-0 tw-z-10 tw-px-2 tw-py-0 tw-bg-primary-main',
        img: 'tw-block tw-w-full tw-h-auto',
        video: {
          container: 'tw-relative tw-w-full',
          layer: 'tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-10',
          link: 'tw-absolute tw-block tw-w-full tw-h-full tw-z-10',
        },
      },
    },
    top5: {
      container: joinObjectValues({
        base: 'tw-grid tw-auto-cols-fr tw-gap-x-4 3xl:tw-gap-x-8',
        nonColumn: !column && {
          geoTargetSG: GeoSg && process.env.NEXT_PUBLIC_GEO_TARGET_SG_CONTENT,
          geoTargetNoJP: GeoNoJp && process.env.NEXT_PUBLIC_GEO_TARGET_NO_JP_CONTENT,
        },
        partner: partner && 'tw-p-2 tw-bg-secondary-light md:tw-p-4',
      }),
      slug: {
        container: 'tw-row-start-1 tw-row-end-2 tw-col-span-6 3xl:tw-col-span-full',
        link: 'tw-block tw-float-left tw-clear-both tw-font-headline tw-uppercase tw-font-bold tw-text-xs tw-text-primary-main hover:tw-underline',
      },
      header: {
        container: joinObjectValues({
          general: !magazine && 'tw-row-start-2 tw-row-end-3 tw-col-span-6 3xl:tw-col-span-4',
          magazine: magazine && 'tw-row-start-1 tw-row-end-2 tw-col-span-6',
        }),
        link: joinObjectValues({
          ...fontStyle.header,
          addition: 'tw-block tw-transition-all hover:tw-underline',
        }),
        div: joinObjectValues({
          ...fontStyle.header,
        }),
      },
      asset: joinObjectValues({
        general: !magazine && 'tw-mt-2 tw-row-start-3 tw-row-end-4 tw-col-span-6 3xl:tw-col-span-4',
        magazine: magazine && 'tw-mt-2 tw-row-start-2 tw-row-end-3 tw-col-span-6',
      }),
      author: {
        container: joinObjectValues({
          ...fontStyle.asset,
          addition: 'tw-font-bold',
        }),
        partner: 'tw-font-normal',
        link: 'hover:tw-underline',
      },
      magazine: joinObjectValues({
        ...fontStyle.asset,
        addition: 'tw-font-bold tw-text-primary-main tw-transition-all hover:tw-underline',
      }),
      time: joinObjectValues({
        ...fontStyle.asset,
        addition: 'tw-text-gray-500',
      }),
      introText: joinObjectValues({
        ...fontStyle.introText,
        addition: 'tw-hidden 3xl:tw-block',
        general: !(partner || magazine) && '3xl:tw-row-start-2 3xl:tw-row-end-5 3xl:tw-col-span-4',
        partner: partner && !customLink && '3xl:tw-mt-4 3xl:tw-row-start-4 3xl:tw-row-end-5 3xl:tw-col-span-4',
        partner2: partner && customLink && '3xl:tw-mt-4 3xl:tw-row-start-3 3xl:tw-row-end-4 3xl:tw-col-span-4',
        magazine: magazine && 'sm:tw-block sm:tw-mt-4 sm:tw-row-start-3 sm:tw-row-end-4 sm:tw-col-span-6',
      }),
      audio: {
        container: 'tw-mt-4 tw-row-start-4 tw-row-end-5 tw-col-span-6 3xl:tw-row-start-5 3xl:tw-row-end-6 3xl:tw-col-span-4',
        button: 'tw-inline-flex tw-items-center tw-font-headline tw-text-xs tw-font-semibold tw-uppercase tw-text-[#800000] tw-transition sm:tw-text-sm',
        icon: 'tw-font-icon icon-ai_audio tw-text-base tw-mr-1 sm:tw-text-lg',
      },
      figure: {
        container: joinObjectValues({
          general: !(partner || magazine || event || webinar || award) && 'tw-row-start-2 tw-row-end-5 tw-col-span-4 sm:tw-mt-4 sm:tw-row-start-4 sm:tw-col-span-6 md:tw-mt-0 md:tw-row-start-2 md:tw-col-span-3 3xl:tw-row-start-2 3xl:tw-col-span-4',
          slug: !(partner || magazine) && (event || webinar || award) && 'tw-row-start-1 tw-row-end-5 tw-col-span-4 sm:tw-mt-4 sm:tw-row-start-4 sm:tw-col-span-6 md:tw-mt-0 md:tw-row-start-1 md:tw-col-span-3 3xl:tw-row-start-2 3xl:tw-col-span-4',
          partner: partner && !customLink && 'tw-mt-4 tw-row-start-5 tw-row-end-6 tw-col-span-6 lg:tw-mt-0 lg:tw-row-start-2 lg:tw-col-span-6 xl:tw-col-span-5 3xl:tw-row-end-7 3xl:tw-col-span-3',
          partner2: partner && customLink && 'tw-mt-4 tw-row-start-3 tw-row-end-4 tw-col-span-6 lg:tw-mt-0 lg:tw-row-start-2 lg:tw-col-span-6 xl:tw-col-span-5 3xl:tw-row-end-5 3xl:tw-col-span-3',
          magazine: magazine && 'tw-row-start-1 tw-row-end-5 tw-col-span-2 sm:tw-row-end-5',
        }),
        link: 'tw-block tw-relative',
        div: 'tw-relative',
        icon: 'tw-font-icon tw-pointer-events-none tw-text-2xl tw-text-white tw-absolute tw-bottom-0 tw-right-0 tw-z-10 tw-px-2 tw-py-0 tw-bg-primary-main',
        img: 'tw-block tw-w-full tw-h-auto',
        video: {
          container: 'tw-relative tw-w-full',
          layer: 'tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-10',
          link: 'tw-absolute tw-block tw-w-full tw-h-full tw-z-10',
        },
      },
    },
    editorsPicks: {
      container: joinObjectValues({
        base: 'tw-grid tw-auto-cols-fr tw-gap-x-4 3xl:tw-gap-x-8',
        nonColumn: !column && {
          geoTargetSG: GeoSg && process.env.NEXT_PUBLIC_GEO_TARGET_SG_CONTENT,
          geoTargetNoJP: GeoNoJp && process.env.NEXT_PUBLIC_GEO_TARGET_NO_JP_CONTENT,
        },
        partner: partner && 'tw-p-2 tw-bg-secondary-light md:tw-p-4',
      }),
      slug: {
        // container: 'tw-row-start-1 tw-row-end-2 tw-col-span-6 3xl:tw-col-span-full',
        container: 'tw-row-start-1 tw-row-end-2 tw-col-span-6',
        link: 'tw-block tw-float-left tw-clear-both tw-font-headline tw-uppercase tw-font-bold tw-text-xs tw-text-primary-main hover:tw-underline',
      },
      header: {
        // container: 'tw-row-start-2 tw-row-end-3 tw-col-span-6 3xl:tw-col-span-4',
        container: 'tw-row-start-2 tw-row-end-3 tw-col-span-6',
        link: joinObjectValues({
          ...fontStyle.header,
          addition: 'tw-block tw-transition-all hover:tw-underline',
        }),
        div: joinObjectValues({
          ...fontStyle.header,
        }),
      },
      // asset: 'tw-mt-2 tw-row-start-3 tw-row-end-4 tw-col-span-6 3xl:tw-col-span-4',
      asset: 'tw-mt-2 tw-row-start-3 tw-row-end-4 tw-col-span-6',
      author: {
        container: joinObjectValues({
          ...fontStyle.asset,
          addition: 'tw-font-bold',
        }),
        partner: 'tw-font-normal',
        link: 'hover:tw-underline',
      },
      time: joinObjectValues({
        ...fontStyle.asset,
        addition: 'tw-text-gray-500',
      }),
      introText: joinObjectValues({
        ...fontStyle.introText,
        // addition: 'tw-hidden 3xl:tw-block',
        // general: !(partner || magazine) && '3xl:tw-row-start-2 3xl:tw-row-end-5 3xl:tw-col-span-4',
        // partner: partner && !customLink && '3xl:tw-mt-4 3xl:tw-row-start-4 3xl:tw-row-end-5 3xl:tw-col-span-4',
        // partner2: partner && customLink && '3xl:tw-mt-4 3xl:tw-row-start-3 3xl:tw-row-end-4 3xl:tw-col-span-4',
        addition: 'tw-hidden',
        // general: !(partner || magazine) && '3xl:tw-block 3xl:tw-mt-4 3xl:tw-row-start-4 3xl:tw-row-end-5 3xl:tw-col-span-6',
        partner: partner && !customLink && '3xl:tw-block 3xl:tw-mt-4 3xl:tw-row-start-4 3xl:tw-row-end-5 3xl:tw-col-span-6',
        partner2: partner && customLink && '3xl:tw-block 3xl:tw-mt-4 3xl:tw-row-start-3 3xl:tw-row-end-4 3xl:tw-col-span-6',
        magazine: magazine && 'sm:tw-block sm:tw-mt-4 sm:tw-row-start-3 sm:tw-row-end-4 sm:tw-col-span-6',
      }),
      figure: {
        container: joinObjectValues({
          // general: !(partner || magazine || event || webinar || award) && 'tw-hidden md:tw-block md:tw-mt-0 md:tw-row-start-2 md:tw-row-end-5 md:tw-col-span-3 3xl:tw-row-start-2 3xl:tw-col-span-4',
          // slug: !(partner || magazine) && (event || webinar || award) && 'tw-hidden md:tw-block md:tw-mt-0 md:tw-row-start-1 md:tw-row-end-5 md:tw-col-span-3 3xl:tw-row-start-2 3xl:tw-col-span-4',
          // partner: partner && 'tw-mt-4 tw-row-start-4 tw-row-end-5 tw-col-span-6 lg:tw-mt-0 lg:tw-row-start-2 lg:tw-col-span-6 xl:tw-col-span-5 3xl:tw-row-end-6 3xl:tw-col-span-3',
          general: !(partner || magazine || event || webinar || award) && 'tw-hidden lg:tw-block lg:tw-mt-0 lg:tw-row-start-2 lg:tw-row-end-5 lg:tw-col-span-2',
          slug: !(partner || magazine) && (event || webinar || award) && 'tw-hidden lg:tw-block lg:tw-mt-0 lg:tw-row-start-1 lg:tw-row-end-5 lg:tw-col-span-2',
          partner: partner && 'tw-mt-4 tw-row-start-4 tw-row-end-5 tw-col-span-6 lg:tw-mt-0 lg:tw-row-start-2 lg:tw-col-span-5 xl:tw-col-span-4 3xl:tw-row-end-6 3xl:tw-col-span-3',
          magazine: magazine && 'tw-hidden md:tw-block md:tw-row-start-1 md:tw-row-end-5 md:tw-col-span-2',
        }),
        link: 'tw-block tw-relative',
        div: 'tw-relative',
        icon: 'tw-font-icon tw-pointer-events-none tw-text-2xl tw-text-white tw-absolute tw-bottom-0 tw-right-0 tw-z-10 tw-px-2 tw-py-0 tw-bg-primary-main',
        img: 'tw-block tw-w-full tw-h-auto',
        video: {
          container: 'tw-relative tw-w-full',
          layer: 'tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-10',
          link: 'tw-absolute tw-block tw-w-full tw-h-full tw-z-10',
        },
      },
    },
    trending: {
      container: joinObjectValues({
        base: 'tw-grid tw-auto-cols-fr tw-gap-x-4 3xl:tw-gap-x-8',
        nonColumn: !column && {
          geoTargetSG: GeoSg && process.env.NEXT_PUBLIC_GEO_TARGET_SG_CONTENT,
          geoTargetNoJP: GeoNoJp && process.env.NEXT_PUBLIC_GEO_TARGET_NO_JP_CONTENT,
        },
        partner: partner && 'tw-p-2 tw-bg-secondary-light md:tw-p-4',
      }),
      slug: {
        // container: 'tw-row-start-1 tw-row-end-2 tw-col-span-6 3xl:tw-col-span-full',
        container: 'tw-row-start-2 tw-row-end-3 tw-col-span-6',
        link: 'tw-block tw-float-left tw-clear-both tw-font-headline tw-uppercase tw-font-bold tw-text-xs tw-text-primary-main hover:tw-underline',
      },
      header: {
        // container: 'tw-row-start-2 tw-row-end-3 tw-col-span-6 3xl:tw-col-span-4',
        container: 'tw-row-start-3 tw-row-end-4 tw-col-span-6',
        link: joinObjectValues({
          ...fontStyle.header,
          addition: 'tw-block tw-transition-all hover:tw-underline',
        }),
        div: joinObjectValues({
          ...fontStyle.header,
        }),
      },
      // asset: 'tw-mt-2 tw-row-start-3 tw-row-end-4 tw-col-span-6 3xl:tw-col-span-4',
      asset: 'tw-mt-2 tw-row-start-4 tw-row-end-5 tw-col-span-6',
      author: {
        container: joinObjectValues({
          ...fontStyle.asset,
          addition: 'tw-font-bold',
        }),
        partner: 'tw-font-normal',
        link: 'hover:tw-underline',
      },
      time: joinObjectValues({
        ...fontStyle.asset,
        addition: 'tw-text-gray-500',
      }),
      introText: joinObjectValues({
        ...fontStyle.introText,
        // addition: 'tw-hidden 3xl:tw-block',
        // general: !(partner || magazine) && '3xl:tw-row-start-2 3xl:tw-row-end-5 3xl:tw-col-span-4',
        // partner: partner && !customLink && '3xl:tw-mt-4 3xl:tw-row-start-4 3xl:tw-row-end-5 3xl:tw-col-span-4',
        // partner2: partner && customLink && '3xl:tw-mt-4 3xl:tw-row-start-3 3xl:tw-row-end-4 3xl:tw-col-span-4',
        addition: 'tw-hidden',
        // general: !(partner || magazine) && '3xl:tw-block 3xl:tw-mt-4 3xl:tw-row-start-4 3xl:tw-row-end-5 3xl:tw-col-span-6',
        partner: partner && !customLink && '3xl:tw-block 3xl:tw-mt-4 3xl:tw-row-start-4 3xl:tw-row-end-5 3xl:tw-col-span-6',
        partner2: partner && customLink && '3xl:tw-block 3xl:tw-mt-4 3xl:tw-row-start-3 3xl:tw-row-end-4 3xl:tw-col-span-6',
        magazine: magazine && 'sm:tw-block sm:tw-mt-4 sm:tw-row-start-3 sm:tw-row-end-4 sm:tw-col-span-6',
      }),
      figure: {
        container: 'tw-hidden lg:tw-block lg:tw-mb-3 lg:tw-row-start-1 lg:tw-row-end-2 lg:tw-col-span-6',
        link: 'tw-block tw-relative',
        div: 'tw-relative',
        icon: 'tw-font-icon tw-pointer-events-none tw-text-2xl tw-text-white tw-absolute tw-bottom-0 tw-right-0 tw-z-10 tw-px-2 tw-py-0 tw-bg-primary-main',
        img: 'tw-block tw-w-full tw-h-auto',
        video: {
          container: 'tw-relative tw-w-full',
          layer: 'tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-10',
          link: 'tw-absolute tw-block tw-w-full tw-h-full tw-z-10',
        },
      },
    },
    homeQuickSearch: {
      container: joinObjectValues({
        base: 'tw-grid tw-auto-cols-fr tw-gap-x-4 3xl:tw-gap-x-8',
        nonColumn: !column && {
          geoTargetSG: GeoSg && process.env.NEXT_PUBLIC_GEO_TARGET_SG_CONTENT,
          geoTargetNoJP: GeoNoJp && process.env.NEXT_PUBLIC_GEO_TARGET_NO_JP_CONTENT,
        },
        partner: partner && 'tw-p-2 tw-bg-secondary-light md:tw-p-4',
      }),
      slug: {
        // container: 'tw-row-start-1 tw-row-end-2 tw-col-span-6 3xl:tw-col-span-full',
        container: 'tw-row-start-1 tw-row-end-2 tw-col-span-6',
        link: 'tw-block tw-float-left tw-clear-both tw-font-headline tw-uppercase tw-font-bold tw-text-xs tw-text-primary-main hover:tw-underline',
      },
      header: {
        // container: 'tw-row-start-2 tw-row-end-3 tw-col-span-6 3xl:tw-col-span-4',
        container: 'tw-row-start-2 tw-row-end-3 tw-col-span-6',
        link: joinObjectValues({
          ...fontStyle.header,
          addition: 'tw-block tw-transition-all hover:tw-underline',
        }),
        div: joinObjectValues({
          ...fontStyle.header,
        }),
      },
      // asset: 'tw-mt-2 tw-row-start-3 tw-row-end-4 tw-col-span-6 3xl:tw-col-span-4',
      asset: 'tw-mt-2 tw-row-start-3 tw-row-end-4 tw-col-span-6',
      author: {
        container: joinObjectValues({
          ...fontStyle.asset,
          addition: 'tw-font-bold',
        }),
        partner: 'tw-font-normal',
        link: 'hover:tw-underline',
      },
      time: joinObjectValues({
        ...fontStyle.asset,
        addition: 'tw-text-gray-500',
      }),
      introText: joinObjectValues({
        ...fontStyle.introText,
        // addition: 'tw-hidden 3xl:tw-block',
        // general: !(partner || magazine) && '3xl:tw-row-start-2 3xl:tw-row-end-5 3xl:tw-col-span-4',
        // partner: partner && !customLink && '3xl:tw-mt-4 3xl:tw-row-start-4 3xl:tw-row-end-5 3xl:tw-col-span-4',
        // partner2: partner && customLink && '3xl:tw-mt-4 3xl:tw-row-start-3 3xl:tw-row-end-4 3xl:tw-col-span-4',
        addition: 'tw-hidden',
        // general: !(partner || magazine) && '3xl:tw-block 3xl:tw-mt-4 3xl:tw-row-start-4 3xl:tw-row-end-5 3xl:tw-col-span-6',
        partner: partner && !customLink && '3xl:tw-block 3xl:tw-mt-4 3xl:tw-row-start-4 3xl:tw-row-end-5 3xl:tw-col-span-6',
        partner2: partner && customLink && '3xl:tw-block 3xl:tw-mt-4 3xl:tw-row-start-3 3xl:tw-row-end-4 3xl:tw-col-span-6',
        magazine: magazine && 'sm:tw-block sm:tw-mt-4 sm:tw-row-start-3 sm:tw-row-end-4 sm:tw-col-span-6',
      }),
      figure: {
        container: joinObjectValues({
          // general: !(partner || magazine || event || webinar || award) && 'tw-hidden md:tw-block md:tw-mt-0 md:tw-row-start-2 md:tw-row-end-5 md:tw-col-span-3 3xl:tw-row-start-2 3xl:tw-col-span-4',
          // slug: !(partner || magazine) && (event || webinar || award) && 'tw-hidden md:tw-block md:tw-mt-0 md:tw-row-start-1 md:tw-row-end-5 md:tw-col-span-3 3xl:tw-row-start-2 3xl:tw-col-span-4',
          // partner: partner && 'tw-mt-4 tw-row-start-4 tw-row-end-5 tw-col-span-6 lg:tw-mt-0 lg:tw-row-start-2 lg:tw-col-span-6 xl:tw-col-span-5 3xl:tw-row-end-6 3xl:tw-col-span-3',
          general: !(partner || magazine || event || webinar || award) && 'tw-hidden lg:tw-block lg:tw-mt-0 lg:tw-row-start-2 lg:tw-row-end-5 lg:tw-col-span-2',
          slug: !(partner || magazine) && (event || webinar || award) && 'tw-hidden lg:tw-block lg:tw-mt-0 lg:tw-row-start-1 lg:tw-row-end-5 lg:tw-col-span-2',
          partner: partner && 'tw-mt-4 tw-row-start-4 tw-row-end-5 tw-col-span-6 lg:tw-mt-0 lg:tw-row-start-2 lg:tw-col-span-4 xl:tw-col-span-3 3xl:tw-row-end-6 3xl:tw-col-span-2',
          magazine: magazine && 'tw-hidden md:tw-block md:tw-row-start-1 md:tw-row-end-5 md:tw-col-span-2',
        }),
        link: 'tw-block tw-relative',
        div: 'tw-relative',
        icon: 'tw-font-icon tw-pointer-events-none tw-text-2xl tw-text-white tw-absolute tw-bottom-0 tw-right-0 tw-z-10 tw-px-2 tw-py-0 tw-bg-primary-main',
        img: 'tw-block tw-w-full tw-h-auto',
        video: {
          container: 'tw-relative tw-w-full',
          layer: 'tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-10',
          link: 'tw-absolute tw-block tw-w-full tw-h-full tw-z-10',
        },
      },
    },
  }

  const className = (center && classes.center) || (side && classes.side) || (figureSide && classes.figureSide) || (top && classes.top) || (top4 && classes.top4) || (top5 && classes.top5) || (editorsPicks && classes.editorsPicks) || (trending && classes.trending) || (homeQuickSearch && classes.homeQuickSearch);

  const ArticleComponent = () =>
    <article className={className.container} style={!column && (GeoSg || GeoNoJp) ? { display: 'none' } : undefined}>
      {/* Type label */}
      {render.slug &&
        <div className={className.slug.container}>
          <Link href={(webinar && linkHelper('webinar')) || (partner && getLink(partnerLinkData)) || (event && linkHelper('event')) || (award && getLink(awardLinkData))}>
            <a className={className.slug.link}>{webinar || partner || event ||  award}</a>
          </Link>
        </div>
      }

      {/* Headline, author & time */}
      {render.header &&
        <header className={className.header.container}>
        {render.link ?
          <div>
            <Link href={link}>
              <a className={className.header.link} target={(event && link) || (webinar && link) || magazine || customLink ? '_blank' : undefined} dangerouslySetInnerHTML={{ __html: heading }} style={{ hyphens: 'auto' }} />
            </Link>
          </div>
          : <div className={className.header.div} dangerouslySetInnerHTML={{ __html: heading }} style={{ hyphens: 'auto' }} />
        }
        </header>
      }

      {render.asset &&
        <div className={className.asset}>
          {/* Author - event, webinar, magazine, topic, customLink & magazine no display */}
          {render.author && <Author authors={authors} center={top || top4 || top5 || center} partner={partner} className={className.author} />}

          {/* View e-magazine - only for magazine */}
          {render.viewMagazine && <Link href={link}>
            <a target='_blank' className={className.magazine}>View e-magazine</a>
          </Link>
          }

          {/* article, video, gallery time */}
          {render.publishedAt && <time className={className.time}>{dateTime(publishedAt)}</time>}

          {/* event time */}
          {render.eventTime &&
            <time className={className.time}>
              {!eventEndAt ? <>{dateTime(eventStartAt)} &nbsp; {getHourMinute(eventStartAt)}</>
                : dateTime(eventStartAt) === dateTime(eventEndAt) ? <>{dateTime(eventStartAt)} &nbsp; {getHourMinute(eventStartAt)} - {getHourMinute(eventEndAt)}</> : <>{getDiffYearMonthDate(eventStartAt, eventEndAt)}</>}
            </time>
          }

          {/* webinar time */}
          {render.webinarTime && <>
            <time className={className.time}>
              {dateTime(webinarStartAt)} &nbsp; {getHourMinute(webinarStartAt)}
            </time>
            {webinarDuration && <time className={className.time}>Duration: {webinarDuration}</time>}
          </>
          }

          {/* share for mobile device
          <div>
            <MobileView renderWithFragment>
              {((link && !magazine) || (magazine && linkUrl)) && <Button title='share' onClick={handleMobileShare} btnType='icon' color='gray' size='xl' classes='tw-absolute tw-top-1/2 tw-right-0 tw-transform tw--translate-y-1/2' />}
            </MobileView>
          </div>

          share for desktop browser
          <div>
            <BrowserView renderWithFragment>
              {((link && !magazine) || (magazine && linkUrl)) && 
                <>
                  <Button title='share' onClick={handleBrowserShare} btnType='icon' color='gray' size='md' classes='tw-absolute tw-top-1/2 tw-right-0 tw-transform tw--translate-y-1/2' />
                  {browserShare && <SocialShare title={heading} url={`${process.env.NEXT_PUBLIC_API_URL}${link}`} handleClose={handleBrowserShareClose} box />}
                </>
              }
            </BrowserView>
          </div> */}
        </div>
      }

      {/* Intro text */}
      {render.introText && <div className={className.introText} dangerouslySetInnerHTML={{ __html: introText }} style={{ hyphens: 'auto' }} />}

      {/* Audio */}
      {render.audio && <div className={className.audio.container}>
        <Link href={link + '#podcast'}>
          <a id='homePodcastBtn' className={className.audio.button}><span className={className.audio.icon} /><span>Audio available</span></a>
        </Link>
      </div>
      }

      {/* Image */}
      {render.figure && <figure className={className.figure.container}>
        {video ?
          <div className={className.figure.video.container}>
            {(gallery || video) && <div className={className.figure.icon}>{type}</div>}
            <img src={imagePath || alternateImagePath ? imgSrcHelper(imagePath || alternateImagePath, { h: 194, w: 344, q: 100, c: 1, v: format(today, 'yyyymmdd') }) : placeholder} onMouseOver={handleMouseOver} width='344' height='194' alt={heading} className={className.figure.img} />

            {/* For All Video in Homepage */}
            {home ? <div className={className.figure.video.layer}>
              <Link href={link}>
                <a className={className.figure.video.link} />
              </Link>
              <Video {...videoRawData} type='preview' />
            </div>
              : showPreview ? <div className={className.figure.video.layer} onMouseOut={handleMouseOut}>
                <Link href={link}>
                  <a className={className.figure.video.link} />
                </Link>
                <Video {...videoRawData} type='preview' />
              </div>
                : ''
            }
          </div>
          :
          render.link ?
            <Link href={link}>
              <a className={className.figure.link}>
                {(gallery || video) && <div className={className.figure.icon}>{type}</div>}
                {magazine ? <img src={imagePath || alternateImagePath ? imgSrcHelper(imagePath || alternateImagePath, { h: 360, w: 266, q: 100, c: 1, v: format(today, 'yyyymmdd') }) : placeholderMagazine} width='266' height='360' alt={heading} className={className.figure.img} />
                  : top ? <img src={imagePath || alternateImagePath ? imgSrcHelper(imagePath || alternateImagePath, { h: 514, w: 912, q: 100, c: 1, v: format(today, 'yyyymmdd') }) : placeholder} width='912' height='514' alt={heading} className={className.figure.img} />
                    : !embed && bigger ? <img src={imagePath || alternateImagePath ? imgSrcHelper(imagePath || alternateImagePath, { h: 360, w: 640, q: 100, c: 1, v: format(today, 'yyyymmdd') }) : placeholder} width='640' height='360' alt={heading} className={className.figure.img} />
                      : !embed && !bigger ? <img src={imagePath || alternateImagePath ? imgSrcHelper(imagePath || alternateImagePath, { h: 194, w: 344, q: 100, c: 1, v: format(today, 'yyyymmdd') }) : placeholder} width='344' height='194' alt={heading} className={className.figure.img} />
                        : <img src={imagePath || alternateImagePath ? imgSrcHelper(imagePath || alternateImagePath, { h: 194, w: 344, q: 100, c: 1, v: format(today, 'yyyymmdd') }) : placeholder} width='344' height='194' alt={heading} className={className.figure.img} />
                }
              </a>
            </Link>
            : <div className={className.figure.div}>
              {(gallery || video) && <div className={className.figure.icon}>{type}</div>}
              {magazine ? <img src={imagePath || alternateImagePath ? imgSrcHelper(imagePath || alternateImagePath, { h: 360, w: 266, q: 100, c: 1, v: format(today, 'yyyymmdd') }) : placeholderMagazine} width='266' height='360' alt={heading} className={className.figure.img} />
                : top ? <img src={imagePath || alternateImagePath ? imgSrcHelper(imagePath || alternateImagePath, { h: 514, w: 912, q: 100, c: 1, v: format(today, 'yyyymmdd') }) : placeholder} width='912' height='514' alt={heading} className={className.figure.img} />
                  : !embed && bigger ? <img src={imagePath || alternateImagePath ? imgSrcHelper(imagePath || alternateImagePath, { h: 360, w: 640, q: 100, c: 1, v: format(today, 'yyyymmdd') }) : placeholder} width='640' height='360' alt={heading} className={className.figure.img} />
                    : !embed && !bigger ? <img src={imagePath || alternateImagePath ? imgSrcHelper(imagePath || alternateImagePath, { h: 194, w: 344, q: 100, c: 1, v: format(today, 'yyyymmdd') }) : placeholder} width='344' height='194' alt={heading} className={className.figure.img} />
                      : <img src={imagePath || alternateImagePath ? imgSrcHelper(imagePath || alternateImagePath, { h: 194, w: 344, q: 100, c: 1, v: format(today, 'yyyymmdd') }) : placeholder} width='344' height='194' alt={heading} className={className.figure.img} />
              }
            </div>
        }
      </figure>
      }
    </article >

  const SimpleArticleComponent = () =>
    <article className={`${!column && GeoSg ? `${process.env.NEXT_PUBLIC_GEO_TARGET_SG_CONTENT} ` : ''}${!column && GeoNoJp ? `${process.env.NEXT_PUBLIC_GEO_TARGET_NO_JP_CONTENT} ` : ''}tw-grid tw-grid-cols-1 tw-gap-0 tw-max-w-middle`} style={!column && (GeoSg || GeoNoJp) ? { display: 'none' } : undefined}>
      {/* Headline, */}
      <header>
        {(link && !magazine) || (magazine && linkUrl) ?
          <div><a href={link} rel="noreferrer" className={`tw-block tw-transition-all tw-font-headline tw-font-extrabold tw-tracking-tight tw-text-base tw-leading-tight tw-text-black | hover:tw-underline | sm:tw-text-xl | lg:tw-text-2xl`} target={(event && link) || (webinar && link) || magazine || customLink ? '_blank' : undefined} dangerouslySetInnerHTML={{ __html: heading }} style={{ hyphens: 'auto' }} /></div>
          : <div className={`tw-font-headline tw-font-extrabold tw-tracking-tight tw-text-base tw-leading-tight tw-text-black | sm:tw-text-3xl | lg:tw-text-4xl`} dangerouslySetInnerHTML={{ __html: heading }} style={{ hyphens: 'auto' }} />
        }
      </header>

      {/* Intro text */}
      {introText && <div className={`tw-font-sans tw-text-xs tw-text-gray-500 | sm:tw-text-base | lg:tw-text-lg lg:tw-leading-normal`} dangerouslySetInnerHTML={{ __html: introText }} style={{ hyphens: 'auto' }} />}
    </article>

  const ColumnWrapper = ({ children, ...props }) =>
    column && (GeoSg || GeoNoJp) ? <Column className={`${GeoSg ? process.env.NEXT_PUBLIC_GEO_TARGET_SG_CONTENT : ''}${GeoNoJp ? process.env.NEXT_PUBLIC_GEO_TARGET_NO_JP_CONTENT : ''}`} {...columnProps} style={{ display: 'none' }}>{children}</Column>
      : column ? <Column {...columnProps}>{children}</Column>
        : children

  return <ColumnWrapper>
    {simple ? <SimpleArticleComponent /> : <ArticleComponent />}
  </ColumnWrapper>
};

export default ArticleSummary;

ArticleSummary.propTypes = {
  /**
  * Identify article and use for hyperlink
  */
  id: PropTypes.number,
  /**
  * slug of the article for hyperlink
  */
  slug: PropTypes.string,
  /**
  * Type of the article ('article', 'gallery', 'video', 'event', 'webinar', 'link', 'topic', 'magazine')
  */
  itemType: PropTypes.oneOf(['article', 'gallery', 'video', 'event', 'webinar', 'link', 'topic', 'magazine']),
  /**
  * Heading of the article summary
  */
  heading: PropTypes.string.isRequired,
  /**
  * Custom link of the article summary
  */
  linkUrl: PropTypes.string,
  /**
  * Authors information array
  */
  authors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
    display: PropTypes.string.isRequired,
  })),
  /**
  * Publich time of the article summary
  */
  publishedAt: PropTypes.number,
  /**
  * Introduction of the article summary
  */
  introText: PropTypes.string,
  /**
  * Image path of the article summary
  */
  imagePath: PropTypes.string,
  /**
  * Alternate image path of the article summary
  */
  alternateImagePath: PropTypes.string,
  /**
  * Variant format of the article for layout, 'center' is layout for centre, 'side' is layout for left or right side, 'figureSide' is layout for left or right side with big figure, 'top' is home page top section first article, 'top4' is home page top section 2-4 articles, 'top5' is home page top section 5 articles for partner content
  */
  variant: PropTypes.oneOf(['center', 'side', 'figureSide', 'top', 'top4', 'top5', 'editorsPicks', 'trending', 'homeQuickSearch']),
  /**
  * Category names of the article summary
  */
  categoryNames: PropTypes.arrayOf(PropTypes.string.isRequired),
  /**
  * Tags of the article summary
  */
  tags: PropTypes.arrayOf(PropTypes.string),
  /**
  * Event start date of event
  */
  eventStartAt: PropTypes.number,
  /**
  * Event end time of event
  */
  eventEndAt: PropTypes.number,
  /**
  * Event Url
  */
  eventUrl: PropTypes.string,
  /**
  * Webcast start date of webinar
  */
  webinarStartAt: PropTypes.number,
  /**
  * Webcast duration
  */
  webinarDuration: PropTypes.string,
  /**
  * Webcast Url
  */
  webinarUrl: PropTypes.string,
  /**
  * Embed to single article
  */
  embed: PropTypes.bool,
  /**
  * Video data
  */
  video: PropTypes.object,
  /**
  * Simple version for quick search result
  */
  simple: PropTypes.bool,
  /**
  * Home page special option
  */
  home: PropTypes.bool,
  /**
  * Include Column component in rendering
  */
  column: PropTypes.bool,
  /**
  * Column props
  */
  columnProps: PropTypes.object,
  /**
  * Apply Geo Target in rendering
  */
  geoTarget: PropTypes.bool,
  /**
  * Is Most Read article
  */
  mostRead: PropTypes.bool,
};

ArticleSummary.defaultProps = {
  variant: 'center',
  itemType: 'article',
  simple: false,
  home: false,
  column: true,
  geoTarget: true,
}
