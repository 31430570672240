import React from 'react';
import PropTypes from 'prop-types';
import { ArticleSummary } from '@app/components';

/**
* Top 2 to 4 Story for Home
*/
export const Top4 = ({ items, ...props }) =>
  items.map((item, i) => {
    const GeoSg = item?.tags && item?.tags.some(tags => tags.toLowerCase() === process.env.NEXT_PUBLIC_GEO_TARGET_SG_TAG) && true;
    const GeoNoJp = item?.tags && item?.tags.some(tags => tags.toLowerCase() === process.env.NEXT_PUBLIC_GEO_TARGET_NO_JP_TAG) && true;

    return <div key={i} className={`${GeoSg ? process.env.NEXT_PUBLIC_GEO_TARGET_SG_CONTENT + ' ' : ''}${GeoNoJp ? process.env.NEXT_PUBLIC_GEO_TARGET_NO_JP_CONTENT + ' ' : ''}tw-py-4 tw-border-t tw-border-border | md:tw-py-8 | lg:first:tw-border-0 lg:first:tw-pt-0 lg:last:tw-pb-0`} style={(GeoSg || GeoNoJp) ? { display: 'none' } : undefined}>
      <ArticleSummary variant='top4' {...items[i]} column={false} geoTarget={false} />
    </div>
  })

export default Top4;

Top4.propTypes = {
  /**
  * Top 2 to 4 items for content
  */
  items: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};