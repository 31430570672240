export const prettifyJson = (value) => {
  return JSON.stringify(value, null, 2);
}

export const cleanObject = (obj) =>{
  for (var propName in obj) {
    //console.log(typeof(obj[propName]))
    if(typeof(obj[propName])==='object'){
      if (obj[propName]?.length===0 || obj[propName] === undefined) {
        delete obj[propName];
      }
    }else if(typeof(obj[propName])==='string'){
      if (obj[propName]==='' || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
  }
  return obj
}

export const extractFilter = (filter) =>{
  //console.log(filter)
  let result = {}, authorIds, categoryIds, itemTypes, countryIds, year, sort, keyword;
  authorIds = filter?.authorIds?.filter((obj) => (obj.selected === true)).map((obj) => obj.key);
  categoryIds = filter?.categoryIds?.filter((obj) => (obj.selected === true)).map((obj) => obj.key);
  itemTypes = filter?.itemTypes?.filter((obj) => (obj.selected === true)).map((obj) => obj.key);
  countryIds = filter?.countryIds?.filter((obj) => (obj.selected === true)).map((obj) => obj.key);
  year = filter?.year?.filter((obj) => (obj.selected === true)).map((obj) => obj.key);
  sort = filter?.sort?.filter((obj) => (obj.selected === true))[0]?.text;
  keyword =  filter?.keyword?.filter((obj) => (obj.selected === true))[0]?.text;

  //console.log(authorIds, categoryIds, itemTypes, countryIds, year, sort, keyword)

  if(authorIds?.length>0)
    result['authorIds'] = authorIds;
  if(categoryIds?.length>0)
    result['categoryIds'] = categoryIds;
  if(itemTypes?.length>0)
    result['itemTypes'] = itemTypes;
  if(countryIds?.length>0)
    result['countryIds'] = countryIds;
  if(year?.length>0)
    result['year'] = year;
  if(sort)
    result['sort'] = sort;
  if(keyword)
    result['keyword'] = keyword;

  //console.log(result)
  /* result = {
    authorIds: filter?.authorIds?.filter((obj) => (obj.selected === true)).map((obj) => obj.key) || '',
    categoryIds: filter?.categoryIds?.filter((obj) => (obj.selected === true)).map((obj) => obj.key) || '',
    itemTypes: filter?.itemTypes?.filter((obj) => (obj.selected === true)).map((obj) => obj.key) || '',
    countryIds: filter?.countryIds?.filter((obj) => (obj.selected === true)).map((obj) => obj.key) || '',
    year: filter?.year?.filter((obj) => (obj.selected === true)).map((obj) => obj.key) || '',
    sort: filter?.sort?.filter((obj) => (obj.selected === true))[0]?.text || '',
    keyword: filter?.keyword?.filter((obj) => (obj.selected === true))[0]?.text || ''
  } */
  return result;
}

export const deepEqual = (object1, object2)=> {
  const keys1 = Object.keys(object1);
  const keys2 = Object.keys(object2);
  if (keys1.length !== keys2.length) {
    return false;
  }
  for (const key of keys1) {
    const val1 = object1[key];
    const val2 = object2[key];
    const areObjects = isObject(val1) && isObject(val2);
    if (
      areObjects && !deepEqual(val1, val2) ||
      !areObjects && val1 !== val2
    ) {
      return false;
    }
  }
  return true;
}
function isObject(object) {
  return object != null && typeof object === 'object';
}