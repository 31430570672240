import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import queryString from "query-string";
import { linkHelper, isEmpty } from '@app/utils';

/**
* Single Article Footer UI component
*/
export const ArticleFooter = props =>
  <footer className='tw-flex tw-flex-col tw-flex-wrap tw-gap-4 tw-max-w-middle'>
    {!isEmpty(props.categories) && <div className='tw-flex tw-flex-row tw-flex-wrap tw-gap-x-4 tw-font-headline tw-text-sm tw-leading-normal tw-uppercase tw-font-bold | sm:tw-gap-x-6 sm:tw-text-base | lg:tw-gap-x-8 lg:tw-text-lg'>
      {props.categories.map((category, i) =>
        <Link key={`c_${i}`} href={linkHelper('category', category.slug, category.id)}>
          <a className='tw-text-primary-main | hover:tw-underline'>{category.display}</a>
        </Link>
        // <a key={`c_${i}`} href={linkHelper('category', category.slug, category.id)} className='tw-text-primary-main | hover:tw-underline'>{category.display}</a>
      )}
    </div>}
    {!isEmpty(props.tags) && <div className='tw-flex tw-flex-row tw-flex-wrap tw-gap-x-4 tw-font-body tw-text-xs tw-leading-normal tw-font-bold | sm:tw-gap-x-6 sm:tw-text-sm | lg:tw-gap-x-8 lg:tw-text-base'>
      {props.tags.map((tag, i) =>
        <Link key={`t_${i}`} href={linkHelper('tag', tag)}>
          <a className='tw-text-black | hover:tw-underline'>#{tag}</a>
        </Link>
        // <a  key={`t_${i}`} href={linkHelper('tag', tag)} className='tw-text-black | hover:tw-underline'>#{tag}</a>
      )}
    </div>}
  </footer>

export default ArticleFooter;

ArticleFooter.propTypes = {
  /**
  * Categories array informations
  */
  categories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
    display: PropTypes.string.isRequired,
  })),
  /**
  * Tags array informations
  */
  tags: PropTypes.arrayOf(PropTypes.string.isRequired),
};