import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import Image from 'next/image';
import { format, formatRelative } from 'date-fns';
import { dateTime, imgSrcHelper, linkHelper } from '@app/utils';

// Author helper function
const Author = ({ authors, ...props }) =>
  <div className='tw-font-sans tw-font-bold tw-text-sm tw-leading-tight | sm:tw-text-base'>
    {authors.map((author, i) =>
      <Fragment key={i}>
        {i > 0 && ', '}
        <Link href={linkHelper('author', author.slug, author.id)}>
          <a className={'tw-text-black | hover:tw-underline'}>{author.display}</a>
        </Link>
        {/* <a href={linkHelper('author', author.slug, author.id)} className={'tw-text-black | hover:tw-underline'}>{author.display}</a> */}
      </Fragment>
    )}
  </div>

// Relative time helper function
const RelativeTime = date => {
  let date48 = ((formatRelative(date, new Date(Date.now()))).indexOf('today') > -1 && 'Today') || ((formatRelative(date, new Date(Date.now()))).indexOf('yesterday') > -1 && 'Yesterday');
  let dateL7d = !date48 && (formatRelative(date, new Date(Date.now()))).indexOf('last') > -1 && formatRelative(date, new Date(Date.now()));
  dateL7d = dateL7d && dateL7d.charAt(0).toUpperCase() + dateL7d.split(' ')[0].slice(1) + ' ' + dateL7d.split(' ')[1];
  return (date48 || dateL7d);
}

/**
* Article Header UI component
*/
export const ArticleHeader = props => {
  //console.log(props)
  const { categoryNames, itemType, publishedAt, authors, heading, introText, imagePath, alternateImagePath, body } = props;
  const award = categoryNames && categoryNames.some(categoryName => categoryName.toLowerCase() === 'awards') && 'award';
  const partner = categoryNames && categoryNames.some(categoryName => categoryName.toLowerCase() === 'partner content') && 'partner content';
  const topic = itemType === 'topic';
  const audio = body?.indexOf('pb-iframe-player') > -1;
  const today = new Date();
  //console.log(imagePath)
  return (
    // <header className={`tw-flex tw-flex-col tw-flex-wrap tw-gap-4 tw-max-w-middle ${topic ? ' tw-pl-2 tw-border-l-8 tw-border-primary-main' : ''} | sm:tw-gap-8 ${topic ? '| md:tw-pl-4 md:tw-border-l-16 ' : ''}| 2xl:tw-gap-12`}>
    <header className={`tw-flex tw-flex-col tw-flex-wrap tw-gap-4 tw-max-w-middle Z| sm:tw-gap-8 | 2xl:tw-gap-12`}>
      {/* Type label */}
      {(partner || award) &&
        <div className='tw--mb-4 | sm:tw--mb-8 | 2xl:tw--mb-12'>
          <span className={`tw-inline-block tw-font-headline tw-uppercase tw-font-bold tw-text-sm ${partner ? 'tw-text-white tw-bg-primary-main tw-py-2 tw-px-4' : 'tw-text-primary-main'} | lg:tw-text-base`}>
            {partner || award}
          </span>
        </div>
      }
      {/* Topic image */}
      {topic && imagePath && <img src={imgSrcHelper(imagePath || alternateImagePath, { h: 490, w: 1120, q: 100, c: 1, v: format(today, 'yyyymmdd') })} width='1120' height='490' alt={heading} className='tw-block tw-w-full tw-h-auto | sm:tw--mb-4 | 2xl:tw--mb-8' />}
      {/* console.log(imagePath,topic,"image") */}
      {/* Headline */}
      <h1 className='tw-block tw-font-headline tw-font-bold tw-tracking-tighter tw-text-3xl tw-leading-tight tw-text-black | sm:tw-text-4xl sm:tw-leading-tight | lg:tw-text-5xl lg:tw-leading-tight' dangerouslySetInnerHTML={{ __html: heading }} />

      {/* Author & time, not show in event & webinar type */}
      {!topic &&
        <div>
          {/* Author - event, webinar, magazine, topic, customLink & magazine no display */}
          {authors && <Author authors={authors} />}

          {/* Time */}
          {publishedAt && <time className='tw-block tw-font-sans tw-text-sm tw-text-gray-500 | sm:tw-text-base'>{dateTime(publishedAt)}</time>}
        </div>}

      {/* Intro text */}
      {introText &&
        <div>
          {/* Audio */}
          {audio && <a id='contentPodcastBtn' href='#podcast' className='tw-inline-flex tw-items-center tw-font-headline tw-text-base tw-font-semibold tw-uppercase tw-text-[#800000] tw-mb-2 tw-transition'><span className='tw-font-icon icon-ai_audio tw-text-xl tw-mr-1' /><span>Audio available</span></a>}

          {/* Intro text */}
          <div className={`tw-font-sans tw-text-lg tw-text-gray-500 | sm:tw-text-xl ${topic ? 'sm:tw--mt-4 ' : ''}| lg:tw-text-2xl lg:tw-leading-normal ${topic ? '| 2xl:tw--mt-8' : ''}`} dangerouslySetInnerHTML={{ __html: introText }} />
        </div>}
    </header>
  );
};

export default ArticleHeader;

ArticleHeader.propTypes = {
  /**
  * Heading of the article summary
  */
  heading: PropTypes.string.isRequired,
  /**
  * Custom link of the article summary
  */
  linkUrl: PropTypes.string,
  /**
  * Authors information array
  */
  authors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    slug: PropTypes.string.isRequired,
    display: PropTypes.string.isRequired,
  })),
  /**
  * Publich time of the article summary
  */
  publishedAt: PropTypes.number,
  /**
  * Introduction of the article summary
  */
  introText: PropTypes.string,
  /**
  * Image path of the article summary
  */
  imagePath: PropTypes.string,
  /**
  * Alternate image path of the article summary
  */
  alternateImagePath: PropTypes.string,
  /**
  * Type of the article ('article', 'gallery', 'video', 'event', 'webinar', 'link', 'topic', 'magazine')
  */
  itemType: PropTypes.oneOf(['article', 'gallery', 'video', 'event', 'webinar', 'link', 'topic', 'magazine']),
  /**
  * Category names of the article summary
  */
  categoryNames: PropTypes.array,
  /** 
   * Content of the article body
  */
  body: PropTypes.node,
};

ArticleHeader.defaultProps = {
  itemType: 'article'
}
