import React from 'react';
import PropTypes from 'prop-types';
import { ArticleSummary} from '@app/components';

/**
* Top Story component for Home
*/
export const TopStory = ({ item, ...props }) => {
  const GeoSg = item?.tags && item?.tags.some(tags => tags.toLowerCase() === process.env.NEXT_PUBLIC_GEO_TARGET_SG_TAG) && true;
  const GeoNoJp = item?.tags && item?.tags.some(tags => tags.toLowerCase() === process.env.NEXT_PUBLIC_GEO_TARGET_NO_JP_TAG) && true;

  return <div className={`${GeoSg ? process.env.NEXT_PUBLIC_GEO_TARGET_SG_CONTENT + ' ' : ''}${GeoNoJp ? process.env.NEXT_PUBLIC_GEO_TARGET_NO_JP_CONTENT + ' ' : ''}lg:tw-w-7/12`} style={(GeoSg || GeoNoJp) ? { display: 'none' } : undefined}>
    <ArticleSummary variant='top' {...item} column={false} geoTarget={false} />
  </div>
}

export default TopStory;

TopStory.propTypes = {
  /**
  * Content objects for display
  */
  item: PropTypes.object.isRequired,
};