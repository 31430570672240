import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { randHash } from '@app/utils';
import { SliderButton } from '@typeform/embed-react';

const feedbackFormId = 'WH6zsN3e';

export const AuthorFeedback = ({authorId, text}) => {
  const router = useRouter();
  const contentItem = encodeURIComponent(router?.asPath);

  return <SliderButton id={feedbackFormId} 
      className='tw-text-primary-main hover:tw-underline'
      as="button"
      position="left"
      width={550}
      autoClose={3000}
      hidden={{ 
        ref_id: randHash(32),
        content_item: contentItem
        }}
    >
    Send feedback to <span className='tw-capitalize'>{text}</span>
    </SliderButton>
}

export default AuthorFeedback;

AuthorFeedback.propTypes = {
  /**
  * Author Id
  */
  authorId: PropTypes.string.isRequired,
  /**
  * Author name
  */
  text: PropTypes.string.isRequired,
};

AuthorFeedback.defaultProps = {}