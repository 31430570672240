import { NextSeo } from 'next-seo'

export const ListingSeo = ({ listing, url, description }) => {
  const title = `${listing} | AsianInvestor`
  return (
    <NextSeo
      title={title}
      description={description}
      canonical={url}
      twitter={{
        cardType: "summary_large_image",
        handle: "@AsianInvestor",
        site: "@AsianInvestor",
      }}
      openGraph={{
        title: title,
        description: description,
        url: url,
        type: 'website',
        site_name: 'AsianInvestor',
        locale: "en_US", // FIXME handle other languages
      }}
    />
  )
}