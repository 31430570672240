import React from 'react';
import PropTypes from 'prop-types';
import { useRouter } from 'next/router';
import { Button } from '@app/components';
import { randHash } from '@app/utils';
import { SliderButton } from '@typeform/embed-react';

const feedbackFormId = 'e3nnQeq4';

export const Feedback = ({ session }) => {
  const router = useRouter();
  const contentItem = encodeURIComponent(router?.asPath);

  return <div className='tw-flex tw-flex-col tw-flex-nowrap tw-gap-2'>
    <div className='tw-font-headline tw-font-bold tw-text-xl tw-tracking-tight tw-text-center sm:tw-text-left'>Was this article helpful?</div>
    <SliderButton id={feedbackFormId} 
      className='tw-text-center | sm:tw-text-left'
      position="left"
      width={550}
      autoClose={3000}
      hidden={{ 
        ref_id: randHash(32),
        person_id: session?.token.sub || 'anonymous',
        content_item: contentItem
        }}
    >
    <Button title='Feedback' btnType='box' size='xs' noResponsive></Button>
    </SliderButton>
  </div>
}

export default Feedback;

Feedback.propTypes = {
  /**
   * User Session
   */
  session: PropTypes.object
};

Feedback.defaultProps = {}