import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '@app/components';
import DefaultProps from '@app/header.json';
import DefaultPropsUAT from '@app/header-uat.json';

const DefaultPropsContent = process.env.NEXT_PUBLIC_APP_ENVIRONMENT === 'Production' ? DefaultProps : DefaultPropsUAT;

export const SocialShare = props => {
  const { logo } = DefaultPropsContent;

  return props.short ? 
    <div className='tw-flex tw-flex-row tw-flex-nowrap tw-gap-2'>
      <div className='tw-font-bold tw-tracking-tight tw-whitespace-nowrap'>Share to: </div>
      <div className='tw-flex tw-flex-row tw-flex-wrap tw-gap-4'>
        <div><Button btnType='icon' size='md' title='facebook' url={'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(props.url)} target='ShareOnFacebook' noResponsive /></div>
        <div><Button btnType='icon' size='md' title='twitter' url={'https://twitter.com/intent/tweet?text=' + encodeURIComponent(props.title).replace(/%20/g, "+") + "&url=" + encodeURIComponent(props.url)} target='ShareOnTwitter' noResponsive /></div>
        <div><Button btnType='icon' size='md' title='linkedin' url={'https://www.linkedin.com/cws/share?url=' + props.url} target='ShareOnLinkedIn' noResponsive /></div>
      </div>
    </div>
    : props.box ? 
      <>
        <div className='tw-fixed tw-top-1/2 tw-left-1/2 tw-transform tw--translate-x-1/2 tw--translate-y-1/2 tw-z-20 tw-w-[36rem] tw-max-w-[90%] tw-bg-white tw-py-8 tw-px-8 | sm:tw-py-12 | lg:tw-py-14'>
          <Button title='close' btnType='icon' size='lg' color='gray' onClick={props.handleClose} classes='tw-absolute tw-top-2 tw-right-2 | sm:tw-top-3 sm:tw-right-3 | lg:tw-top-3 lg:tw-right-3' />

          <div className='tw-flex tw-flex-col tw-flex-nowrap tw-gap-4 | sm:tw-gap-6 | lg:tw-gap-8'>
            <div className='tw-mx-auto'><img alt={props.alt} src={logo.logoW} className='tw-block tw-w-auto tw-h-5 | sm:tw-h-7' /></div>
            <div className='tw-font-headline tw-font-bold tw-text-2xl tw-tracking-tight tw-text-center | sm:tw-text-3xl'>Share our publication<br />on social media</div>
            <div className='tw-flex tw-flex-row tw-flex-wrap tw-gap-8 tw-justify-center'>
              <div><Button btnType='icon' size='xl' title='facebook' url={'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(props.url)} target='ShareOnFacebook' noResponsive /></div>
              <div><Button btnType='icon' size='xl' title='twitter' url={'https://twitter.com/intent/tweet?text=' + encodeURIComponent(props.title).replace(/%20/g, "+") + "&url=" + encodeURIComponent(props.url)} target='ShareOnTwitter' noResponsive /></div>
              <div><Button btnType='icon' size='xl' title='linkedin' url={'https://www.linkedin.com/cws/share?url=' + props.url} target='ShareOnLinkedIn' noResponsive /></div>
            </div>
          </div>
          <style>{"\
            body {\
              height: 100vh;\
              overflow: hidden;\
            }\
          "}</style>
        </div>
        <div className='tw-fixed tw-top-0 tw-left-0 tw-z-10 tw-w-screen tw-h-screen tw-bg-black tw-opacity-10' onClick={props.handleClose} />
      </>
    : <div className='tw-flex tw-flex-col tw-flex-nowrap tw-gap-2'>
        <div className='tw-font-headline tw-font-bold tw-text-xl tw-tracking-tight tw-text-center sm:tw-text-left'>Share our publication on social media</div>
        <div className='tw-flex tw-flex-row tw-flex-wrap tw-gap-8 tw-justify-center sm:tw-justify-start'>
          <div><Button btnType='icon' size='lg' title='facebook' url={'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(props.url)} target='ShareOnFacebook' noResponsive /></div>
          <div><Button btnType='icon' size='lg' title='twitter' url={'https://twitter.com/intent/tweet?text=' + encodeURIComponent(props.title).replace(/%20/g, "+") + "&url=" + encodeURIComponent(props.url)} target='ShareOnTwitter' noResponsive /></div>
          <div><Button btnType='icon' size='lg' title='linkedin' url={'https://www.linkedin.com/cws/share?url=' + props.url} target='ShareOnLinkedIn' noResponsive /></div>
        </div>
      </div>
}

export default SocialShare;

SocialShare.propTypes = {
  /**
  * Title of the share link
  */
  title: PropTypes.string,
  /**
  * Url for share link
  */
  url: PropTypes.string,
  /**
  * Display format
  */
  short: PropTypes.bool,
  /**
  * Display format
  */
  box: PropTypes.bool,
  /**
  * Handle Close function
  */
  handleClose: PropTypes.func,
};

SocialShare.defaultProps = {
  short: false,
  box: false,
}