import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
// import Image from 'next/image';
import { format } from 'date-fns';
import { imgSrcHelper } from '@app/utils';
import { Button, SocialShare } from '@app/components';
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';

/**
* Popup Content UI component
*/
const Popup = props => {
  const [beforeChangeId, setBeforeChangeId] = useState(props.currentId);
  const [afterChangeId, setAfterChangeId] = useState(props.currentId);
  const sliderRef = useRef();
  const PrevArrow = props => <Button classes='tw-absolute tw-top-0 tw-z-10 tw-top-1/2 tw-transform tw--translate-y-1/2 tw-font-icon tw-text-center tw-cursor-pointer tw-text-white tw-bg-primary-main | hover:tw-bg-primary-dark' btnType='boxIcon' size='md' color='primary' title='navigate_before' onClick={props.onClick} />;
  const NextArrow = props => <Button classes='tw-absolute tw-top-0 tw-right-0 tw-z-10 tw-top-1/2 tw-transform tw--translate-y-1/2 tw-font-icon tw-text-center tw-cursor-pointer tw-text-white tw-bg-primary-main | hover:tw-bg-primary-dark' btnType='boxIcon' size='md' color='primary' title='navigate_next' onClick={props.onClick} />;

  useEffect(() => {
    sliderRef.current.slickGoTo(afterChangeId);
  });

  const setting = {
    dots: false,
    infinite: true,
    speed: 500,
    lazyload: 'progressively',
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    beforeChange: (current, next) => setBeforeChangeId(next),
    afterChange: current => setAfterChangeId(current),
    ...props.sliderSettings
  }

  return <div className='tw-fixed tw-top-0 tw-left-0 tw-bottom-0 tw-right-0 tw-z-10 tw-flex tw-items-center tw-justify-center tw-min-w-screen tw-min-h-screen tw-bg-black tw-bg-opacity-75'>
    <div className='tw-absolute tw-z-10 tw-grid tw-grid-cols-1 tw-p-4 | md:tw-max-w-long | lg:tw-grid-cols-12'>
      <Slider ref={sliderRef} className='lg:tw-col-span-9 tw-bg-black' {...setting}>
        {props.images.map(image =>
          <div key={image.id} className='tw-relative tw-overflow-hidden tw--mb-1.5'>
            {/* <Image src={imgSrcHelper(image.slug, { h: 459, w: 816, q: 100, c: 1, v: format(new Date(), 'yyyymmdd') })} layout='intrinsic' width='816' height='459' alt={image.display || undefined} className='tw-block tw-w-full tw-h-auto' /> */}
            <img src={imgSrcHelper(image.slug, { h: 459, w: 816, q: 100, c: 1, v: format(new Date(), 'yyyymmdd') })} width='816' height='459' alt={image.display || undefined} className='tw-block tw-w-full tw-h-auto' />
          </div>
        )}
      </Slider>
      <div className='lg:tw-col-span-3 tw-flex tw-flex-col tw-gap-2 tw-relative tw-p-4 tw-bg-white'>
        <SocialShare title={props.images[beforeChangeId].display} url={window.location.href} short />
        <div className='tw-relative tw--mr-4 tw-h-24 | lg:tw-h-full'>
          <div className='tw-absolute tw-font-body tw-text-base tw-text-third-main tw-overflow-auto tw-pr-4 tw-h-full'>{props.images[beforeChangeId].display}
          </div>
        </div>
      </div>
    </div>
    <div className='tw-w-full tw-h-screen' onClick={props.onClose(beforeChangeId)} />
    <style>{"\
      body {\
        overflow: hidden;\
      }\
    "}</style>
  </div>
}

/**
* Gallery Popup UI component
*/
export const GalleryPopup = props => {
  return props.currentId > -1 ?
    <Popup {...props} />
    : null;
}

export default GalleryPopup;

GalleryPopup.propTypes = {
  /**
  * Current image id from gallery
  */
  currentId: PropTypes.number.isRequired,
  /**
  * Gallery images array
  */
  images: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    slug: PropTypes.string,
    display: PropTypes.string
  })).isRequired,
  /**
  * Popup close function
  */
  onClose: PropTypes.func.isRequired,
};

GalleryPopup.defaultProps = {
  itemType: 'article'
};