import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';

/**
* Text link UI component for general text link with hover effect
*/
export const TextLink = ({ href, ...props }) =>
  <Link href={href}>
    <a className='tw-text-primary-main hover:tw-underline' {...props} />
  </Link>
  // <a href={href} className='tw-text-primary-main hover:tw-underline' {...props} />

export default TextLink;

TextLink.propTypes = {
  /**
  * Children content
  */
  children: PropTypes.node.isRequired,
  /**
  * Location of the link
  */
  href: PropTypes.string.isRequired,
};