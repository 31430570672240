import { Provider, getSession } from 'next-auth/client';
import { AppContext, VirtualUrlContext } from '@app/utils';
import { Layout as DefaultLayout, Seo, CookieConsent } from '@app/components';
import 'tailwindcss/tailwind.css';
import '../styles/icomoon/style.css';
import '../styles/ArticleImage.css';
import '../styles/styles.css';
import { useEffect, useState } from 'react';
import { DefaultSeo } from 'next-seo';
import SEO from '../next-seo.config'
import { useRouter } from 'next/router';
import { signIn } from 'next-auth/client';
import Script from 'next/script';
import { getAspxRedirect } from '@app/utils';

const NoLayout = props => props.children

const layouts = {
  default: DefaultLayout,
  none: NoLayout
}


function LayoutWrapper(props) {
  // to get the text value of the assigned layout of each component
  const Layout = layouts[props.children.type.layout];
  // if we have a registered layout render children with said layout
  if (Layout != null) {
    return <Layout {...props}>{props.children}</Layout>;
  }
  // if not render children with fragment
  return <DefaultLayout {...props}>{props.children}</DefaultLayout>;
}

const MyApp = ({ Component, pageProps }) => {
  const [loading, setLoading] = useState(false); // pass to provider
  const [aId, setAId] = useState(); // pass to provider
  const router = useRouter();
  const [isShorthand, setIsShorthand] = useState(false);
  const [virtualUrl, setVirtualUrl] = useState(null) // For use in GA pageview events, hides info from appearing in browser url bar

  const handleTracking = () => {
    
    const { pathname, search, hash } = window.location
    const isContent = pathname.indexOf('/article/') === 0 || pathname.indexOf('/gallery/') === 0 || pathname.indexOf('/video/') === 0

    // GA4
    if (typeof window?.gtag === 'function') {
      // If is content page, don't send the pageview event (it will be done inside content page useEffect)
      if (!isContent) {
        window.gtag('event', 'page_view', {
          send_to: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS,
          page_location: window.location.href,
          // user_id: pageProps.session?.token?.sub || '0'
        })
      }
    }

    // Divolte
    // Add lock reason to page view event if it is content page (done in [id].js)
    if (!isContent) {
      if(pageProps?.session){
        const payload = {
          'user_id': pageProps.session?.token?.sub || '0'
        }
        window?.divolte?.signal('pageView', payload)
      }
    }
  }

  useEffect(async () => {

    /*     const ses = await getSession();
        if(!ses){
          signIn(process.env.NEXT_PUBLIC_AUTHSERVER_CLIENTID);
        } */
        

    const handleRouteChangeComplete = (url, { shallow }) => {
      if (window?.geoTarget) {
        window.geoTarget(process.env.NEXT_PUBLIC_GEO_TARGET_SG_ID, process.env.NEXT_PUBLIC_GEO_TARGET_SG_REFERRERID);
        window.geoTarget(process.env.NEXT_PUBLIC_GEO_TARGET_NO_JP_ID, process.env.NEXT_PUBLIC_GEO_TARGET_NO_JP_REFERRERID);
      }
      
      if (isShorthand === false) {
        if (window?.shorthand) {
          setIsShorthand(window.shorthand());
        }
      } else {
        router.reload();
      }
    }

    const handleRouteChangeStart = (url, { shallow }) => {
      if (window.googletag && googletag?.apiReady) {
        const { googletag } = window
        googletag.cmd.push(function () {
          googletag.destroySlots();
          document.querySelector('.bottomLeaderboardObj')?.classList.add('!tw-hidden');
        })
      }
      //handleTracking()
    }

    router.events.on('routeChangeStart', handleRouteChangeStart)
    router.events.on('routeChangeComplete', handleRouteChangeComplete)

    const url = new URL(window.location.href)
    setVirtualUrl(url)

    handleTracking()

    // window?.divolte?.signal('pageView', { 'user_id': pageProps.session?.token?.sub || '0' })

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart)
      router.events.off('routeChangeComplete', handleRouteChangeComplete)
    }
  }, [isShorthand])
  //console.log('pathname',router.pathname)
  //console.log('pageProps',pageProps)

  return (
    <>
      {
        (router.pathname=='/account/subscribe')?
        <Script
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `
              <!-- Event snippet for Become AI member conversion page, In your html page, add the snippet and call gtag_report_conversion when someone clicks on the chosen link or button. -->
              function gtag_report_conversion(url) {
                var callback = function () {
                  if (typeof(url) != 'undefined') {
                    window.location = url;
                  }
                };
                gtag('event', 'conversion', {
                    'send_to': '${process.env.NEXT_PUBLIC_GOOGLE_AD_CONVERSION}',
                    'event_callback': callback
                });
                return false;
              }
            `,
          }}
        />:
        <></>
      }
      <Script
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
              send_page_view: false,
              ${pageProps?.session?.token?.sub ? `user_id: "${pageProps?.session?.token?.sub}"` : ''}
            });
          `,
          }}
      />
      <VirtualUrlContext.Provider value={[virtualUrl, setVirtualUrl]} >
        <Provider session={pageProps?.session} >
          <AppContext.Provider value={{ val1: [loading, setLoading], val2: [aId, setAId] }}>
            <LayoutWrapper>
              <Component setLoading={setLoading} {...pageProps} />
            </LayoutWrapper>
            <CookieConsent />
            <code data-client-id={process.env.NEXT_PUBLIC_AUTHSERVER_CLIENTID}
              data-app-version={`${process.env.NEXT_PUBLIC_APP_GITHASH} (${process.env.NEXT_PUBLIC_APP_ENVIRONMENT})`}>
            </code>
          </AppContext.Provider>
          <Script src="/js/geotarget.js?v=1.0.32.2" strategy="afterInteractive" onLoad={() => {
            window.geoTarget(process.env.NEXT_PUBLIC_GEO_TARGET_SG_ID, process.env.NEXT_PUBLIC_GEO_TARGET_SG_REFERRERID)
            window.geoTarget(process.env.NEXT_PUBLIC_GEO_TARGET_NO_JP_ID, process.env.NEXT_PUBLIC_GEO_TARGET_NO_JP_REFERRERID)
          }} />
          <Script src="/js/shorthand.js" strategy="afterInteractive" onLoad={() => { setIsShorthand(shorthand()) }} />
        </Provider>
      </VirtualUrlContext.Provider>
    </>
  )
}

MyApp.getInitialProps = async (context) => { 
  const URL = context?.ctx?.req?.url?.includes('.aspx');

  if (URL) {
    try {
      const result = await getAspxRedirect(context.ctx.req.url);
      const data = result?.data;

      if (data?.redirect) {
        context.ctx.res.writeHead(302, { Location: data.newUrl });
        context.ctx.res.end();
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }

  return { data: {} };

}
export default MyApp;
