import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-select';

const stylesConfig = {
  control: (provided, state) => ({
    ...provided,
    borderColor: state.isFocused ? '#ED1C24' : '#707070',
    borderRadius: 0,
    boxShadow: state.isFocused && '0 0 0 1px #ED1C24',
    '&:hover': {
      borderColor: '#ED1C24',
      // borderColor: state.isFocused ? '#ED1C24' : '#000',
    }
  }),
  placeholder: (provided) => ({
    ...provided,
    fontWeight: 'bold',
    color: '#CECECE',
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontWeight: 'bold',
    color: state.isFocused ? '#ED1C24' : 'black',
  }),
  indicatorSeparator: (provided, state) => ({
    ...provided,
    backgroundColor: '#CECECE'
  }),
  dropdownIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused ? '#ED1C24' : '#707070',
    '&:hover': {
      color: '#ED1C24',
      // color: state.isFocused ? '#ED1C24' : 'black',
    }
  }),
  clearIndicator: (provided, state) => ({
    ...provided,
    color: state.isFocused ? '#ED1C24' : '#707070',
    '&:hover': {
      color: '#ED1C24',
      // color: state.isFocused ? '#ED1C24' : 'black',
    }
  }),
  menu: (provided) => ({
    ...provided,
    margin: '1px 0 0 0',
    borderRadius: 0,
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    fontWeight: 'bold',
    color: state.isFocused ? 'white' : state.isDisabled && '#CECECE',
    backgroundColor: state.isFocused && '#ED1C24',
    '&:active': {
      backgroundColor: '#A71C20'
    }
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    fontWeight: 'bold',
    color: '#CECECE',
  }),
}

export const Select = ({ onChange, ...props }) => {
  
  const handleChange = value =>{
    onChange(value);
  }
    
  return <Autocomplete isClearable isSearchable 
    styles={stylesConfig} 
    onChange={handleChange} 
    defaultValue={props.options.find(option => option.selected === true)} 
    getOptionLabel={option => `${option.text} (${option.count})`} 
    getOptionValue={option => option.key} 
    isOptionDisabled={(option) => option.count === 0} 
    {...props} />
}

export default Select;

Select.propTypes = {
  /**
  * Option list data, sample: [{label:'Option 1', value: 'option 1'}, {label:'Option 2', value: 'option 2'}]
  */
  options: PropTypes.array.isRequired,
  /**
  * Placeholder text
  */
  placeholder: PropTypes.string,
  /**
  * onChange callback function
  */
  onChange: PropTypes.func.isRequired,
};