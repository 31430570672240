import Head from "next/head"
import Script from "next/script"

/**
 * Alternative application ld in cases where NextSeo has missing properties
 * @param {Object} props 
 * @param {string} props.type
 * @param {Object} props.structuredData
 * @param {any} props.key
 * @returns 
 */
const ApplicationLd = ({ type, structuredData, key }) => (
  <Head key={key}>
    <script 
      type="application/ld+json"
      dangerouslySetInnerHTML={{
        __html: JSON.stringify({
          "@context": "https://schema.org",
          "@type": type || '',
          ...structuredData
        })
      }}
    />
  </Head>
)

export {
  ApplicationLd
}