import { getEventURL } from '@app/utils'
import { CorporateContact, Breadcrumb, ListingSeo, ItemList } from "@app/components/Seo"


export const Events = ({ data }) => (
  <>
    <CorporateContact />
    <ListingSeo
      listing="Events"
      url={getEventURL()}
    />
    <ItemList items={data?.items} />
    <Breadcrumb 
      elements={[
        {
          position: 2,
          name: "Events",
          item: getEventURL()
        }
      ]}
    />
  </>
)