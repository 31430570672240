import { Fragment, useEffect, useState, useCallback, useContext } from 'react';
import { isMobileOnly } from 'react-device-detect';
import { SEARCH_START, MAX_FETCH, LOADMORE_ROWS, VirtualUrlContext } from '@app/utils';
import { Container, Section, Title, Button, ArticleSummary, MobileInsertAd } from '@app/components';
import { AppContext, extractFilter } from '@app/utils';
import { useRouter } from 'next/router'
import { useSession } from 'next-auth/client';

export const LoadMoreSection = (props) => {
  const [ state, setState ] = useState({
    pagingData: null,
    filters: props?.filters||null,
    hasFilter: props.hasFilter||false,
    queryId: null,
    queries: [],
    wrapperList: [],
    showLoader: false,
    isMounted: false,
    showLoadMore: false,
    fetchCount: 0,
  })

  const { pagingData, filters, queryId, queries, wrapperList, showLoadMore, showLoader, isMounted, fetchCount } = state

  const adLimit = props.limit || parseInt(process.env.NEXT_PUBLIC_AD_LIMIT)
  const numItems = props.rows || LOADMORE_ROWS
  const maxFetch = props.maxFetch || MAX_FETCH
  const title = props.title || ''
  const func = props.func || 'search'
  const itemType = props.itemType || 'article'
  const showBackDrop = props.showBackDrop || false;
  const { val1 } = useContext(AppContext);
  const [loading, setLoading] = val1
  const [ virtualUrl, setVirtualUrl ] = useContext(VirtualUrlContext)
  const router = useRouter()
  //  const [session, loading] = useSession()
  const [session] = useSession()


  const getMoreArticles = async (props) => {
    const { id, start, filters, rows, func, queries, itemType } = props;
    //let tempArticleList = [...articleList]
    // console.log(props)
    let res, data, qs, extFilters;
    setState((prev) => ({ ...prev, showLoader: true }));
    switch (func) {
      case 'getRelatedArticles':
        qs = new URLSearchParams({ id: id, itemTypes: 'article', start: start, rows: rows, sort: 'latest' }).toString();
        res = await fetch(`/api/articles/related?${qs}`)
        break;
      case 'getRelatedVideos':
        qs = new URLSearchParams({ id: id, itemTypes: 'video', start: start, rows: rows, sort: 'latest' }).toString();
        res = await fetch(`/api/articles/related?${qs}`)
        break;
      case 'getRelatedGalleries':
        qs = new URLSearchParams({ id: id, itemTypes: 'gallery', start: start, rows: rows, sort: 'latest' }).toString();
        res = await fetch(`/api/articles/related?${qs}`)
        break;
      case 'getRelatedEvents':
        qs = new URLSearchParams({ id: id, itemTypes: 'event', start: start, rows: rows, sort: 'latest' }).toString();
        res = await fetch(`/api/articles/related?${qs}`)
        break;
      case 'getRelatedWebinars':
        qs = new URLSearchParams({ id: id, itemTypes: 'webinar', start: start, rows: rows, sort: 'latest' }).toString();
        res = await fetch(`/api/articles/related?${qs}`)
        break;
      case 'getRelatedTopics':
        qs = new URLSearchParams({ id: id, itemTypes: 'topic', start: start, rows: rows, sort: 'latest' }).toString();
        res = await fetch(`/api/articles/related?${qs}`)
        break;
      case 'getRelatedLinks':
        qs = new URLSearchParams({ id: id, itemTypes: 'link', start: start, rows: rows, sort: 'latest' }).toString();
        res = await fetch(`/api/articles/related?${qs}`)
        break;
      case 'search':

        qs = new URLSearchParams({ ...filters, start: start, rows: rows }).toString();
        res = await fetch(`/api/search?${qs}`)
        break;
      case 'category':
        qs = new URLSearchParams({ categoryIds: id, start: start, rows: rows, sort: 'latest' }).toString();
        res = await fetch(`/api/search?${qs}`)
        break;
      case 'country':
        qs = new URLSearchParams({ countryIds: id, itemTypes: 'article', start: start, rows: rows, sort: 'latest' }).toString();
        res = await fetch(`/api/search?${qs}`)
        break;
      case 'author':
        qs = new URLSearchParams({ authorIds: id, start: start, rows: rows, sort: 'latest' }).toString();
        res = await fetch(`/api/search?${qs}`)
        break;
      case 'tag':
        qs = new URLSearchParams({ tags: id, start: start, rows: rows, sort: 'latest' }).toString();
        res = await fetch(`/api/search?${qs}`)
        break;
      case 'topic':
        qs = new URLSearchParams({ start: start, rows: rows, id: id, sort: 'latest' }).toString();
        //console.log(queries)
        if (queries)
          qs = queries + qs
        //console.log(qs)
        res = await fetch(`/api/advsearch?${qs}`)
        break;
      case 'event':
      case 'webinar':
      case 'gallery':
      case 'video':
        qs = new URLSearchParams({ itemTypes: func, start: start, rows: rows, sort: 'latest' }).toString();
        res = await fetch(`/api/search?${qs}`)
        break;
      case 'previousEvent':
      case 'previousWebinar':
        qs = new URLSearchParams({ itemTypes: itemType, start: start, rows: rows, sort: 'latest', itemTypeListing: 'previous' }).toString();
        res = await fetch(`/api/search?${qs}`)
        break;
      case 'upcomingEvent':
      case 'upcomingWebinar':
        qs = new URLSearchParams({ itemTypes: itemType, start: start, rows: rows, sort: 'latest', itemTypeListing: 'upcoming' }).toString();
        res = await fetch(`/api/search?${qs}`)
        break;
      case 'magazine':
        qs = new URLSearchParams({ start: start, rows: rows, sort: 'latest' }).toString();
        res = await fetch(`/api/magazines?${qs}`)
        break;

      default:
        qs = new URLSearchParams({ id: id, itemTypes: 'article', sort: 'latest' }).toString();
        res = await fetch(`/api/article?${qs}`)
        break;
    }
    
    if (res.status === 200) {
      data = await res.json()
      //tempHashList.push(randHash(32))
      /* setShowLoader(false)
      setPagingData(data.paging);
      setArticleList((prevArticles) => {
        return [...prevArticles, data]
      }) */
      console.log(data);
      return data;
    }
  }
  const loadMoreHandler = useCallback(async (props) => {
    const data = await getMoreArticles(props);
    let extFilters;
    let showLoadMore = false

    if (data && data.items?.length > 0) {
      showLoadMore = data.paging?.hasNext && !(maxFetch > 0 && fetchCount >= maxFetch)
      //console.log(fetchCount,maxFetch)
      extFilters = extractFilter(data.filter);
      setState((prev) => ({
        ...prev,
        wrapperList: prev.wrapperList.length === 0 && title !== '' ? 
          [...prev.wrapperList, <Wrapper key={`wrapper-${prev.wrapperList.length + 1 || 1}`} noAd={!((prev.wrapperList.length + 1) <= adLimit)} title={title} data={data} />] : 
          [...prev.wrapperList, <Wrapper key={`wrapper-${prev.wrapperList.length + 1 || 1}`} noAd={!((prev.wrapperList.length + 1) <= adLimit)} data={data} />],
        pagingData: data.paging,
        filters: extFilters,
        showLoader: false,
        fetchCount: prev.fetchCount + 1,
        showLoadMore: showLoadMore
      }))
      //if (showBackDrop)
        setLoading(false);
      
    } else {
      setState((prev) => ({ ...prev, showLoadMore: showLoadMore }))
    }
  }, [title, setLoading, showBackDrop, fetchCount, maxFetch, adLimit])

  useEffect(() => {
    let queryStr = '';
    if(state.hasFilter){
      if (props?.queries) {
        for (const [key, value] of Object.entries(props.queries)) {
          queryStr += `${key}=${encodeURIComponent(value)}&`
        }
      }
      if (props?.loadArticles) {
        loadMoreHandler({ ...props, queries: queryStr, start: (props.pagingData.start), rows: numItems });
      }
      //}
      setState((prev) => ({
        ...prev,
        pagingData: props.pagingData,
        filters: props.filters,
        queryId: props.id,
        queries: queryStr,
        isMounted: true,
        showLoadMore: !props?.loadArticles && maxFetch === 0 && props.pagingData?.hasNext,
        wrapperList: props?.loadArticles ? prev.wrapperList : []
      }))
    }
  }, [props.filters,props.pagingData, itemType ])

  useEffect(()=>{
    if(!state.hasFilter){
      if(props?.loadArticles)
        loadMoreHandler({ ...props, start: (props.pagingData.start), rows: numItems });
      setState((prev) => ({
        ...prev,
        pagingData: props.pagingData,
        filters: props.filters,
        queryId: props.id,
        isMounted: true,
        showLoadMore: props.pagingData?.hasNext,
        wrapperList: []
      }))
    }
  },[])
 

  const renderArticles = (data, title, noAd) => {
    const { items } = data;
    const Render = () =>
      items.map((item, i) =>
        <Fragment key={`${item.id}-${i}`}>
          {!(i === 0) && Number.isInteger(i / numItems * 2) ? <MobileInsertAd googleAd={props.googleAd} type='secondMpu' /> : ''}
          {isMobileOnly
            ? <ArticleSummary {...item} itemType={item.itemType} columnProps={{skipBorder: `${(numItems / 2) + 2}n`}} />
            : <ArticleSummary {...item} itemType={item.itemType} />}
        </Fragment>
      )

    return <div className={noAd ? 'tw-pt-8 tw-border-t tw-border-border' : undefined}>
      {title
        ? <Section title={<Title title={title || ''} />}><Render /></Section>
        : <Render />}
      {/*         <Script
          strategy="afterInteractive"
          dangerouslySetInnerHTML={{
            __html: `if(window?.geoTarget){window.geotarget('${process.env.NEXT_PUBLIC_GEO_TARGET_SG_ID}','${process.env.NEXT_PUBLIC_GEO_TARGET_SG_REFERRERID}');}`,
          }}
        /> */}
    </div>

  }


  const Wrapper = ({ data, title, noAd }) => {
    return <Container center={renderArticles(data, title, noAd)} noAd={noAd} loadMoreNoAd={noAd} googleAd={props.googleAd} leaderboard mpu='secondMpu' />;

  }

  const LoadMoreButton = () => {
    return (
      <div className='tw-text-center' >
        {!showLoader
          ? <Button title={`Load More`} id='loadMore' btnType='box' color='secondary' outline onClick={() => {
            //if (showBackDrop)
            setLoading(true);
            loadMoreHandler({ itemType: itemType, func: func, id: queryId, filters: filters, queries: queries, start: (pagingData.start + numItems), rows: numItems });
            
            window?.divolte?.signal('pageView', { 'user_id': session?.token?.sub || '0' })
            if (typeof window?.gtag === 'function') {
              
              const _url = new URL(window.location.href)
              // const params = _url.searchParams
              // params.set('loadmore', 1) //Number(params.get('loadmore')) + 1) // Eventually will loadmore=[Count] will allow user to jump to Count number of load-more's (pagination)
              _url.hash = '#load-more' // Update hash to url
              //const url = _url.href
              router.push(_url.href, _url.href, {
                shallow: true
              }).then(() => {
                const _virtual = new URL(virtualUrl.href)
                _virtual.hash = _url.hash
                // GA_onLoadContent(props.summary, props.subscriptionStatus)
                const payload = {
                  send_to: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS,
                  page_location: _virtual.href,
                  // user_id: typeof props.userId !== 'undefined' ? props.userId : '0'
                }
                if (typeof props.summary !== 'undefined' && typeof props.subscriptionStatus !== 'undefined') {
                  payload.dimension1 = props.summary.itemType // Site section
                  payload.dimension2 = props.summary.id // Article Id
                  payload.dimension3 = props.summary.heading // Article Name
                  payload.dimension4 = props.subscriptionStatus.lockReason // Lock Reason
                  payload.dimension5 = props.summary.tags?.join('|') || '' // Tags
                  payload.dimension6 = props.summary.countryNames?.join('|') || '' // Country Tags
                  payload.dimension7 = props.summary.categoryNames?.join('|') || '' // Site Category
                }
                
                
                //console.log('GA_onLoadMore', payload)
                window.gtag('event', 'page_view', payload);
                // console.log('onLoadMore', _virtual.href)
              })
            }
      
            if (window?.geoTarget)
              window.geoTarget(process.env.NEXT_PUBLIC_GEO_TARGET_SG_ID, process.env.NEXT_PUBLIC_GEO_TARGET_SG_REFERRERID);      
            
          }} long />
          : <div className='tw-font-bold tw-uppercase'>
            <img src='/images/loading.svg' alt='Loading...' className='tw-block tw-w-16 tw-h-16 tw-mx-auto' />
            Loading...
          </div>}
      </div>
    )
  }
  if (isMounted) {
    return <>
      {(wrapperList?.length > 0) && <>{wrapperList}</>}
      {showLoadMore ? <Container center={
        <div className='tw-text-center'>
          <LoadMoreButton />
        </div>
      } noAd /> : <></>}

    </>
  } else {
    return <></>
  }

}

export default LoadMoreSection;
