import React from 'react';
import { FooterLogo, Follow, Bulletin, FooterNav, Copyright } from '@app/components';


/**
* Footer UI component for layout
*/
export const Footer = props =>
  <footer className='tw-bg-black'>
    <div className='tw-flex tw-flex-col tw-flex-wrap tw-gap-16 tw-px-2 tw-pt-8 tw-pb-16 tw-mx-auto tw-max-w-screen-3xl | sm:tw-px-4'>
      <div className='tw-flex tw-flex-col tw-items-center tw-gap-8 | sm:tw-flex-row sm:tw-flex-nowrap'>
        <FooterLogo />
        <div className='tw-hidden | sm:tw-block sm:tw-flex-grow' />
        <Follow />
      </div>

      <div className='tw-flex tw-flex-col tw-gap-8 | lg:tw-flex-row lg:tw-flex-nowrap lg:tw-gap-8 | 2xl:tw-gap-16'>
        <div className='tw-flex-grow tw-w-full | lg:tw-w-1/3 | 2xl:tw-w-1/4'>
          <Bulletin dark />
        </div>

        <div className='tw-flex-grow tw-w-full | lg:tw-w-2/3 | 2xl:tw-w-3/4'>
          <FooterNav />
        </div>
      </div>

      <Copyright />
    </div>
  </footer>

export default Footer;