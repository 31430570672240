import React from 'react';

/**
* CookieConsent UI component
*/
export const CookieConsent = props => {
  return (
    <>
      <style dangerouslySetInnerHTML={{__html: 'getsitecontrol-widget {width: 100%}'}} />
      <script type="text/javascript" async src="//l.getsitecontrol.com/p43gqvrw.js"></script>
    </>
  )
};

export default CookieConsent;