import { getCategoryURL } from '@app/utils'
import { CorporateContact, Breadcrumb, ListingSeo, ItemList } from '@app/components/Seo'

export const Categories = ({ data, category }) => (
  <>
    <CorporateContact />
    <ListingSeo
      listing={category?.text || "Category"}
      url={getCategoryURL(category?.slug, category?.key)}
    />
    <ItemList items={data?.items} />
    <Breadcrumb 
      elements={[
        {
          position: 2, 
          name: category?.text || "Category",
          item: getCategoryURL(category?.slug, category?.key)
        }
      ]}
    />
  </>
)