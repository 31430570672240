import { useEffect, useState, createContext, useContext } from "react";
import { AppContext } from "./context";

export const randHash = (length) => {
  let result           = '';
  let characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let charactersLength = characters.length;
  for ( var i = 0; i < length; i++ ) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
}

export const useOnScreen = (ref, rootMargin = "0px") => {
  // State and setter for storing whether element is visible
  const [isIntersecting, setIntersecting] = useState(false);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        // Update our state when observer callback fires
        setIntersecting(entry.isIntersecting);
      },
      {
        rootMargin,
      }
    );
    if (ref.current) {
      observer.observe(ref.current);
    }
    return () => {
      observer.unobserve(ref.current);
    };
  }, []); // Empty array ensures that effect is only run on mount and unmount
  return isIntersecting;
}

export const Context = createContext();

export const useAdSlot = ({ mapping, sizes, adId, targetId }) =>{
  const {val2} = useContext(AppContext)
  const [aId, setAId] = val2
  
  useEffect(() => {
    if(!adId) {
      throw new Error("adId cannot be empty.");
    }
    if(!targetId) {
      throw new Error("targetId cannot be empty.");
    }
    
    if (typeof window !== undefined  && window?.googletag && googletag.apiReady) {
      const { googletag } = window;
      googletag.cmd.push(function () {
        var adMapping = googletag.sizeMapping();
        Object.keys(mapping).forEach((breakpoint) => {
          adMapping.addSize([Number(breakpoint), 0], [mapping[breakpoint]]);
        });
/*         mapping.forEach((item,i)=>{
          
          adMapping.addSize(item, sizes[i]).build();
          console.log(adMapping,item,sizes[i]);
        }) */
        var builtMapping = adMapping.build(); 
        //console.log(builtMapping)
        console.table({ adId, sizes: JSON.stringify(sizes), targetId });
        googletag
          .defineSlot(
            `${adId}`,
            sizes,
            `${targetId}`
          ).defineSizeMapping(builtMapping)
           .addService(googletag.pubads())
           .setTargeting('aid',aId);

        googletag.pubads().enableSingleRequest();
        googletag.pubads().addEventListener('impressionViewable',
          function(event) {
            var slot = event.slot;
            console.log('Impression for slot', slot.getSlotElementId(),
                        'became viewable.');

            if (slot === targetSlot) {
              // Slot specific logic.
            }
          }
        );
        googletag.enableServices();
      });

      googletag.cmd.push(function () {
        googletag.display(`${targetId}`);
      });
    }
  }, [mapping, sizes, adId,aId, targetId]);
}

/**
 * Used for parsing pesky titles with unicodes
 * @param {String} str the string including the unicode decimal
 * @returns a string with the unicode decimals decoded
 */
export const parseUnicodeDecimal = (str) => (
  str.replace(/&#([0-9]{1,4});/gi, (match, numStr) => {
    var num = parseInt(numStr, 10); // read num as normal number
    return String.fromCharCode(num);
  })
)