import React from 'react';
import PropTypes from 'prop-types';
import { ArticleSummary} from '@app/components';

/**
* Top 5 (Partner Content Highlight) component for Home
*/
export const Top5 = ({ item, ...props }) => {
  const GeoSg = item?.tags && item?.tags.some(tags => tags.toLowerCase() === process.env.NEXT_PUBLIC_GEO_TARGET_SG_TAG) && true;
  const GeoNoJp = item?.tags && item?.tags.some(tags => tags.toLowerCase() === process.env.NEXT_PUBLIC_GEO_TARGET_NO_JP_TAG) && true;

  return <div className={`${GeoSg ? process.env.NEXT_PUBLIC_GEO_TARGET_SG_CONTENT + ' ' : ''}${GeoNoJp ? process.env.NEXT_PUBLIC_GEO_TARGET_NO_JP_CONTENT + ' ' : ''}tw-pt-4 tw-border-t tw-border-border | md:tw-pt-8 md:tw-pb-0 | lg:tw-border-t-0`} style={(GeoSg || GeoNoJp) ? { display: 'none' } : undefined}>
    <ArticleSummary variant='top5' {...item} column={false} geoTarget={false} />
  </div>
}

export default Top5;

Top5.propTypes = {
  /**
  * Content objects for display
  */
  item: PropTypes.object.isRequired,
};