import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { isMobileOnly, CustomView, MobileOnlyView } from 'react-device-detect';
import { Ad, MobileInsertAd, Space, HomeQuickSearch } from '@app/components';
import { TYPEFORM_POLLING_ID } from '@app/utils';
import { Widget } from '@typeform/embed-react'

/**
* HomeContainer component for suitable view switch
*/
export const HomeContainer = (props) => {
  const [mounted, setMounted] = useState(false)
  const googleAd = props.googleAd || true
  const pollingId = TYPEFORM_POLLING_ID || false
  useEffect(() => {
    setMounted(true)
  }, []);
  //console.log(mounted, props)
  return (
    <section className='tw-flex tw-flex-col tw-gap-8 tw-w-full | sm:tw-gap-16'>
      <div className='tw-max-w-container tw-flex tw-flex-col tw-flex-wrap tw-gap-8 tw-px-2 tw-m-auto tw-w-full | sm:tw-flex-row sm:tw-flex-nowrap sm:tw-gap-4 sm:tw-px-4 | md:tw-gap-12'>
        <aside className='tw-hidden | 2xl:tw-w-75 2xl:tw-flex 2xl:tw-flex-col 2xl:tw-gap-16'>
          {props.leftTop}
          <Ad type='leftMpu' googleAd={googleAd} />
          {props.leftBottom}
        </aside>

        <div className={`tw-flex-grow tw-w-full tw-flex tw-flex-col tw-gap-16 | sm:tw-w-[calc(100%-19.75rem)] | 2xl:tw-w-[calc(100%-(2*19.75rem))]`}>
          {props.centerTop}
          {props.centerBottom}
          <div className='tw-hidden | sm:tw-block'>
            <HomeQuickSearch />
          </div>
        </div>

        <aside className='homeRightColumn tw-flex-none tw-w-full tw-flex tw-flex-col tw-gap-16 | sm:tw-w-75'>
          <div><CustomView renderWithFragment condition={isMobileOnly !== true}>
            <Ad type='firstMpu' googleAd={googleAd} />
          </CustomView></div>

          <div className='2xl:tw-hidden'><CustomView renderWithFragment condition={isMobileOnly !== true}>
            <Ad type='leftMpuMobile' googleAd={googleAd} />
          </CustomView></div>

          {/* HSBC typeform polling control by pollingId value */}
          {pollingId && <div>
            <div className={`tw-font-body tw-text-xs tw-leading-loose tw-uppercase tw-text-center ${props.white ? 'tw-text-white' : 'tw-text-third-main'}`}>Audience Poll</div>
            <Widget id={pollingId} className='tw-w-full tw-h-[700px]' />
          </div>}

          {props.rightTop}

          {props.leftTop && <div className='2xl:tw-hidden'>
            {props.leftTop}
          </div>}

          <div><CustomView renderWithFragment condition={isMobileOnly !== true}>
            <Ad type='secondMpu' googleAd={googleAd} />
          </CustomView></div>

          <div><MobileOnlyView>
            <div className={`tw-h-ad tw--mx-2 tw-bg-secondary-light tw-relative tw-overflow-hidden ${props.type}`}>
              <div className='interscroller tw-absolute tw-w-full tw-h-full'>
                <div className='tw-fixed tw-top-1/2 tw-left-1/2 tw--translate-x-1/2 tw--translate-y-1/2'>
                  <Ad googleAd type='secondMpu' />
                </div>
              </div>
            </div>
          </MobileOnlyView></div>

          {props.leftBottom && <div className='2xl:tw-hidden'>
            {props.leftBottom}
          </div>}

          {props.rightBottom}

          <div className='sm:tw-hidden'>
            <HomeQuickSearch />
          </div>
        </aside>
      </div>
    </section>
  )
}

export default HomeContainer;

HomeContainer.propTypes = {
  /**
  * Center Top column elements
  */
  centerTop: PropTypes.node.isRequired,
  /**
  * Center Bottom column elements under center, and mobile view switch to the bottom
  */
  centerBottom: PropTypes.node,
  /**
  * Left Top column elements
  */
  leftTop: PropTypes.node,
  /**
  * Left Bottom column elements
  */
  leftBottom: PropTypes.node,
  /**
  * Right Top column elements (beside first MPU & second MPU)
  */
  rightTop: PropTypes.node,
  /**
  * Right Bottom column elements (beside first MPU & second MPU)
  */
  rightBottom: PropTypes.node,
};