import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@app/components';
import DefaultProps from '@app/header.json';
import DefaultPropsUAT from '@app/header-uat.json';

const DefaultPropsContent = process.env.NEXT_PUBLIC_APP_ENVIRONMENT === 'Production' ? DefaultProps : DefaultPropsUAT;

export const Follow = props => {
  const [weChatOpen, setWeChatOpen] = useState(false);

  const handleClick = value => () => {
    setWeChatOpen(value);
  }

  return <section className='tw-inline-flex tw-flex-row tw-flex-nowrap tw-gap-6 tw-mx-auto'>
    {props.follow.map(({ img, display, ...item }, i) =>
      item.url ?
        <div key={i}>
          <Button btnType='icon' color='white' noResponsive title={display} {...item} />
        </div>
        : <div key={i}>
          <Button btnType='icon' color='white' noResponsive {...item} title={display} onClick={handleClick(true)} />
          {weChatOpen &&
            <div className='tw-fixed tw-top-0 tw-left-0 tw-z-10 tw-w-full tw-h-screen tw-flex tw-items-center tw-justify-center tw-bg-opacity-75 tw-bg-black' onClick={handleClick(false)}>
              <img src={img} alt={item.title} className='tw-w-4/5 tw-h-auto tw-max-w-80' />
              {props.open &&
                <style>{"\
                body {\
                  height: 100vh;\
                  overflow: hidden;\
                }\
              "}</style>
              }
            </div>
          }
        </div>
    )}
  </section>
}

export default Follow;

Follow.prototype = {
  /**
  * Follow information array
  */
  follow: PropTypes.arrayOf(PropTypes.shape({
    /**
    * Title or icon text of the button
    */
    display: PropTypes.string.isRequired,
    /**
    * Hyperlink of the button
    */
    url: PropTypes.string,
    /**
    * Image of QR code
    */
    img: PropTypes.string,
  }))
}

Follow.defaultProps = {
  follow: DefaultPropsContent.follow,
}