import { LOCK_REASON } from './constants';
import { setCookies, getCookie, checkCookies } from 'cookies-next';
import { getViewLogs, postViewLogs, getAccount, isEmpty } from "@app/utils";

import queryString from 'query-string'

export const subscriptionCheck = async (lockStatus, id, session, res, req) => {  

  const today = Math.floor(Date.now() / 1000);
  const expDur = parseInt(process.env.NEXT_PUBLIC_ARTICLE_EXPIRED_DUR);
  const limit = parseInt(process.env.NEXT_PUBLIC_FREE_ARTICLE_LIMIT);
  const cookiesLimit = parseInt(process.env.NEXT_PUBLIC_FREE_COOKIES_ARTICLE_LIMIT);
  let userData = session?.user;
  
  let items, unExpired = [], existed, remains = 0, viewable = true;

  const checkUser = await getAccount(session?.user?.userId); // check latest subscription

  if (!isEmpty(checkUser.data.result))
  {
      userData.hasActiveSubscription = checkUser.data.result.hasActiveSubscription;
      userData.notEligibleForTrial = checkUser.data.result.notEligibleForTrial;
  }

  if(lockStatus===undefined || (lockStatus!=='Public' && lockStatus!=='Locked')){
    if(session === null ){
      // Check cookie
      const cookie = getCookie('items', { req, res});
      const newItem = {id:id, viewAt:today};

      if(!cookie){ // no cookie
        setCookies('items' ,JSON.stringify([newItem]),{ req, res, maxAge: expDur });
        remains = cookiesLimit - 1
      }else{
        items = JSON.parse(cookie)
        unExpired = getUnExpiredArticles(items);
        //console.log(unExpired)
        existed = unExpired?.find( o=> o.id===id)
        /* if(existed){ // update new viewed date
          existed.viewAt = today
          setCookies('items' ,JSON.stringify(unExpired),{ req, res, maxAge: expDur });
        }else  */
        if(!existed && unExpired.length<cookiesLimit){ // insert to cookie
          unExpired.push(newItem)
          setCookies('items' ,JSON.stringify(unExpired),{ req, res, maxAge: expDur });
        }else if(!existed && unExpired.length >= cookiesLimit){ // new article but no quota
          viewable = false
        }
        remains = cookiesLimit - unExpired.length
      }
      
    }else{
      // check view log by user id
      // has work email but no active subscription
      
      if(!userData?.hasActiveSubscription && session?.token?.sub){ // no subscription but registered
        const viewData = await getViewLogs(session.token.sub);
        items = viewData?.data?.items;
        //console.debug('viewData',items)
        //unExpired = getUnExpiredArticles(items);
        existed = items?.find( o=> o.id===id)
  
        if(items.length<limit){ // update new viewed date
          await postViewLogs( id, session.token.sub, today, expDur)
        }else if(!existed && items.length >= limit){ // new article but no quota
          viewable = false
        }
        remains = limit - items.length
      }
    }
    //console.debug('existed',existed?'true':'false', 'unExpired items',unExpired.length)
  }
  
  return getLockStatus(lockStatus, remains, userData, viewable);
  
}

const getUnExpiredArticles = (articles) =>{
  const expDur = parseInt(process.env.NEXT_PUBLIC_ARTICLE_EXPIRED_DUR);
  const today = Math.floor(Date.now() / 1000);
  return articles?.filter(o=>((today-o.viewAt)<=expDur)) || []
}

export const getPreviewLockStatus = ()=>{
  return {
    "remainingQuota": 100,
    "usedQuota": 0,
    "quota": parseInt(process.env.NEXT_PUBLIC_FREE_ARTICLE_LIMIT),
    "isLoggedIn": true,
    "lockReason": LOCK_REASON.NotLocked
  }
}

const getLockStatus = (lockStatus, remains, userData, viewable) => {
  //console.log('getLockStatus',lockStatus, remains, userData);
  let sharedProps = {
    "remainingQuota": remains,
    "usedQuota": parseInt(process.env.NEXT_PUBLIC_FREE_ARTICLE_LIMIT) - remains,
    "quota": parseInt(process.env.NEXT_PUBLIC_FREE_ARTICLE_LIMIT),
    "isLoggedIn": null
  }
  
  switch (lockStatus) {
    case 'Public':
      if(userData){ // Registered User
        return {
          ...sharedProps,
          "isLoggedIn": true,
          "lockReason": "NotLocked", 
        }
      }else{ //  No Login User
        return {
          ...sharedProps,
          "isLoggedIn": false,
          "lockReason": "NotLocked", 
        }
      }
          
    case 'Locked':
      if(userData){ // Registered User
        sharedProps.isLoggedIn = true;
        if(userData?.hasWorkEmail === true){
          if(userData?.hasActiveSubscription === true){ // Has subscription
            return {
              ...sharedProps,
              "quota": 0,
              "lockReason": LOCK_REASON.NotLocked
            }
          } else if(userData?.notEligibleForTrial === true){ //  Not eligible for trial
            return {
                ...sharedProps,
                "lockReason": LOCK_REASON.NotEligibleForTrial
            }
          } else{ //  No subscription but registered
            return {
              ...sharedProps,
              "lockReason": LOCK_REASON.NoSubscription
            }
          }
        }else{
          return {
            ...sharedProps,
            "lockReason": LOCK_REASON.DomainBlacklisted, 
          }
        }
      }else{
        return {
          ...sharedProps,
          "isLoggedIn": false,
          "lockReason": LOCK_REASON.TrialEnded
        }
      }
      
         
     
    default: // null or default
      if(userData){ // Registered User
        sharedProps.isLoggedIn = true;
        if(userData?.hasWorkEmail === true){
          if(userData?.hasActiveSubscription === true){ // Has subscription
            return {
              ...sharedProps,
              "quota": 0,
              "lockReason": LOCK_REASON.NotLocked
            }
          } else if(userData?.notEligibleForTrial === true){ //  Not eligible for trial
            return {
                ...sharedProps,
                "lockReason": LOCK_REASON.NotEligibleForTrial
            }
          } else{ //  No subscription but registered
            if(remains>=0 && viewable){ //  Viewlog check
              return {
                ...sharedProps,
                "lockReason": LOCK_REASON.Trialing, 
              }
            }else{
              return {
                ...sharedProps,
                "lockReason": LOCK_REASON.TrialEnded, 
              }
            }
          }
        }else{
          return {
            ...sharedProps,
            "lockReason": LOCK_REASON.DomainBlacklisted, 
          }
        }
      }else{ //  No Login User
        sharedProps.isLoggedIn = false;
        //if(remains>=0 && viewable){ //  Cookies check
        if(remains===0 && viewable){ //  Cookies check
          return {
            ...sharedProps,
            //"lockReason": LOCK_REASON.Trialing 
            "lockReason": LOCK_REASON.NotLocked 
          }
        }else{
          return {
            ...sharedProps,
            "lockReason": LOCK_REASON.TrialEnded 
          }
        }
      }
  }
};

/**
 * Handle GA pageview event for content pages (article, gallery, video)
 * @param {*} subscriptionStatus 
 * @param {*} articleSummary
 * @param {string} articleSource
 * @returns {URL}
 */
export const GA_onLoadContent = (subscriptionStatus, articleSummary, articleSource, userId) => {
  const _url = new URL(window.location.href)

  _url.searchParams.delete('lock') // Prevent existing lock query from being processed; happens when url includes lock query on landing page
  
  // Check why user is locked
  if (subscriptionStatus.lockReason === LOCK_REASON.DomainBlacklisted) {
    _url.searchParams.set("lock", LOCK_REASON.DomainBlacklisted.toLowerCase())
  }
  if (subscriptionStatus.lockReason === LOCK_REASON.TrialEnded) {
    _url.searchParams.set("lock", ((!subscriptionStatus.isLoggedIn ? "NotSignIn" : "") + LOCK_REASON.TrialEnded).toLowerCase())
  }
  if (subscriptionStatus.lockReason === LOCK_REASON.NoSubscription) {
    _url.searchParams.set("lock", LOCK_REASON.NoSubscription.toLowerCase())
  }

  const payload = {
    send_to: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS,
    page_location: _url.href,
    // user_id: '0'
  }

  if (typeof articleSummary !== 'undefined' && typeof subscriptionStatus !== 'undefined') {
    payload.dimension1 = articleSummary.itemType // Site section
    payload.dimension2 = articleSummary.id // Article Id
    payload.dimension3 = articleSummary.heading // Article Name
    payload.dimension4 = subscriptionStatus.lockReason // Lock Reason
    payload.dimension5 = articleSummary.tags?.join('|') || '' // Tags
    payload.dimension6 = articleSummary.countryNames?.join('|') || '' // Country Tags
    payload.dimension7 = articleSummary.categoryNames?.join('|') || '' // Site Category
  }

  if (typeof articleSource === 'string' && articleSource.length > 0) {
    payload.dimension8 = articleSource
  }

  // if (typeof userId !== 'undefined') {
  //   payload.user_id = userId
  // }

  // console.log('GA_onLoadContent', payload)
  window.gtag('event', 'page_view', payload);
  return _url
}