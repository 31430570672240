import React from 'react';
import PropTypes from 'prop-types';
import { Column, Button } from '@app/components';
import JsonData from '@app/header.json';
import JsonDataUAT from '@app/header-uat.json';

const JsonDataContent = process.env.NEXT_PUBLIC_APP_ENVIRONMENT === 'Production' ? JsonData : JsonDataUAT;

export const Bulletin = props => {
  const { account, subscribe } = JsonDataContent;
  return <div className='tw-w-full'>
    <Column variant='side'>
      <section className='tw-flex tw-flex-col tw-flex-nowrap tw-gap-3'>
        <header>
          <div className={`tw-font-headline tw-font-bold tw-text-xl tw-leading-tight tw-tracking-tight ${props.dark ? 'tw-text-white' : ''}`}>Sign up for <br/>AsianInvestor Bulletins</div>
          <div className={`tw-font-body tw-font-bold tw-text-base tw-leading-tight tw-tracking-tight ${props.dark ? 'tw-text-white' : ''}`}>Top news, insights and analysis every weekday</div>
        </header>
        <div className='tw-font-body tw-text-base tw-leading-tight tw-tracking-tight tw-text-third-main'>Free registration gives you access to our email bulletins</div>
        <div><Button title={account.registerDisplay} btnType='box' url={account.registerUrl} size='xs' noResponsive/></div>
      </section>
    </Column>
    <Column variant='side'>
      <section className='tw-flex tw-flex-col tw-flex-nowrap tw-gap-3'>
        <header>
          <div className={`tw-font-headline tw-font-bold tw-text-xl tw-leading-tight tw-tracking-tight ${props.dark ? 'tw-text-white' : ''}`}>Become an AsianInvestor Member</div>
          <div className={`tw-font-body tw-font-bold tw-text-base tw-leading-tight tw-tracking-tight ${props.dark ? 'tw-text-white' : ''}`}>for unlimited access to all articles, newsletters and e-magazines</div>
        </header>
        <div><Button title={subscribe.display} btnType='box' url={subscribe.url} size='xs' noResponsive/></div>
      </section>
    </Column>
  </div>
}

export default Bulletin;

Bulletin.prototype = {
  /**
  * Dark color mode
  */
  dark: PropTypes.bool,
}