import { getCountryURL } from '@app/utils'
import { CorporateContact, Breadcrumb, ListingSeo, ItemList } from "@app/components/Seo"


export const Countries = ({ data, country }) => (
  <>
    <CorporateContact
      listing={country?.text || "Country"}
      url={getCountryURL(country?.slug, country?.key)}
    />
    <ItemList items={data?.items} />
    <Breadcrumb 
      elements={[
        {
          position: 2,
          name: country?.text || "Country",
          item: getCountryURL(country?.slug, country?.key)
        }
      ]}
    />
  </>
)