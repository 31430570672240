import React from 'react';
import { Logo, Navigation, SideNavigation } from '@app/components';

/**
* Header UI component for user interaction
*/
export const Header = (props) => {

    return (
        <header>
            <div className='tw-antialiased tw-max-w-full tw-bg-black tw-flex tw-items-center'>
                <Logo />
                <Navigation btnType='nav' {...props} />
                <div className='tw-flex-grow' />
                <SideNavigation {...props} />
            </div>
            <Navigation btnType='subNav' {...props} />
        </header>
    )
}

export default Header;