import React from 'react';
import PropTypes from 'prop-types';
import { ArticleSummary, Title } from '@app/components';

/**
* Trending for Home
*/
export const EditorsPicks = ({ items, ...props }) => <>
  <Title title={`Editor's Picks`} home />

  <div className='tw-mt-8'>
    {items.map((item, i) => {
      const GeoSg = item?.tags && item?.tags.some(tags => tags.toLowerCase() === process.env.NEXT_PUBLIC_GEO_TARGET_SG_TAG) && true;
      const GeoNoJp = item?.tags && item?.tags.some(tags => tags.toLowerCase() === process.env.NEXT_PUBLIC_GEO_TARGET_NO_JP_TAG) && true;

      return <div key={i} className={`${GeoSg ? process.env.NEXT_PUBLIC_GEO_TARGET_SG_CONTENT + ' ' : ''}${GeoNoJp ? process.env.NEXT_PUBLIC_GEO_TARGET_NO_JP_CONTENT + ' ' : ''}tw-py-4 tw-border-t tw-border-border | first:tw-pt-0 first:tw-border-t-0 | last:tw-pb-0 | md:tw-py-8`} style={(GeoSg || GeoNoJp) ? { display: 'none' } : undefined}>
        <ArticleSummary variant='editorsPicks' {...items[i]} column={false} geoTarget={false} />
      </div>
    })}
  </div>
</>


export default EditorsPicks;

EditorsPicks.propTypes = {
  /**
  * Trending items for content
  */
  items: PropTypes.arrayOf(PropTypes.object.isRequired).isRequired,
};