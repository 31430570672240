import React from 'react';
import Link from 'next/link';
import Image from 'next/image';
import PropTypes from 'prop-types';
import ReactDOMServer from 'react-dom/server';
import Slider from "react-slick";

export const SlickSlider = props => {
  
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    ...props
  }
  return (
    <Slider className="tw-mb-10" {...settings}>
      <div className="tw-h-32 tw-block tw-bg-gray-100">
        <h3>1</h3>
      </div>
      <div className="tw-h-32 tw-block tw-bg-gray-200">
        <h3>2</h3>
      </div>
      <div className="tw-h-32 tw-block tw-bg-gray-300">
        <h3>3</h3>
      </div>
      <div className="tw-h-32 tw-block tw-bg-gray-400">
        <h3>4</h3>
      </div >
      <div className="tw-h-32 tw-block tw-bg-gray-500">
        <h3>5</h3>
      </div>
      <div className="tw-h-32 tw-block tw-bg-gray-600">
        <h3>6</h3>
      </div>
    </Slider>
  )
}
//https://react-slick.neostack.com/docs/api

SlickSlider.propTypes = {
  /**
  * Enable tabbing and arrow key navigation
  * Default: true
  */
  accessibility: PropTypes.bool,
  
  /**
  * Adjust the slide's height automatically
  * Default: true
  */
  adaptiveHeight: PropTypes.bool,

  /**
  * Index change callback. `index => ...`
  */
  afterChange: PropTypes.func,

  /**
  * Custom dots templates. Works same as customPaging
  */
  appendDots: PropTypes.func,

  /**
  * Default: true
  */
  arrows: PropTypes.bool,

  /**
  * provide ref to another slider and sync it with current slider
  * Default: undefined
  */
  asNavFor: PropTypes.any,
  
  /**
  * Delay between each auto scroll (in milliseconds)
  * Default: 3000
  */
  autoplaySpeed: PropTypes.number,

  /**
  * Default: false
  */
  autoplay: PropTypes.bool,

  /**
  * Index change callback. `(oldIndex, newIndex) => ...`
  */
  beforeChange: PropTypes.func,

  /**
  * Center current slide
  * Default: false
  */
  centerMode: PropTypes.bool,

  /**
  * Default: '50px'
  */
  centerPadding: PropTypes.string,

  /**
  * CSS class for inner slider div
  * Default: ''
  */
  className: PropTypes.string,

  /**
  * Custom paging templates. [Example](examples/CustomPaging.js) 
  * Default: i => <button>{i + 1}</button>
  */
   customPaging: PropTypes.func,

  /**
  * CSS class for dots
  * Default: 'slick-dots'
  */
  dotsClass: PropTypes.string,

  /**
  * Default: Default
  */
  dots: PropTypes.bool,

  /**
  * Enable scrollable via dragging on desktop
  * Default: true
  */
  draggable: PropTypes.bool,

  /**
  * Default: 'linear'
  */
  easing: PropTypes.string,

  /**
  * Default: Default
  */
  fade: PropTypes.bool,

  /**
  * Default: false
  * Go to slide on click
  */
  focusOnSelect: PropTypes.bool,

  /**
  * Default: true
  * Infinitely wrap around contents
  */
  infinite: PropTypes.bool,

  /**
  * Default: 0
  * Index of first slide
  */
  initialSlide: PropTypes.number,

  /**
  * Default: null
  * Load images or render components on demand or progressively
  */
  lazyLoad: PropTypes.any,

  /**
  * Default: null
  * Edge dragged event in finite case, `direction => {...}`
  */
  onEdge: PropTypes.func,

  /**
  * Default: null
  * componentWillMount callback. `() => void`
  */
  onInit: PropTypes.func,

  /**
  * Default: null
  * Callback after slides load lazily `slidesLoaded => {...}`
  */
  onLazyLoad: PropTypes.func,

  /**
  * Default: null
  * componentDidUpdate callback. `() => void`
  */
  onReInit: PropTypes.func,

  /**
  * Default: null
  * Callback after slide changes by swiping
  */
  onSwipe: PropTypes.func,

  /**
  * Default: false
  * Prevents autoplay while hovering on dots
  */
  pauseOnDotsHover: PropTypes.bool,

  /**
  * Default: false
  * Prevents autoplay while focused on slides
  */
  pauseOnFocus: PropTypes.bool,

  /**
  * Default: true
  * Prevents autoplay while hovering on track
  */
  pauseOnHover: PropTypes.bool,

  /**
  * Default: null
  * Customize based on breakpoints (see the demo example for better understanding)
  */
  responsive: PropTypes.array,

  /**
  * Default: 1
  * number of rows per slide in the slider, (enables grid mode)
  */
  rows: PropTypes.number,

  /**
  * Default: false
  * Reverses the slide order
  */
  rtl: PropTypes.number,

  /**
  * Default: 'div'
  * Slide container type
  */
  slide: PropTypes.string,

  /**
  * Default: '1'
  * number of slides to display in grid mode, this is useful with rows option
  */
  slidesPerRow: PropTypes.number,

  /**
  * Default: '1'
  * How many slides to scroll at once
  */
  slidesToScroll: PropTypes.number,

  /**
  * Default: '1'
  * How many slides to show in one frame
  */
  slidesToShow: PropTypes.number,

  /**
  * Default: 500
  * Animation speed in milliseconds
  */
  speed: PropTypes.number,

  /**
  * Default: false
  * Enable drag/swipe irrespective of `slidesToScroll`
  */
  swipeToSlide: PropTypes.bool,

  /**
  * Default: true
  * Enable/disable swiping to change slides
  */
  swipe: PropTypes.bool,

  /**
  * Default: true
  */
  touchMove: PropTypes.bool,

  /**
  * Default: 5
  */
  touchThreshold: PropTypes.number,

  /**
  * Default: true
  * Enable/Disable CSS Transitions
  */
  touchThreshold: PropTypes.bool,

  /**
  * Default: true
  * Enable/Disable CSS transforms
  */
  useTransform: PropTypes.bool,

  /**
  * Default: false
  */
  variableWidth: PropTypes.bool,

  /**
  * Default: false
  */
  vertical: PropTypes.bool


};

export default SlickSlider;