import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Ad, Button } from '@app/components';
import JsonProps from '@app/header.json';
import JsonPropsUAT from '@app/header-uat.json';

const JsonDataContent = process.env.NEXT_PUBLIC_APP_ENVIRONMENT === 'Production' ? JsonProps : JsonPropsUAT;

const Counter = props => {
  const [seconds, setSeconds] = useState(props.counter);

  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000);
    } else {
      setSeconds(0);
      props.onClick();
    }
  }, [seconds, props]);

  // return <Button title={`This Ad will end in ${seconds} sec. Please click here to skip.`} btnType='box' onClick={props.onClick} classes='tw-w-72' />
  return <Button title='close' btnType='boxIcon' size='lg' onClick={props.onClick} />
}

export const Prestitial2 = props => {
  const { logo } = JsonDataContent;
  const [render, setRender] = useState(false);

  const handleClose = () => {
    // setRender(false);
    document.getElementById('prestitialPopup').style.height = '0vh';
  }

  useEffect(() => {
    // Add localStrage
    const prestad = localStorage.getItem('prestad');
    const date = Date.now();
    
    if (!prestad || prestad < date) {
      
      localStorage.setItem('prestad', date + (props.hours * 60 * 60 * 1000));
      setRender(true);
    }
    
    // Callback display popup window
    googletag.cmd.push(function () {
      googletag.pubads().addEventListener('slotRenderEnded', function (event) {
        if (document.querySelector(`[id*='${process.env.NEXT_PUBLIC_GOOGLE_AD_ID}/prestitial']`)?.childNodes.length > 0) {
          document.getElementById('prestitialPopup').style.height = '100vh';
        }
      })
    })

    // const resizeAd = dataString => {
    //   if (JSON.stringify(dataString).indexOf('resizeAd') > -1) {
    //     console.log(dataString);
    //     document.querySelector(`${JSON.parse(dataString).resizeAd} iframe`).setAttribute('height', JSON.parse(dataString).innerHeight);
    //   }
    // }

    // // Received post message action
    // const receivedMessage = dataString => {
    //   resizeAd(dataString);
    // }

    // // Listener for receive post message
    // window.addEventListener('message', event => { receivedMessage(event.data) });

    // return window.removeEventListener('message', event => { receivedMessage(event.data) });
  }, [props.hours])

  return <div id='prestitialPopup' className='tw-relative tw-z-[2147483647] tw-h-[0vh] tw-w-full tw-bg-black tw-overflow-hidden tw-transition-all' style={{height: '100vh'}}>
      <img src={logo.logo} alt={logo.alt} className='tw-absolute tw-left-1/2 tw--translate-x-1/2 tw-w-32 tw-mx-auto tw-mb-4 tw-pt-8 | sm:tw-w-64' />
      <div className='tw-absolute tw-top-0 tw-right-0'><Counter onClick={handleClose} counter={props.counter} /></div>
      <div className='tw-flex tw-items-center tw-justify-center tw-h-full'>
        <div className='tw-w-full tw-max-w-[970px] tw-max-h-[600px]'>
          <Ad googleAd type='prestitialNativeAd' />
        </div>
      </div>
    </div>
  // return render &&
    // <div id='prestitialPopup' className='tw-hidden tw-fixed tw-top-0 tw-left-0 tw-z-[2147483647] tw-w-full tw-h-full tw-bg-opacity-[0.9] tw-bg-black tw-pt-8'>
    //   <img src={logo.logo} alt={logo.alt} className='tw-w-32 tw-mx-auto tw-mb-4 | sm:tw-w-64' />
    //   <div className='tw-fixed tw-top-0 tw-right-0'><Counter onClick={handleClose} counter={props.counter} /></div>
    //   <Ad googleAd type='prestitial' />
    //   {/* <div className='tw-text-center tw-mt-4'><Counter onClick={handleClose} counter={props.counter} /></div> */}
    // </div>
}

export default Prestitial2;

Prestitial2.propTypes = {
  /**
  * How long repeat the ad, unit by hours
  */
  hours: PropTypes.number,
  /**
  * How long to skip, unit by second
  */
  counter: PropTypes.number,
}

Prestitial2.defaultProps = {
  hours: 4,
  counter: 20,
}