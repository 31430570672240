import React from 'react';
import PropTypes from 'prop-types';

/**
* Border component for Home
*/
export const Space = ({double, middle, ...props}) => 
  <div className={`${double ? 'tw-pt-8' : 'tw-pt-4'}${middle ? ' md:tw-pt-8' : ''}`} {...props} />
  
export default Space;

Space.propTypes = {
  /**
  * Double size for space
  */
  double: PropTypes.bool,
  /**
  * Double size in responsive for middle use
  */
  middle: PropTypes.bool,
  /**
  * Addtional class
  */
  className: PropTypes.string,
}

Space.defaultProps = {
  double: false,
  middle: false,
}