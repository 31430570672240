import React from 'react';
import PropTypes from 'prop-types';

/**
* Single Article Footer UI component
*/
export const ArticleContent = props => 
  // <div className='leading-relaxed' dangerouslySetInnerHTML={{ __html: props.body }} />
  <div className='tw-prose 
    md:tw-prose-lg lg:tw-prose-xl 
    prose-headings:tw-font-headline 
    prose-img:tw-m-0 md:prose-img:tw-m-0 lg:prose-img:tw-m-0 
    prose-a:tw-text-primary-main hover:prose-a:tw-no-underline 
    prose-blockquote:tw-relative 
    prose-blockquote:tw-pl-4em md:prose-blockquote:tw-pl-5em lg:prose-blockquote:tw-pl-6em 
    prose-blockquote:tw-font-semibold prose-blockquote:tw-not-italic 
    prose-blockquote:tw-border-dotted prose-blockquote:tw-border-border prose-blockquote:tw-border-l-0 prose-blockquote:tw-border-y 
    tw-max-w-full tw-select-none'  dangerouslySetInnerHTML={{ __html: props.body }} />

export default ArticleContent;

ArticleContent.propTypes = {
  /**
  * Content of the article body
  */
  body: PropTypes.node.isRequired,
};