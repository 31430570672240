import React from 'react';
import PropTypes from 'prop-types';
import Autocomplete from 'react-select';
import { isEmpty } from '@app/utils';

const stylesConfig = {
  container: (provided) => ({
    ...provided,
    display: 'inline-block',
    width: 140,
    lineHeight: '0.8',
  }),
  control: () => ({
    display: 'inline-flex',
  }),
  valueContainer: (provided, state) => ({
    webkitAlignItems: 'center',
    webkitBoxAlign: 'center',
    msFlexAlign: 'center',
    alignItems: 'center',
    display: 'grid',
    webkitFlex: 1,
    msFlex: 1,
    flex: 1,
    webkitBoxFlexWrap: 'wrap',
    webkitFlexWrap: 'wrap',
    msFlexWrap: 'wrap',
    flexWrap: 'wrap',
    padding: 0,
    webkitOverflowScrolling: 'touch',
    position: 'relative',
    overflow: 'hidden',
    boxSizing: 'border-box',
    padding: 0,
    height: 27
  }),
  input: (provided, state) => ({
    ...provided,
    visibility: 'hidden',
    height: 27,
  }),
  singleValue: (provided, state) => ({
    ...provided,
    fontWeight: 'bold',
    color: state.isFocused ? '#ED1C24' : 'black',
    textTransform: 'lowercase',
  }),
  IndicatorsContainer: () => ({
    padding: 0,
    height: 27,
  }),
  indicatorContainer: () => ({
    padding: 0,
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (provided, state) => ({
    color: state.isFocused ? '#ED1C24' : '#707070',
    lineHeight: '1.25',
    '&:hover': {
      color: '#ED1C24',
      // color: state.isFocused ? '#ED1C24' : 'black',
    }
  }),
  menu: (provided) => ({
    ...provided,
    margin: '1px 0 0 0',
    borderRadius: 0,
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 0,
  }),
  option: (provided, state) => ({
    ...provided,
    fontWeight: 'bold',
    textTransform: 'lowercase',
    color: state.isFocused && 'white',
    backgroundColor: state.isFocused && '#ED1C24',
    '&:active': {
      backgroundColor: '#A71C20'
    }
  }),
  noOptionsMessage: (provided) => ({
    ...provided,
    fontWeight: 'bold',
    color: '#CECECE',
  }),
}

export const ResultSorting = ({ numOfResult, keywords, value, onChange, ...props }) => {
  const handleChange = value =>{
    onChange(value);
  }
    
  return <div className='tw-text-sans tw-text-base | md:tw-text-xl'>
    {numOfResult} result{ (numOfResult > 1) ? 's ':' '}
    {!isEmpty(keywords) && <>search for <b>{keywords.map(keyword => ` ${keyword.text}`)}</b></>} order by <Autocomplete
      styles={stylesConfig}
      defaultValue={props?.options?.filter(option => option.selected === true)}
      getOptionLabel={option => option.text}
      getOptionValue={option => option.key}
      onChange={handleChange} {...props} />
  </div>
}

export default ResultSorting;

ResultSorting.propTypes = {
  /**
  * Number of result
  */
  numOfResult: PropTypes.number.isRequired,
  /**
  * Search keywords
  */
  keywords: PropTypes.array,
  /**
  * Option list data, sample: [{label:'Option 1', value: 'option 1'}, {label:'Option 2', value: 'option 2'}]
  */
  options: PropTypes.array.isRequired,
  /**
  * onChange callback function
  */
  onChange: PropTypes.func.isRequired,
};

ResultSorting.defaultProps = {
  keywords: [],
}