import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import queryString from "query-string";
import { Button } from '@app/components';
import DefaultProps from '@app/header.json';
import DefaultPropsUAT from '@app/header-uat.json';

const DefaultPropsContent = process.env.NEXT_PUBLIC_APP_ENVIRONMENT === 'Production' ? DefaultProps : DefaultPropsUAT;

/**
* Search field component
*/
export const SearchField = props => {
  const [focus, setFocus] = useState(false);
  const inputRef = useRef();

  const handleChange = event => {
    props.onChange(event.target.value);
  }

  const handleKeyDown = event =>
    event.key === "Enter" && handleSubmit()

  const handleSubmit = () => {
    const keyword = (props?.value || '').trim();
    window.location.href = !keyword ? `/search` : `/search?${queryString.stringify({ keyword: keyword, sort: 'latest' })}`
    return
  }

  const handleClear = () => {
    inputRef.current.focus();
    props.onChange('');
  }

  const handleFocus = () => setFocus(true);

  const handleBlur = () => !props.value && setFocus(false)

  return !props.isTap ?
    <div className='tw-relative tw-border-b-2 tw-border-black tw-border-opacity-30 tw-bg-white | hover:tw-border-opacity-60' onFocus={handleFocus} onBlur={handleBlur}>
      <label htmlFor={props.name} className={`tw-absolute tw-transition-all ${focus ? 'tw-top-0 tw-left-0 tw-transform tw--translate-y-full tw-text-xs' : 'tw-top-1/2 tw-left-4 tw-transform tw--translate-y-1/2 tw-text-xl'} tw-leading-none tw-font-bold tw-tracking-tighter tw-text-gray-300 tw-pointer-events-none |  ${focus ? 'sm:tw-left-0 sm:tw-text-sm | lg:tw-text-base' : 'sm:tw-left-6 sm:tw-text-3xl | lg:tw-left-8 lg:tw-text-4xl'}`}>{props.label}
      {/* {props.sponser && <span className='tw-text-0.75em tw-italic tw-font-normal'> - {props.sponser}</span>} */}
      </label>
      <div className={`${props.value ? 'tw-pr-20' : 'tw-pr-10'} tw-pl-2 tw-border-l-8 tw-border-primary-main | sm:tw-pl-3 sm:tw-border-l-12 | lg:tw-pl-4 lg:tw-border-l-16`}>
        <input ref={inputRef} type='text' aria-invalid='false' id={props.name} className='tw-text-xl tw-leading-tight tw-font-bold tw-tracking-tighter tw-outline-none tw-w-full tw-h-10 | sm:tw-text-3xl sm:tw-leading-tight sm:tw-h-12 | lg:tw-text-4xl lg:tw-leading-tight lg:tw-h-13' autoComplete={props.autoComplete.toString()} disabled={props.disabled} value={props.value} onChange={handleChange} onKeyDown={handleKeyDown} />
      </div>
      {props.submitable && <div className='tw-absolute tw-bottom-0 tw-right-0'><Button title='search' btnType='boxIcon' onClick={handleSubmit} /></div>}
      {props.value && <div className={`tw-absolute tw-bottom-0 ${props.submitable ? 'tw-right-10 | sm:tw-right-12 | lg:tw-right-13' : 'tw-right-0'}`}><Button title='close' btnType='boxIcon' onClick={handleClear} /></div>}
    </div>
    : <div className='tw-relative' onFocus={handleFocus} onBlur={handleBlur}>
      <div className='tw-pr-10'>
        <input ref={inputRef} type='text' aria-invalid='false' id={props.name} className='tw-text-based tw-leading-tight tw-font-bold tw-outline-none tw-w-full tw-h-10 tw-text-gray-300 tw-bg-transparent | sm:tw-text-lg sm:tw-leading-tight' autoComplete={props.autoComplete.toString()} disabled={props.disabled} value={props.value} onChange={handleChange} autoFocus />
      </div>
      {props.value && <div className='tw-absolute tw-top-1/2 tw-right-2 tw-transform tw--translate-y-1/2'><Button title='close' btnType='icon' onClick={handleClear} noResponsive /></div>}
    </div>
}

export default SearchField;

SearchField.propTypes = {
  /**
  * Name for the textfield
  */
  name: PropTypes.string,
  /**
  * Label for the textfield
  */
  label: PropTypes.string,
  /**
  * Sponser for the textfield
  */
  sponser: PropTypes.string,
  /**
  * Value for the textfield
  */
  value: PropTypes.string.isRequired,
  /**
  * On Change call back function for the textfield
  */
  onChange: PropTypes.func.isRequired,
  /**
  * Include submit button and route to search result with keywords
  */
  submitable: PropTypes.bool,
  /**
  * AutoComplete for the textfield
  */
  autoComplete: PropTypes.bool,
  /**
  * AutoComplete for the textfield
  */
  disabled: PropTypes.bool,
  /**
  * Is tap in homepage
  */
  isTap: PropTypes.bool,
}

SearchField.defaultProps = {
  name: DefaultPropsContent.search.name,
  label: DefaultPropsContent.search.label,
  sponser: DefaultPropsContent.search.sponser,
  submitable: false,
  autoComplete: false,
  disabled: false,
  isTap: false
}