export const isEmpty = (obj) => {
  if (Array.isArray(obj)) {
    if (obj.length > 0)
      return false;
  } else {
    for (var key in obj) {
      if (obj.hasOwnProperty(key))
        return false;
    }
  }
  return true;
}

export default isEmpty;