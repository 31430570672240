import { BreadcrumbJsonLd } from 'next-seo'

/**
 * Default breadcrumb json ld
 * @param {Object} props
 * @param {Object[]} props.elements breadcrumb items
 * @param {Number} props.elements[].position position of the breadcrumb item, start with 2
 * @param {string} props.elements[].name name of the breadcrumb item
 * @param {string} props.elements[].item URL of the breadcrumb item
 * @returns 
 */
export const Breadcrumb = ({ elements }) => {
  return (
    <BreadcrumbJsonLd
      itemListElements={[
        {
          position: 1,
          item: process.env.NEXT_PUBLIC_API_URL,
          name: "Homepage"
        },
        ...elements
      ]}
    />
  )
}