import { getMagazineURL } from '@app/utils'
import { CorporateContact, Breadcrumb, ListingSeo, MagazineItemList } from "@app/components/Seo"


export const Magazines = ({ data }) => (
  <>
    <CorporateContact />
    <ListingSeo
      listing="Magazines"
      url={getMagazineURL()}
    />
    <MagazineItemList items={data?.items} itemType="magazine/issue" />
    <Breadcrumb 
      elements={[
        {
          position: 2,
          name: "Magazines",
          item: getMagazineURL()
        }
      ]}
    />
  </>
)