import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import DefaultProps from '@app/header.json';
import DefaultPropsUAT from '@app/header-uat.json';

const DefaultPropsContent = process.env.NEXT_PUBLIC_APP_ENVIRONMENT === 'Production' ? DefaultProps : DefaultPropsUAT;

/**
* Logo component for footer
*/
export const FooterLogo = props =>
  <figure>
    <Link href='/'>
      <a className='tw-block'>
        <img alt={props.alt} src={props.logo} className='tw-w-auto tw-h-7 tw-transition-all tw-mx-auto' />
      </a>
    </Link>
    {/* <a href={process.env.NEXTAUTH_URL} className='tw-block'>
      <img alt={props.alt} src={props.logo} className='tw-w-auto tw-h-7 tw-transition-all tw-mx-auto' />
    </a> */}
  </figure>

export default FooterLogo;

FooterLogo.propTypes = {
  /**
  * Website logo for big screen
  */
  logo: PropTypes.string.isRequired,
  /**
  * Alt text for logo
  */
  alt: PropTypes.string.isRequired
}

FooterLogo.defaultProps = DefaultPropsContent.logo;