import React from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import DefaultProps from '@app/header.json';
import DefaultPropsUAT from '@app/header-uat.json';

const DefaultPropsContent = process.env.NEXT_PUBLIC_APP_ENVIRONMENT === 'Production' ? DefaultProps : DefaultPropsUAT;

/**
* Copyright UI component for footer
*/
export const Copyright = props =>
  <div className='tw-flex tw-flex-col tw-flex-wrap tw-gap-2 tw-items-center | sm:tw-flex-row sm:tw-flex-nowrap sm:tw-gap-6'>
    <Link href={props.url}>
      <a className='tw-block' target='_blank'>
        <img alt={props.alt} src={props.logo} className='tw-w-auto tw-h-8 tw-transition-all tw-mx-auto' />
      </a>
    </Link>
    {/* <a href={props.url} className='tw-block' target='_blank' rel='noreferrer'>
      <img alt={props.alt} src={props.logo} className='tw-w-auto tw-h-8 tw-transition-all tw-mx-auto' />
    </a> */}
    <div className='tw-font-bold tw-text-white'>{props.display}</div>
  </div>

export default Copyright;

Copyright.prototype = {
  /**
  * Haymarket logo
  */
  logo: PropTypes.string.isRequired,
  /**
  * Alt text for logo
  */
  alt: PropTypes.string.isRequired,
  /**
  * Copyright text
  */
  display: PropTypes.string.isRequired,
  /**
  * Hyperlink of the button
  */
  url: PropTypes.string.isRequired,
}

Copyright.defaultProps = DefaultPropsContent.copyright;