import React, { useEffect, useRef, useState } from 'react';

import { Header, Footer, Ad, Prestitial, Prestitial2,  BackdropLoading } from '@app/components';

export const Layout = ({ children }) => {
  const [mount, setMount] = useState(false);
  const runSticky = useRef(true);

  useEffect(() => {
    // Disable feature when sever side rendering
    if (!mount) {
      setMount(true)
    } else {
      const targetObj = document.querySelector('#__next > header');
      // const stickyObj = document.querySelector('.topLeaderboardObj');
      const stickyBottomObj = document.querySelector('.bottomLeaderboardObj');
      const maxStickyPos = 480;

      // Sticky function
      const sticky = (targetObj, maxStickyPos) => {
        const targetPos = targetObj?.getBoundingClientRect().height;
        const scrollPos = window.scrollY;

        runSticky.current = (scrollPos < targetPos) || runSticky.current;

        if (runSticky.current) {
          if (scrollPos < maxStickyPos) {

            // topLeaderboard
            // stickyObj.classList.add('!tw-bg-secondary-light/[.9]', 'tw-sticky');
            // stickyObj.classList.remove('tw--translate-y-full');

            // bottomLeaderboard
            stickyBottomObj?.classList.add('!tw-bg-secondary-light/[.95]', 'tw-sticky');
            stickyBottomObj?.classList.remove('tw-translate-y-full');
          } else {
            // topLeaderboard
            // stickyObj.classList.remove('!tw-bg-secondary-light/[.9]');
            // stickyObj.classList.add('tw--translate-y-full');
            // setTimeout(() => {
            //   stickyObj.classList.remove('tw-sticky', 'tw--translate-y-full');
            // }, 500);

            // bottomLeaderboard
            stickyBottomObj?.classList.remove('!tw-bg-secondary-light/[.95]');
            stickyBottomObj?.classList.add('tw-translate-y-full');
            setTimeout(() => {
              stickyBottomObj?.classList.remove('tw-sticky', 'tw-translate-y-full');
            }, 500);

            runSticky.current = false;
          }
        }

        return;
      }

      // Callback display popup window
      googletag.cmd.push(function () {
        googletag.pubads().addEventListener('slotRenderEnded', function (event) {
          if (document.querySelector(`[id*='${process.env.NEXT_PUBLIC_GOOGLE_AD_ID}/bottom-leaderboard']`)?.childNodes.length > 0) {
            document.querySelector('.bottomLeaderboardObj')?.classList.remove('!tw-hidden');
          }
        })
      })

      // Run Sticky function when loaded
      sticky(targetObj, maxStickyPos);
      // Run Sticky function when scrolling
      window.addEventListener('scroll', () => { sticky(targetObj, maxStickyPos) });

      // Remove all event listeners
      return () => {
        window.removeEventListener('scroll', () => { sticky(targetObj, maxStickyPos) });
      }
    }
  }, [mount, setMount, runSticky])


  return <>
    <BackdropLoading />
    <Prestitial />
    {/* <Prestitial2 /> */}
    {/* <div className='tw-bg-secondary-light tw-top-0 tw-left-0 tw-z-[500] tw-transition tw-duration-500'><Ad googleAd type='prestitialNativeAd' /></div> */}
    <Header />
    <div className='topLeaderboardObj tw-bg-secondary-light tw-top-0 tw-left-0 tw-z-[500] tw-transition tw-duration-500'><Ad googleAd type='topLeaderboard' id="layout-leaderboard" /></div>
    <main className='tw-flex tw-flex-col tw-gap-8 tw-my-4 | sm:tw-gap-16 sm:tw-my-8 | lg:tw-my-12 | 2xl:tw-my-16'>{children}</main>
    <div className='bottomLeaderboardObj !tw-hidden tw-bg-secondary-light tw-w-full tw-bottom-0 tw-left-0 tw-z-[500] tw-transition tw-duration-500'><Ad googleAd type='bottomLeaderboard' id="layout-bottomLeaderboard" /></div>
    <Footer />
  </>
}

export default Layout;