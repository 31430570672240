import React from 'react';
import Link from 'next/link';
import PropTypes from 'prop-types';

/**
* Title UI component for component use
*/
export const Title = ({id, slug, itemType, title, caption, variant, url, home, ...props}) => {
  const middle = variant === 'middle';
  const className = `tw-block tw-font-extrabold tw-tracking-tight tw-uppercase ${middle ? (!home ? 'tw-text-3xl tw-leading-tight | sm:tw-text-4xl sm:tw-leading-tight | lg:tw-text-5xl lg:tw-leading-tight' : 'tw-text-2xl tw-leading-tight | sm:tw-text-4xl sm:tw-leading-tight') : 'tw-text-2xl tw-leading-tight'}`;
  return <div {...props}>
    <header className={`tw-pl-2 tw-border-l-8 tw-border-primary-main ${middle ? '| sm:tw-pl-3 sm:tw-border-l-12 | lg:tw-pl-4 lg:tw-border-l-16' : ''}`}>
      {middle ? url || (itemType && slug && id) ? 
          <h1>
            <Link href={url || linkHelper(itemType, slug, id)}>
              <a className={className + ' hover:tw-underline'}>{title}</a>
            </Link>
          </h1>
        : <h1 className={className}>{title}</h1>
        : url || (itemType && slug && id) ? 
          <h3>
            <Link href={url || linkHelper(itemType, slug, id)}>
              <a className={className + ' hover:tw-underline'}>{title}</a>
            </Link>
          </h3>
          : <h3 className={className}>{title}</h3>
      }
    </header>
    {caption && <div className={`tw-text-base tw-mt-2 ${middle ? '| sm:tw-text-lg sm:tw-mt-4 | lg:tw-text-xl' : ''}`}>{caption}</div>}
  </div>
}

export default Title;

Title.propTypes = {
  /**
  * Identify article and use for hyperlink
  */
  id: PropTypes.number,
  /**
  * slug of the article for hyperlink
  */
  slug: PropTypes.string,
  /**
  * Type of the article ('article', 'gallery', 'video', 'event', 'webinar', 'link', 'topic', 'magazine', 'category')
  */
  itemType: PropTypes.oneOf(['article', 'gallery', 'video', 'event', 'webinar', 'link', 'topic', 'magazine', 'category']),
  /**
  * String of title
  */
  title: PropTypes.string.isRequired,
  /**
  * String of caption
  */
  caption: PropTypes.string,
  /**
  * Variant format of title for layout, 'middle' is layout for centre, 'side' is layout for left or right side
  */
  variant: PropTypes.oneOf(['middle', 'side']),
  /**
  * Hyperlink for the title
  // */
  url: PropTypes.string,
  /**
  * Home center title
  // */
  home: PropTypes.bool
};

Title.defaultProps = {
  variant: 'middle',
  caption: ''
}
