import { getGalleryURL } from '@app/utils'
import { CorporateContact, Breadcrumb, ListingSeo, ItemList } from "@app/components/Seo"


export const Galleries = ({ data }) => (
  <>
    <CorporateContact />
    <ListingSeo
      listing="Galleries"
      url={getGalleryURL()}
    />
    <ItemList items={data?.items} />
    <Breadcrumb 
      elements={[
        {
          position: 2,
          name: "Galleries",
          item: getGalleryURL()
        }
      ]}
    />
  </>
)