import React from 'react';
import PropTypes from 'prop-types';

export const Section = props => 
  <section>
    {props.title}
    <div className={props.className ? props.className : 'tw-mt-4 | md:tw-mt-8 | 2xl:tw-mt-16'}>
      {props.children}
    </div>
  </section>

export default Section;

Section.propTypes = {
  /**
  * Title component
  */
  title: PropTypes.node.isRequired,
  /**
  * Children component
  */
  children: PropTypes.node.isRequired,
  /**
  * Wrapper class
  */
  className: PropTypes.string,
};