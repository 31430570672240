import { format } from 'date-fns'

export const dateTime = (publishedAt) => {
 
  function timeSince(timeStamp) {
    var now = new Date()
    const secondsPast = (now.getTime() - timeStamp)/1000;
    
    if (secondsPast < 60) {
      if (secondsPast < 0) {
        var day = timeStamp.getDate();
        var month = timeStamp.toDateString().match(/ [a-zA-Z]*/)[0].replace(" ", "");
        var year =  timeStamp.getFullYear();
        return month + " " + day + ", " + year;
      }
      return 'now';
    }
    if (60 < secondsPast  && secondsPast < 120) {
      return '1 minute ago';
    }
    if (120 < secondsPast  && secondsPast < 3540) {
      return parseInt(secondsPast / 60) + ' minutes ago';
    }
    if (3540 < secondsPast  && secondsPast < 7200) {
      return '1 hour ago';
    }
    if (7200 < secondsPast  && secondsPast < 86400) {
      return  parseInt(secondsPast / 3600) + ' hours ago';
    }
    if (86400 < secondsPast  && secondsPast < 172800) {
      return '1 day ago';
    }
    if (172800 < secondsPast  && secondsPast < 604800) {
      return parseInt(secondsPast / 24 / 3600) + ' days ago';
    }
    if (secondsPast > 604800) {
      var day = timeStamp.getDate();
      var month = timeStamp.toDateString().match(/ [a-zA-Z]*/)[0].replace(" ", "");
      var year =  timeStamp.getFullYear();
      return month + " " + day + ", " + year;
    }
  }

 return timeSince(new Date(publishedAt*1000));
}

export const getDiffYearMonthDate = (startAt, endAt) => {
  const startYear = new Date(startAt*1000).getFullYear();
  const endYear = new Date(endAt*1000).getFullYear();

  const startMonth = new Date(startAt*1000).toDateString().match(/ [a-zA-Z]*/)[0].replace(" ", "");
  const endMonth = new Date(endAt*1000).toDateString().match(/ [a-zA-Z]*/)[0].replace(" ", "");

  const startDate = new Date(startAt*1000).getDate();
  const endDate = new Date(endAt*1000).getDate();
  return startYear !== endYear ? `${startMonth} ${startDate}, ${startYear} - ${endMonth} ${endDate}, ${endYear}`
    : startMonth !== endMonth ? `${startMonth} ${startDate} - ${endMonth} ${endDate}, ${startYear}`
    : startDate !== endDate ? `${startMonth} ${startDate} - ${endDate}, ${startYear}`
    : `${startMonth} ${startDate}, ${startYear}`
}

export const getHourMinute = (publishedAt) => {
  const hours = new Date(publishedAt*1000).getHours();
  const minutes = new Date(publishedAt*1000).getMinutes();
  return (hours < 10 ? '0' + hours : hours) + ':' + (minutes < 10 ? '0' + minutes : minutes);
}

export const getDateFromUnix = (unix) => {
  return new Date(unix * 1000);
}

export const getRssDateStr = (date) => {
  return format(date, "EEE, dd MMM yyyy HH:mm:ss xxxx");
}